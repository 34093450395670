import React from 'react';
import { BrandIconPersonalDataSecurity } from '@snsw-gel/react';
import * as Styled from './FormHeading.styled';

const FormHeading: React.FC = () => (
  <Styled.HeadingContainer>
    <BrandIconPersonalDataSecurity size='lg' data-testid='brandDataSecurity' />
    <Styled.Heading level={3}>Existing customer record</Styled.Heading>
  </Styled.HeadingContainer>
);

export default FormHeading;
