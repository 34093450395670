const getHeaderValueByKey = (
  headers: null | Record<string, unknown>,
  key: string
): unknown => {
  if (headers === null) {
    return null;
  }

  if (headers[key] === undefined) {
    return null;
  }

  return headers[key];
};

export default getHeaderValueByKey;
