import styled from 'styled-components';
import { tokens, HorizontalRule as SNSWHorizontalRule } from '@snsw-gel/react';
const { spacing, breakpoints } = tokens;

interface HorizontalRuleProps {
  disableMarginTop?: boolean;
  disableMarginBottom?: boolean;
}

const disableMarginTopStyles = `
margin-top: 0;

@media screen and (min-width: ${breakpoints.md}px) {
  margin-top: 0;
}
`;
const disableMarginBottomStyles = `
margin-bottom: 0px;

@media screen and (min-width: ${breakpoints.md}px) {
  margin-bottom: 0;
}
`;

export const HorizontalRule = styled(SNSWHorizontalRule)<HorizontalRuleProps>`
  margin: ${spacing.xl}px 0;

  @media screen and (min-width: ${breakpoints.md}px) {
    margin: ${spacing.xxxl}px 0;
  }

  ${({ disableMarginTop = false }) =>
    disableMarginTop && disableMarginTopStyles}

  ${({ disableMarginBottom = false }) =>
    disableMarginBottom && disableMarginBottomStyles}
`;

export const SmallHorizontalRule = styled(
  SNSWHorizontalRule
)<HorizontalRuleProps>`
  margin: ${spacing.lg}px 0;

  @media screen and (min-width: ${breakpoints.md}px) {
    margin: ${spacing.xl}px 0;
  }

  ${({ disableMarginTop = false }) =>
    disableMarginTop && disableMarginTopStyles}

  ${({ disableMarginBottom = false }) =>
    disableMarginBottom && disableMarginBottomStyles}
`;
