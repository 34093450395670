import Styled from 'styled-components';
import { FormGroup } from '@snsw-gel/react';

// there are some clashing styles due to parent sub-form, these turn off the clashing styles
export const DatePickerFormGroup = Styled(FormGroup)`
  duet-date-picker * select {
    opacity: 0;
    position: absolute
  }
`;
