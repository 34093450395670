import React from 'react';
import { InPageAlert } from '@snsw-gel/react';

const SuccessEmailNotification: React.FC<SuccessEmailNotificationProps> = ({
  emailAddress,
}) => (
  <InPageAlert variant='success' title='Success!' headingElement='p' compact>
    <p data-testid='success-description'>
      Your email has been sent to <strong>{emailAddress}</strong>
    </p>
  </InPageAlert>
);

export default SuccessEmailNotification;

interface SuccessEmailNotificationProps {
  emailAddress: string;
}
