import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { KeepAReceipt, pdfActions } from '@rmstransactions/components';
import { postReceiptSendEmail } from 'api/api';
import { SendEmailBody } from 'api/types/request';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { receiptFileName } from 'config/constants';
import { useStoreSelector } from 'store';
import { handleResponseData } from 'utils/api/httpClient';
import { RoutePathParams } from 'utils/routes/routePathParams';
import ErrorNotification, {
  ReceiptErrorType,
} from './ErrorNotification/ErrorNotification';
import SuccessEmailNotification from './SuccessEmailNotification/SuccessEmailNotification';

const ReceiptActions: React.FC = () => {
  const { receiptUUID } = useParams<RoutePathParams>();
  const [errorType, setErrorType] = useState<ReceiptErrorType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successEmailAddress, setSuccessEmailAddress] = useState<null | string>(
    null
  );
  const confirmationData = useStoreSelector((state) => state.confirmation.data);
  const receiptPdf = confirmationData?.receiptPDFBase64 ?? null;

  const handlePrint = () => {
    if (!receiptPdf) {
      setErrorType('print');
      return;
    }
    try {
      pdfActions.printBase64PDF(receiptPdf);
      setErrorType(null);
    } catch {
      setErrorType('print');
    }
  };

  const handleDownload = () => {
    if (!receiptPdf) {
      setErrorType('download');
      return;
    }
    try {
      pdfActions.downloadBase64PDF(receiptPdf, receiptFileName);
      setErrorType(null);
    } catch {
      setErrorType('download');
    }
  };

  const handleEmailPost = async (emailAddress: string) => {
    setErrorType(null);
    setSuccessEmailAddress(null);

    setIsLoading(true);
    try {
      const body: SendEmailBody = {
        emailAddress: emailAddress,
      };
      if (receiptUUID) {
        body.uuid = receiptUUID;
      }

      const response = await postReceiptSendEmail(body);
      const data = handleResponseData(response);

      if (data === null) {
        setErrorType('email');
        setIsLoading(false);
        return;
      }

      setSuccessEmailAddress(emailAddress);
      setIsLoading(false);
    } catch {
      setErrorType('email');
      setIsLoading(false);
    }
  };

  return (
    <>
      <section>
        <KeepAReceipt
          id='keep-a-receipt'
          heading='Download or share your enrolment summary'
          labelPrint='Print'
          onPrint={handlePrint}
          labelDownload='Download transaction summary'
          onDownload={handleDownload}
          labelEmail='Send to this email address'
          onEmailSubmit={handleEmailPost}
          emailFieldId={emailReceiptActionID}
          confirmationModalContentName='DKT online enrolment confirmation'
        />
        {errorType && <ErrorNotification errorType={errorType} />}
        {successEmailAddress && (
          <SuccessEmailNotification emailAddress={successEmailAddress} />
        )}
      </section>
      {isLoading && <FullPageLoader />}
    </>
  );
};

export default ReceiptActions;

export const emailReceiptActionID = 'dkt-email';
