import styled from 'styled-components';
import { tokens } from '@snsw-gel/react';

export const FormNavigationButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 3rem;
  margin-bottom: 2rem;

  @media screen and (min-width: ${tokens.breakpoints.sm}px) {
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    margin-top: 4rem;
    margin-bottom: 2.5rem;
  }
`;
