import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TFNSWCardType } from 'api/types/drives';

export type tfNSWCardTypeFormValue = 'n/a' | TFNSWCardType;

export interface TFNSWCardData {
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  dateOfBirth: string | null;
  tfNSWCardNumber: string | null;
}

export type ValidationInvalid = 'INVALID';
export type ValidationRequired = 'REQUIRED';
export type ValidationInvalidAge = 'INVALID_AGE';
export type ValidationFormInvalid =
  | null
  | ValidationInvalid
  | ValidationRequired;
export type ValidationDateOfBirthInvalid =
  | ValidationFormInvalid
  | ValidationInvalidAge;
export const validationInvalidValue: ValidationInvalid = 'INVALID';
export const validationRequiredValue: ValidationRequired = 'REQUIRED';
export const validationInvalidAge: ValidationInvalidAge = 'INVALID_AGE';

export interface TFNSWCardValidation {
  firstName: ValidationFormInvalid;
  middleName: ValidationFormInvalid;
  lastName: ValidationFormInvalid;
  dateOfBirth: ValidationDateOfBirthInvalid;
  tfNSWCardNumber: ValidationFormInvalid;
}

export interface ExistingCustomerState {
  tfNSWCardType: tfNSWCardTypeFormValue | null;
  data: TFNSWCardData;
  validation: TFNSWCardValidation;
  inPageAlertValidation: Partial<TFNSWCardValidation>;
}

const initialDataState = {
  firstName: null,
  middleName: null,
  lastName: null,
  dateOfBirth: null,
  tfNSWCardNumber: null,
};

const initialValidationState = {
  firstName: null,
  middleName: null,
  lastName: null,
  dateOfBirth: null,
  tfNSWCardNumber: null,
};

export const initialState: ExistingCustomerState = {
  tfNSWCardType: null,
  data: initialDataState,
  validation: initialValidationState,
  inPageAlertValidation: {},
};

export const tfNSWCustomerSlice = createSlice({
  name: 'tfNSWCustomer',
  initialState,
  reducers: {
    setCardType: (state, action: PayloadAction<tfNSWCardTypeFormValue>) => {
      state.tfNSWCardType = action.payload;
      state.data = initialDataState;
      state.validation = initialValidationState;
      state.inPageAlertValidation = {};
    },
    setCardData: (
      state,
      action: PayloadAction<{ key: keyof TFNSWCardData; value: string }>
    ) => {
      const { key, value } = action.payload;
      state.data[key] = value;
    },
    setCardValidation: (
      state,
      action: PayloadAction<Partial<TFNSWCardValidation>>
    ) => {
      state.validation = {
        ...state.validation,
        ...action.payload,
      };
    },
    setInPageAlertValidation: (
      state,
      action: PayloadAction<Partial<TFNSWCardValidation>>
    ) => {
      state.inPageAlertValidation = action.payload;
    },
  },
});

export const tfNSWCustomerSliceActions = tfNSWCustomerSlice.actions;
export default tfNSWCustomerSlice.reducer;
