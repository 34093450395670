import React from 'react';
import { useStoreSelector } from 'store';
import QuestionnaireRadioTemplate from '../QuestionnaireRadioTemplate/QuestionnaireRadioTemplate';

/**
 * QUESTION 2
 *
 * VISIBLE => Q1 === YES\
 * YES => move to Q3\
 * NO => END: ineligible
 *  */
const RequiredAge: React.FC = () => {
  const nswResidentialAddress = useStoreSelector(
    (state) => state.questionnaire.nswResidentialAddress
  );
  const requiredAge = useStoreSelector(
    (state) => state.questionnaire.requiredAge
  );

  const isVisible = requiredAge !== null || nswResidentialAddress === 'yes';
  if (!isVisible) return <></>;

  return (
    <QuestionnaireRadioTemplate
      questionnaireKey='requiredAge'
      legendLabel='Are you 15 years and 11 months of age, or older?'
    />
  );
};

export default RequiredAge;
