import React from 'react';
import { TextLink } from '@snsw-gel/react';

/**
 * Use this component over the SNSW Kiama <TextLink /> for external links
 * Designers have declared they do not wan't the icon for external built into the Kiama component
 */
const ExternalTextLink: React.FC<ExternalTextLinkProps> = ({
  id,
  href,
  children,
}) => {
  return (
    <TextLink href={href} target='_blank' rel='noopener noreferrer' {...{ id }}>
      {children}
    </TextLink>
  );
};

interface ExternalTextLinkProps {
  id?: string;
  href?: string;
}

export default ExternalTextLink;
