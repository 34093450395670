import React from 'react';
import { useHistory } from 'react-router-dom';
import { TFNSWCardType } from 'api/types/drives';
import { tfNSWCardTypes, tfNSWCardTypeLabels } from 'consts/transport';
import { useStoreDispatch, useStoreSelector } from 'store';
import { eligibilityFormSliceActions } from 'store/slice/eligibilityForm';
import constructFullName from 'utils/helpers/constructFullName';
import * as Styled from '../DetailsBox.styled';

const ExistingCustomerDetails: React.FC = () => {
  const history = useHistory();
  const dispatch = useStoreDispatch();
  const emailCustomerInfo = useStoreSelector((state) => state.customer.email);
  const emailPoiInfo = useStoreSelector(
    (state) => state.review.profileData?.email
  );
  const tfNSWCardType = useStoreSelector(
    (state) => state.tfNSWCustomer.tfNSWCardType
  );
  const tfNSWCustomer = useStoreSelector((state) => state.tfNSWCustomer.data);
  const { firstName, middleName, lastName, dateOfBirth, tfNSWCardNumber } =
    tfNSWCustomer;

  const handleEditButton = () => {
    dispatch(
      eligibilityFormSliceActions.setActiveAccordion('existingCustomer')
    );
    history.goBack();
  };

  const fullName = constructFullName([firstName, middleName, lastName]);
  const documentLabel =
    tfNSWCardType && tfNSWCardType in tfNSWCardTypeLabels
      ? tfNSWCardTypeLabels[tfNSWCardType as TFNSWCardType]
      : '';
  const displayEmail = emailPoiInfo ?? emailCustomerInfo ?? '';

  return (
    <Styled.Box data-testid='customer-details-existing'>
      <Styled.DescriptionList>
        <dt>Full name</dt>
        <dd>{fullName || ''}</dd>

        <dt>Date of Birth</dt>
        <dd>{dateOfBirth ?? ''}</dd>

        <dt>Email</dt>
        <dd>{displayEmail}</dd>

        <dt>Product</dt>
        <dd>{documentLabel}</dd>

        <dt>{getNumberLabel(tfNSWCardType)}</dt>
        <dd>{tfNSWCardNumber ?? ''}</dd>
      </Styled.DescriptionList>

      <Styled.NormalWeightButton variant='link' onClick={handleEditButton}>
        Edit
        <Styled.IconWrapper>
          <img alt='' src='/images/editIcon.svg' />
        </Styled.IconWrapper>
      </Styled.NormalWeightButton>
    </Styled.Box>
  );
};

export default ExistingCustomerDetails;

const getNumberLabel = (cardType: string | null) => {
  switch (cardType) {
    case tfNSWCardTypes.nswPlateNumber:
      return 'NSW plate number';
    case tfNSWCardTypes.drivesCustomerNumber:
      return 'Number';
    default:
      return 'Card number';
  }
};
