import React from 'react';
import ReactMarkdown from 'react-markdown';
import { hyperlinkNewPageExternal } from 'utils/providers/reactMarkdown';

const ModalMarkdownWrapper: React.FC = ({ children }) => {
  return (
    <ReactMarkdown components={hyperlinkNewPageExternal}>
      {children as string}
    </ReactMarkdown>
  );
};

export default ModalMarkdownWrapper;
