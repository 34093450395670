import styled, { css } from 'styled-components';
import { tokens } from '@snsw-gel/react';

interface AccordionContainerProps {
  $hasError: boolean;
}
export const AccordionContainer = styled.div<AccordionContainerProps>`
  display: flex;
  flex-direction: column;
  border: 2px solid ${tokens.colors.grey.geyser};
  border-radius: 0.5rem;
  margin: 1rem 0;

  ${({ $hasError }) =>
    $hasError &&
    css`
      border-color: ${tokens.colors.borders.error};
    `}
`;

const accordionPaddingMobile = '1rem';
const accordionPaddingLaptop = '1.375rem';
const accordionLayoutGrid = `${tokens.spacing.icons.lg}px 1fr ${tokens.spacing.icons.md}px`;

export const AccordionButton = styled.button`
  display: grid;
  grid-template-columns: ${accordionLayoutGrid};
  justify-items: start;
  gap: 1rem;
  width: 100%;
  padding: ${accordionPaddingMobile};

  background-color: transparent;
  border: none;
  cursor: pointer;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    gap: 1.5rem
    padding: ${accordionPaddingLaptop}
  }

  &:focus {
    outline: 2px solid ${tokens.colors.brand.snswSecondaryBlue};
    outline-offset: 2px;
  }
`;

export const HeadingWrapper = styled.h3`
  margin: 0;
`;

export const AccordionHeading = styled.span`
  display: block;
  margin: auto 0;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
  text-align: left;
  color: ${tokens.colors.text.body};
`;

export const ChevronContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${tokens.spacing.icons.lg}px;
`;

interface ContentContainerProps {
  $visible: boolean;
}
export const ContentContainer = styled.div<ContentContainerProps>`
  padding: 0 ${accordionPaddingMobile};

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    display: grid;
    grid-template-columns: ${accordionLayoutGrid};
    gap: 1.5rem
    padding: 0 ${accordionPaddingLaptop};
  }

  visibility: hidden;
  overflow: hidden;
  height: 0;

  ${({ $visible }) =>
    $visible &&
    css`
      visibility: visible;
      overflow: initial;
      height: auto;
    `}
`;

export const ChildrenWrapper = styled.section`
  margin-bottom: ${accordionPaddingMobile};

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    grid-column-start: 2;
    margin-bottom: ${accordionPaddingLaptop};
  }
`;
