import React from 'react';
import * as StyledSurveyRadioGroup from 'components/RadioGroup/SurveyRadioGroup.styled';
import { useStoreDispatch, useStoreSelector } from 'store';
import {
  QuestionnaireFormValues,
  QuestionnaireKeys,
  questionnaireLabelAndValues,
  questionnaireSliceActions,
} from 'store/slice/questionnaire';

const QuestionnaireRadioTemplate: React.FC<QuestionnaireRadioTemplateProps> = ({
  questionnaireKey,
  legendLabel,
}) => {
  const dispatch = useStoreDispatch();
  const questionnaireValue = useStoreSelector(
    (state) => state.questionnaire[questionnaireKey]
  );

  const handleOnChangeEvent = (newValue: QuestionnaireFormValues) => {
    dispatch(
      questionnaireSliceActions.setQuestionnaireValue({
        key: questionnaireKey,
        value: newValue,
      })
    );
  };

  return (
    <StyledSurveyRadioGroup.SurveyFormRadioGroup
      id={`questionnaire-${questionnaireKey}`}
      name={`${questionnaireKey}Group`}
      legend={legendLabel}
      vertical={false}
      options={questionnaireLabelAndValues}
      value={questionnaireValue}
      onChange={handleOnChangeEvent}
    />
  );
};

interface QuestionnaireRadioTemplateProps {
  questionnaireKey: QuestionnaireKeys;
  legendLabel: string;
}

export default QuestionnaireRadioTemplate;
