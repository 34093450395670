import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';
import { defaultPageTitle } from 'config/page';
import pushGoogleAnalyticsPage from 'utils/session/pushGoogleAnalyticsPage';

/**
 * Based off KIAMA starter app <BaseRoute /> component
 */
const BaseRoute = ({ pageStep, ...rest }: BaseRouteProps): JSX.Element => {
  useEffect(() => {
    const formattedStepString = pageStep ? ` - ${pageStep}` : '';
    document.title = defaultPageTitle + formattedStepString;

    pushGoogleAnalyticsPage(pageStep);
  }, [pageStep]);

  return <Route {...rest} />;
};

interface BaseRouteProps extends RouteProps {
  pageStep: string;
}

export default BaseRoute;
