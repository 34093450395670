import styled from 'styled-components';
import { Heading } from '@snsw-gel/react';

// styled-components recommends ampersands (&) over !important
const LeadingHeading = styled(Heading)`
  && {
    margin-top: 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }
`;

export default LeadingHeading;
