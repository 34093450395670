import { mainElementId } from 'consts/links';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import usePrevious from 'utils/hooks/usePrevious';

const PageChangeMonitor: React.FC = () => {
  const { pathname } = useLocation();
  const previousPathname = usePrevious(pathname);

  useEffect(() => {
    if (previousPathname && previousPathname !== pathname) {
      focusMainContent();
    }
  }, [pathname]);

  return null;
};

export default PageChangeMonitor;

const focusMainContent = (): void => {
  const mainElement = document.getElementById(mainElementId);

  if (mainElement) {
    mainElement.tabIndex = -1;
    mainElement.focus();
    mainElement.scrollIntoView();
  }
};
