import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { questionnaireSessionManager } from 'utils/session/storeSessionManagers';

export type QuestionnaireFormValues = 'yes' | 'no';

export interface QuestionnaireState {
  nswResidentialAddress: QuestionnaireFormValues | null;
  requiredAge: QuestionnaireFormValues | null;
  hasNSWOrInterstateDriverLicence: QuestionnaireFormValues | null;
  overseasDriverLicence: QuestionnaireFormValues | null;
  disqualifiedSuspendedRefusedFromDriving: QuestionnaireFormValues | null;
  overdueFines: QuestionnaireFormValues | null;
  learnerLicenceExpired: QuestionnaireFormValues | null;
  fullLicenceExpired: QuestionnaireFormValues | null;
}
export type QuestionnaireKeys = keyof QuestionnaireState;

export const initialState: QuestionnaireState = {
  nswResidentialAddress: null,
  requiredAge: null,
  hasNSWOrInterstateDriverLicence: null,
  overseasDriverLicence: null,
  disqualifiedSuspendedRefusedFromDriving: null,
  overdueFines: null,
  learnerLicenceExpired: null,
  fullLicenceExpired: null,
};
const stateKeys = Object.keys(initialState) as Array<keyof typeof initialState>;
const acceptedValues = [null, 'yes', 'no'];

export const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    setQuestionnaireValue: (
      state,
      action: PayloadAction<{
        key: QuestionnaireKeys;
        value: QuestionnaireFormValues | null;
      }>
    ) => {
      const { key, value } = action.payload;
      if (!(key in state)) return;
      state[key] = value;

      if (key === 'hasNSWOrInterstateDriverLicence' && value === 'no') {
        // reset Q3.1 and Q3.2 questions when Q3 has changed: subflow questions
        state.learnerLicenceExpired = null;
        state.fullLicenceExpired = null;
      }
      if (key === 'learnerLicenceExpired' && value === 'yes') {
        // reset Q3.2 when Q3.1 has changed: subflow questions
        state.fullLicenceExpired = null;
      }
    },
    saveToSessionData: (state) => {
      questionnaireSessionManager.setValue(JSON.stringify(state));
    },
    restoreFromSessionData: (state) => {
      const sessionData = questionnaireSessionManager.getValue();
      if (!sessionData) return;

      const data = JSON.parse(sessionData);
      stateKeys.forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const value = data[key];
          if (acceptedValues.includes(value)) {
            state[key] = value;
          }
        }
      });
    },
  },
});

export const questionnaireLabelAndValues = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

export const questionnaireSliceActions = questionnaireSlice.actions;
export default questionnaireSlice.reducer;
