import React from 'react';
import { HorizontalRule } from 'components/HorizontalRule/HorizontalRule.styled';
import ExistingCustomerForm from 'components/ExistingCustomerForm/ExistingCustomerForm';
import ExistingCustomerFormInPageAlert from 'components/ExistingCustomerFormInPageAlert/ExistingCustomerFormInPageAlert';
import FormPrefaceInformation from './FormPrefaceInformation/FormPrefaceInformation';

const ExitingCustomer: React.FC = () => {
  return (
    <>
      <FormPrefaceInformation />
      <HorizontalRule />
      <ExistingCustomerFormInPageAlert />
      <ExistingCustomerForm />
    </>
  );
};

export default ExitingCustomer;
