import React from 'react';
import {
  ServiceCentreHyperlink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';

const FormPrefaceInformation = (): JSX.Element => (
  <section>
    <p>
      If you have a current or expired licence, registration or permit, we may
      already have your details.
    </p>
    <p>
      If you have a document and cannot provide all of the details{' '}
      <strong>do not continue</strong>, call us on{' '}
      <TelephoneContactNumberHyperlink /> or visit a <ServiceCentreHyperlink />.
    </p>
  </section>
);

export default FormPrefaceInformation;
