import React, { useState } from 'react';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import { Heading, Button } from '@snsw-gel/react';
import { analyticsServiceV2 } from '@rmstransactions/components';
import { postEnrolmentDetails } from 'api/api';
import { TFNSWCardType } from 'api/types/drives';
import type { TransportIdentificationData } from 'api/types/request';
import { FormNavigationButtonWrapper } from 'components/ButtonStyling/ButtonStyling.styled';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import routes from 'config/routes';
import { errorTermsAndConditionsInvalid } from 'consts/errors/inLineErrors';
import { enrolmentIdentificationType } from 'consts/transport';
import { useStoreDispatch, useStoreSelector } from 'store';
import { reviewSliceActions } from 'store/slice/review';
import { TFNSWCardData } from 'store/slice/tfNSWCustomer';
import handledSystemMessages from 'utils/api/handledSystemMessages';
import { handleResponseData } from 'utils/api/httpClient';
import {
  analyticsEnrolmentType,
  analyticsPushButtonEvent,
} from 'utils/session/pushGoogleAnalyticsPage';
import PrivacyStatement from './PrivacyStatement/PrivacyStatement';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import ConsentDKTContact from './ConsentDKTContact/ConsentDKTContact';
import SecondaryActionButton from './SecondaryActionButton/SecondaryActionButton';
import * as Styled from './ReviewDeclarations.styled';
const { defaultAnalyticsNullValue } = analyticsServiceV2;

const ReviewDeclarations: React.FC = () => {
  const dispatch = useStoreDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const termsAndConditions = useStoreSelector(
    (state) => state.review.termsAndConditions
  );
  const consentDKTContact = useStoreSelector(
    (state) => state.review.consentDKTContact
  );
  const identificationType = useStoreSelector(
    (state) => state.customer.enrolmentIdentificationType
  );
  const tfNSWCustomerData = useStoreSelector(
    (state) => state.tfNSWCustomer.data
  );
  const tfNSWCardType = useStoreSelector(
    (state) => state.tfNSWCustomer.tfNSWCardType
  );
  const history = useHistory();

  const handleErrorRedirect = () => {
    history.push(routes.genericError.path);
  };

  const handlePostEnrolment = async () => {
    if (!identificationType) {
      handleErrorRedirect();
      return;
    }

    setIsLoading(true);
    let drivesProductData: null | TransportIdentificationData = null;

    const existingDrivesCustomerEnrolment =
      identificationType === enrolmentIdentificationType.drivesProduct;
    if (existingDrivesCustomerEnrolment) {
      if (tfNSWCardType === null || tfNSWCardType === 'n/a') {
        // should not be null or n/a, path error or bug
        handleErrorRedirect();
        return;
      }

      drivesProductData = constructDrivesProductData(
        tfNSWCardType,
        tfNSWCustomerData
      );
    }

    const response = await postEnrolmentDetails(
      identificationType,
      consentDKTContact,
      drivesProductData
    );
    const data = handleResponseData(response);
    if (data === null) {
      handleErrorRedirect();
      return;
    }

    // check system messages
    const messageStatus = handledSystemMessages(data.systemMessages, history);
    if (messageStatus.hasError) {
      if (!messageStatus.redirected) {
        dispatch(
          reviewSliceActions.setErrorNotification(messageStatus.message)
        );
        setIsLoading(false);
      }
      return;
    }

    history.push(routes.confirmation.path);
  };

  const handleSubmitButton = () => {
    if (!termsAndConditions) {
      dispatch(
        reviewSliceActions.setErrorNotification(errorTermsAndConditionsInvalid)
      );
      return;
    }
    dispatch(reviewSliceActions.setErrorNotification(null));

    analyticsPushButtonEvent({
      component_type: 'button_primary',
      component_text: 'Submit',
      section_id: 'page_content',
      position_id: '1',
      destination_url: window.location.origin + routes.confirmation.path,
      transaction_sub_type:
        analyticsEnrolmentType.getValue() ?? defaultAnalyticsNullValue,
    });
    handlePostEnrolment();
  };

  return (
    <>
      <section>
        <Heading level={2}>Declaration</Heading>

        <Styled.CheckboxWrapper>
          <TermsAndConditions />
          <ConsentDKTContact />
        </Styled.CheckboxWrapper>

        <PrivacyStatement />

        <FormNavigationButtonWrapper>
          <Button onClick={handleSubmitButton}>Submit</Button>
          <SecondaryActionButton />
        </FormNavigationButtonWrapper>
      </section>
      {isLoading && <FullPageLoader />}
    </>
  );
};

export default ReviewDeclarations;

export const constructDrivesProductData = (
  tfNSWCardType: TFNSWCardType,
  tfNSWCustomerData: TFNSWCardData
): TransportIdentificationData => {
  const dateOfBirth = tfNSWCustomerData.dateOfBirth
    ? formatTransportDate(tfNSWCustomerData.dateOfBirth)
    : '';

  return {
    firstName: tfNSWCustomerData.firstName || '',
    middleName: tfNSWCustomerData.middleName || '',
    lastName: tfNSWCustomerData.lastName || '',
    dateOfBirth: dateOfBirth,
    tfNSWCardNumber: tfNSWCustomerData.tfNSWCardNumber || '',
    tfNSWCardType,
  };
};

// convert to transport date format "YYYY-MM-DD"
const formatTransportDate = (date: string) => {
  return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
};
