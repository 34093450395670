import React from 'react';
import { InPageAlert } from '@snsw-gel/react';
import { useStoreSelector } from 'store';
import {
  ExternalTextLink,
  ServiceCentreHyperlink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';
import { nswKnowledgeAndDrivingTestExemptions } from 'consts/links';
import QuestionnaireRadioTemplate from '../QuestionnaireRadioTemplate/QuestionnaireRadioTemplate';
import { QuestionnaireFormValues } from 'store/slice/questionnaire';

/**
 * QUESTION 4
 *
 * VISIBLE => Q3 === NO || Q3.1 === YES || Q3.2 === YES \
 * YES => show Info & move to Q5\
 * NO => move to Q5
 *  */
const OverseasDriverLicence: React.FC = () => {
  const hasNSWOrInterstateDriverLicence = useStoreSelector(
    (state) => state.questionnaire.hasNSWOrInterstateDriverLicence
  );
  const learnerLicenceExpired = useStoreSelector(
    (state) => state.questionnaire.learnerLicenceExpired
  );
  const fullLicenceExpired = useStoreSelector(
    (state) => state.questionnaire.fullLicenceExpired
  );
  const overseasDriverLicence = useStoreSelector(
    (state) => state.questionnaire.overseasDriverLicence
  );

  const hasCriteria = checkVisibilityByCriteria({
    hasNSWOrInterstateDriverLicence,
    learnerLicenceExpired,
    fullLicenceExpired,
  });
  const isVisible = overseasDriverLicence !== null || hasCriteria;
  if (!isVisible) return <></>;

  return (
    <>
      <QuestionnaireRadioTemplate
        questionnaireKey='overseasDriverLicence'
        legendLabel='Do you have an overseas driver licence (current or expired)?'
      />
      {overseasDriverLicence === 'yes' && (
        <InPageAlert
          variant='info'
          title='Check if you need to pass a Driver Knowledge Test'
        >
          <p>
            Only some overseas licence holders need to pass the DKT to convert
            their licence.{' '}
            <ExternalTextLink href={nswKnowledgeAndDrivingTestExemptions}>
              Check if this applies to you
            </ExternalTextLink>
            .
          </p>
          <p>
            If you pass DKT online but find out later you were not required to
            do it, you must still pay the test fee when you apply for your
            licence.
          </p>
          <p>
            If you are unsure, call us on <TelephoneContactNumberHyperlink /> or
            visit a <ServiceCentreHyperlink />.
          </p>
        </InPageAlert>
      )}
    </>
  );
};

export default OverseasDriverLicence;

type QuestionnaireValue = QuestionnaireFormValues | null;

export const checkVisibilityByCriteria = ({
  hasNSWOrInterstateDriverLicence,
  learnerLicenceExpired,
  fullLicenceExpired,
}: {
  hasNSWOrInterstateDriverLicence: QuestionnaireValue;
  learnerLicenceExpired: QuestionnaireValue;
  fullLicenceExpired: QuestionnaireValue;
}): boolean => {
  if (hasNSWOrInterstateDriverLicence === 'no') {
    // Question 3 criteria into Q4
    return true;
  } else if (
    hasNSWOrInterstateDriverLicence === 'yes' &&
    learnerLicenceExpired === 'yes'
  ) {
    // Question 3.1 criteria into Q4
    return true;
  } else if (
    hasNSWOrInterstateDriverLicence === 'yes' &&
    learnerLicenceExpired === 'no' &&
    fullLicenceExpired === 'yes'
  ) {
    // Question 3.2 criteria into Q4
    return true;
  }
  return false;
};
