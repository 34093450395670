import styled, { css } from 'styled-components';
import { InPageAlert as SNSWInPageAlert } from '@snsw-gel/react';

export const InPageAlert = styled(SNSWInPageAlert)<InPageAlertProps>`
  margin: 0;

  ${({ $marginTop }) =>
    $marginTop &&
    css`
      margin-top: 2.5rem;
    `}

  ${({ $marginBottom }) =>
    $marginBottom &&
    css`
      margin-bottom: 2.5rem;
    `}
`;

interface InPageAlertProps {
  $marginTop?: boolean;
  $marginBottom?: boolean;
}
