import React from 'react';
import * as Styled from './AdditionalInformation.styled';
import { tfNSWCardTypes } from 'consts/transport';
import { TelephoneContactNumberHyperlink } from 'components/Hyperlinks';
import { useStoreSelector } from 'store';

const AdditionalInformation: React.FC = () => {
  const tfNSWCardType = useStoreSelector(
    (state) => state.tfNSWCustomer.tfNSWCardType
  );

  if (tfNSWCardType !== tfNSWCardTypes.drivesCustomerNumber) return <></>;

  return (
    <div>
      <Styled.CalloutWrapper>
        <Styled.CalloutParagraph>
          If you don’t have a document with your customer number displayed or
          cannot find these details, call us on{' '}
          <TelephoneContactNumberHyperlink /> for assistance.
        </Styled.CalloutParagraph>
      </Styled.CalloutWrapper>
    </div>
  );
};

export default AdditionalInformation;
