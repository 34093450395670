import React from 'react';
import { HorizontalRule } from 'components/HorizontalRule/HorizontalRule.styled';
import NSWResidentialAddress from './NSWResidentialAddress/NSWResidentialAddress';
import RequiredAge from './RequiredAge/RequiredAge';
import HasNSWOrInterstateDriverLicence from './HasNSWOrInterstateDriverLicence/HasNSWOrInterstateDriverLicence';
import LearnerLicenceExpiredOrExpire from './LearnerLicenceExpiredOrExpire/LearnerLicenceExpiredOrExpire';
import FullLicenceExpired from './FullLicenceExpired/FullLicenceExpired';
import OverseasDriverLicence from './OverseasDriverLicence/OverseasDriverLicence';
import DisqualifiedSuspendedRefusedFromDriving from './DisqualifiedSuspendedRefusedFromDriving/DisqualifiedSuspendedRefusedFromDriving';
import OverdueFines from './OverdueFines/OverdueFines';
import SubmitAndValidate from './SubmitAndValidate/SubmitAndValidate';

const Questionnaire: React.FC = () => {
  return (
    <>
      <HorizontalRule disableMarginTop disableMarginBottom />
      <form>
        <NSWResidentialAddress />
        <RequiredAge />

        <HasNSWOrInterstateDriverLicence />
        <LearnerLicenceExpiredOrExpire />
        <FullLicenceExpired />

        <OverseasDriverLicence />
        <DisqualifiedSuspendedRefusedFromDriving />
        <OverdueFines />

        <SubmitAndValidate />
      </form>
    </>
  );
};

export default Questionnaire;
