import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getEnrolmentStatus } from 'api/api';
import { Config } from 'config/env';
import routes from 'config/routes';
import {
  checkDKTEnrolmentStatus,
  enrolmentIdentificationType,
} from 'consts/transport';
import { useStoreDispatch, useStoreSelector } from 'store';
import { customerSliceActions } from 'store/slice/customer';
import { handleResponseData } from 'utils/api/httpClient';
import handledSystemMessages from 'utils/api/handledSystemMessages';

const DKTEnrolmentStatus: React.FC<DKTEnrolmentStatusProps> = ({
  callbackFn,
}) => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const isDKTLinkedCustomer = useStoreSelector(
    (state) => state.customer.isDKTLinked
  );

  useEffect(() => {
    const redirectToGenericErrorPage = () => {
      history.push(routes.genericError.path);
    };

    const handleEnrolmentStatus = async () => {
      const response = await getEnrolmentStatus();
      const data = handleResponseData(response);
      if (data === null) {
        redirectToGenericErrorPage();
        return;
      }

      // handle system messages
      const messageStatus = handledSystemMessages(data.systemMessages, history);
      if (messageStatus.hasError) {
        return;
      }

      // no status found
      const dktEnrolmentStatus = data.status;
      if (!dktEnrolmentStatus) {
        redirectToGenericErrorPage();
        return;
      }

      switch (dktEnrolmentStatus) {
        case checkDKTEnrolmentStatus.passed:
          history.push(routes.passedTest.path);
          return;
        case checkDKTEnrolmentStatus.active:
          window.location.href = Config.componoURL;
          return;
        case checkDKTEnrolmentStatus.reEnrolment:
          // no redirect
          dispatch(
            customerSliceActions.setEnrolmentType(
              enrolmentIdentificationType.reEnrolment
            )
          );
          callbackFn();
          return;
        default:
          redirectToGenericErrorPage();
          return;
      }
    };

    if (isDKTLinkedCustomer) {
      handleEnrolmentStatus();
    } else {
      callbackFn();
    }
  }, []);

  return null;
};

export default DKTEnrolmentStatus;

interface DKTEnrolmentStatusProps {
  callbackFn: () => void;
}
