import React, { useEffect } from 'react';
import { FormLayout } from 'components/Layouts';
import { pageHeadingLabel } from 'config/page';
import { clearSessionFormData } from 'utils/session/storeSessionManagers';
import EnrolmentInformationBox from './EnrolmentInformationBox/EnrolmentInformationBox';
import PrefaceInformation from './PrefaceInformation/PrefaceInformation';
import CallToActions from './CallToActions/CallToActions';
import ApiHandler from './ApiHandler/ApiHandler';

const LandingPage: React.FC = () => {
  useEffect(() => {
    clearSessionFormData();
  }, []);

  return (
    <>
      <FormLayout
        title='Welcome to DKT online enrolment'
        label={pageHeadingLabel}
        stepsList={null}
      >
        <PrefaceInformation />
        <EnrolmentInformationBox />
        <CallToActions />
      </FormLayout>
      <ApiHandler />
    </>
  );
};

export default LandingPage;
