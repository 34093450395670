import React, { useEffect } from 'react';
import { PageLayout } from 'components/Layouts';
import NotificationMessage from 'components/NotificationMessage/NotificationMessage';
import { defaultPageTitle, genericErrorTitle } from 'config/page';
import { genericSystemFailureError } from 'consts/errors/systemErrors';

const ErrorBoundaryPage: React.FC = () => {
  useEffect(() => {
    document.title = `${defaultPageTitle} - Error`;
  }, []);

  return (
    <PageLayout title={genericErrorTitle}>
      <NotificationMessage message={genericSystemFailureError} />
    </PageLayout>
  );
};

export default ErrorBoundaryPage;
