import React from 'react';
import HorizontalRule from 'components/HorizontalRule/HorizontalRule';
import DescriptionContent from './DescriptionContent/DescriptionContent';
import SelfSurvey from './SelfSurvey/SelfSurvey';

const HealthAndDriving: React.FC = () => {
  return (
    <>
      <DescriptionContent />
      <HorizontalRule />
      <SelfSurvey />
    </>
  );
};

export default HealthAndDriving;
