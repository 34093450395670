import Styled from 'styled-components';
import { Button } from '@snsw-gel/react';

export const ExternalButton = Styled(Button)`
  && {
    text-decoration: none;
    
    > span {
      display: none;
    }
  }
`;
