import React from 'react';
import * as Styled from './EmailInput.styled';

const EmailInput: React.FC<EmailInputProps> = ({
  id,
  name,
  label = '',
  value,
  onChange,
  placeholder,
  hasError = false,
  errorMessage = '',
}) => {
  return (
    <Styled.EmailFormGroup
      id={id}
      name={name}
      label={label}
      hasError={hasError}
      errorMessage={errorMessage}
      $noLabel={label === ''}
    >
      <Styled.TextInput
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        $hasError={hasError}
      />
    </Styled.EmailFormGroup>
  );
};

export default EmailInput;

interface EmailInputProps {
  id: string;
  name?: string;
  label?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hasError?: boolean;
  errorMessage?: string;
  placeholder?: string;
}
