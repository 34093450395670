import React from 'react';
import * as Styled from './HorizontalCard.styled';

const HorizontalCard: React.FC<HorizontalCardProps> = ({
  headingText,
  imageSource,
  altText,
  children,
}) => {
  return (
    <Styled.CardLayout>
      <Styled.Image src={imageSource} alt={altText} />
      <Styled.ContentLayout>
        {headingText && (
          <Styled.Heading level={4} headingElement='h3'>
            {headingText}
          </Styled.Heading>
        )}
        <Styled.ChildWrapper>{children}</Styled.ChildWrapper>
      </Styled.ContentLayout>
    </Styled.CardLayout>
  );
};

export default HorizontalCard;

interface HorizontalCardProps {
  headingText?: string;
  imageSource: string;
  altText: string;
}
