import React from 'react';
import AdditionalInformation from './AdditionalInformation/AdditionalInformation';
import InputDateOfBirth from './InputDateOfBirth/InputDateOfBirth';
import InputFamilyName from './InputFamilyName/InputFamilyName';
import InputTemplate from './InputTemplate/InputTemplate';
import InputTFNSWNumber from './InputTFNSWNumber/InputTFNSWNumber';
import CardInfographic from './CardInfographic/CardInfographic';
import * as Styled from './DetailsSubForm.styled';

const DetailsSubForm: React.FC<DetailsSubFormProps> = ({ formID }) => {
  return (
    <Styled.SubFormWrapper id={formID}>
      <CardInfographic />
      <InputTemplate
        dataKey='firstName'
        label='Given name'
        requiredErrorMessage='Enter your given name as it appears on the card.'
        isSpaceAllowed={true}
      />
      <InputTemplate
        dataKey='middleName'
        label='Middle name (optional)'
        requiredErrorMessage=''
        isSpaceAllowed={true}
      />
      <InputFamilyName />
      <InputDateOfBirth />
      <InputTFNSWNumber />
      <AdditionalInformation />
    </Styled.SubFormWrapper>
  );
};

interface DetailsSubFormProps {
  formID: string;
}

export default DetailsSubForm;
