import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import {
  NormalizeCSS,
  MyAccountNav,
  MyAccountNavConfig,
} from '@rmstransactions/components';
import { GlobalNav } from '@snsw/react-global-nav';
import { GlobalStyle, theme } from '@snsw-gel/react';
import store from 'store';
import { Config } from 'config/env';
import routes from 'config/routes';
import BaseRoute from 'components/BaseRoute/BaseRoute';
import ErrorBoundaryHandler from 'components/ErrorBoundaryHandler/ErrorBoundaryHandler';
import CheckEligibility from 'pages/CheckEligibility/CheckEligibility';
import ReviewAndSubmit from 'pages/ReviewAndSubmit/ReviewAndSubmit';
import Confirmation from 'pages/Confirmation/Confirmation';
import LandingPage from 'pages/LandingPage/LandingPage';
import PassedPage from 'pages/PassedPage/PassedPage';
import ExistingCustomerDVS from 'pages/ExistingCustomerDVS/ExistingCustomerDVS';
import ErrorPages from 'pages/ErrorPages/ErrorPages';
import PageChangeMonitor from 'components/PageChangeMonitor/PageChangeMonitor';
import TimeoutHandler from './components/Layouts/TimeoutHandler/TimeoutHandler';

const { globalNavEnv } = Config;

MyAccountNavConfig.setupGlobalNavUri(globalNavEnv);
MyAccountNavConfig.setLoggedInUser();

const App = (): JSX.Element => {
  return (
    <>
      <NormalizeCSS />
      <TimeoutHandler />
      <GlobalNav accountNavLinks={<MyAccountNav />}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <ErrorBoundaryHandler>
            <Provider store={store}>
              <Router>
                <PageChangeMonitor />
                <Switch>
                  {ErrorPages}
                  <BaseRoute
                    path={routes.checkEligibilityFoundExistingCustomer.path}
                    pageStep={
                      routes.checkEligibilityFoundExistingCustomer.pageStep
                    }
                  >
                    <ExistingCustomerDVS />
                  </BaseRoute>
                  <BaseRoute
                    path={routes.checkEligibility.path}
                    pageStep={routes.checkEligibility.pageStep}
                  >
                    <CheckEligibility />
                  </BaseRoute>
                  <BaseRoute
                    path={routes.confirmationSupport.path}
                    pageStep={routes.confirmationSupport.pageStep}
                  >
                    <Confirmation />
                  </BaseRoute>
                  <BaseRoute
                    path={routes.confirmation.path}
                    pageStep={routes.confirmation.pageStep}
                  >
                    <Confirmation />
                  </BaseRoute>
                  <BaseRoute
                    path={routes.passedTest.path}
                    pageStep={routes.passedTest.pageStep}
                  >
                    <PassedPage />
                  </BaseRoute>
                  <BaseRoute
                    path={routes.reviewAndSubmit.path}
                    pageStep={routes.reviewAndSubmit.pageStep}
                  >
                    <ReviewAndSubmit />
                  </BaseRoute>
                  <BaseRoute
                    path={routes.postPoi.path}
                    pageStep={routes.postPoi.pageStep}
                  >
                    <ReviewAndSubmit postPoi />
                  </BaseRoute>
                  <BaseRoute
                    path={routes.landingPage.path}
                    pageStep={routes.landingPage.pageStep}
                    exact
                  >
                    <LandingPage />
                  </BaseRoute>
                </Switch>
              </Router>
            </Provider>
          </ErrorBoundaryHandler>
        </ThemeProvider>
      </GlobalNav>
    </>
  );
};

export default App;
