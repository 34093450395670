import styled from 'styled-components';
import { tokens } from '@snsw-gel/react';

export const SubFormWrapper = styled.div`
  margin: 1.5rem 0;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    margin: 2rem 0;
  }
`;
