import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit';
import customerReducer from './slice/customer';
import healthAndDrivingConditionsReducer from './slice/healthAndDrivingConditions';
import questionnaireReducer from './slice/questionnaire';
import tfNSWCustomerReducer from './slice/tfNSWCustomer';
import { confirmationReducer } from './slice/confirmation';
import eligibilityFormReducer from './slice/eligibilityForm';
import reviewReducer from './slice/review';

const reducers = combineReducers({
  customer: customerReducer,
  healthAndDrivingConditions: healthAndDrivingConditionsReducer,
  questionnaire: questionnaireReducer,
  tfNSWCustomer: tfNSWCustomerReducer,
  confirmation: confirmationReducer,
  eligibilityForm: eligibilityFormReducer,
  review: reviewReducer,
});
export type ReducerRootState = ReturnType<typeof reducers>;

const reduxStore = configureStore({
  reducer: reducers,
});

export type ReducersStoreType = typeof reduxStore;
export type StoreType = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;

export const useStoreDispatch: () => AppDispatch = useDispatch;
export const useStoreSelector: TypedUseSelectorHook<StoreType> = useSelector;
const storeInitialState = reduxStore.getState();

export const setupReduxStore = (
  preloadedState?: Partial<PreloadedState<ReducerRootState>>
): ReducersStoreType => {
  return configureStore({
    reducer: reducers,
    preloadedState: {
      ...storeInitialState,
      ...preloadedState,
    },
  });
};

export default reduxStore;
