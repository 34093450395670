import React from 'react';
import { tfNSWCardTypes } from 'consts/transport';
import { useStoreSelector } from 'store';
import InputTemplate from '../InputTemplate/InputTemplate';

const InputFamilyName: React.FC = () => {
  const tfNSWCardType = useStoreSelector(
    (state) => state.tfNSWCustomer.tfNSWCardType
  );

  return (
    <InputTemplate
      dataKey='lastName'
      label='Family name'
      helpMessage={
        tfNSWCardType === tfNSWCardTypes.drivesLicenceNumber
          ? drlHelperMessage
          : ''
      }
      requiredErrorMessage='Enter your family name as it appears on the card.'
      isSpaceAllowed={true}
      isRequired={true}
    />
  );
};

export default InputFamilyName;

const drlHelperMessage =
  'This is printed on the licence card in CAPITAL LETTERS.';
export const familyNameLabel = 'Family name';
