import React from 'react';
import dayjs from 'dayjs';
import { DatePicker } from '@snsw-gel/react';
import { useStoreDispatch, useStoreSelector } from 'store';
import {
  tfNSWCustomerSliceActions,
  validationInvalidAge,
  validationInvalidValue,
  validationRequiredValue,
} from 'store/slice/tfNSWCustomer';
import { subFormIdPrefix } from '../InputTemplate/InputTemplate';
import * as Styled from './InputDateOfBirth.styled';

// Set max date to today
const inputMaxDate = dayjs().format('YYYY-MM-DD');

const InputDateOfBirth: React.FC = () => {
  const dispatch = useStoreDispatch();
  const dateOfBirth = useStoreSelector(
    (state) => state.tfNSWCustomer.data.dateOfBirth
  );
  const validation = useStoreSelector(
    (state) => state.tfNSWCustomer.validation.dateOfBirth
  );

  const onChangeHandler = (
    _event: CustomEvent<unknown>,
    date: DatePickerDate
  ) => {
    dispatch(
      tfNSWCustomerSliceActions.setCardData({
        key: 'dateOfBirth',
        value: date.value,
      })
    );
  };

  const errorMessage =
    validation && validation in validationErrorsMessages
      ? validationErrorsMessages[validation]
      : '';

  const fieldId = `${subFormIdPrefix}dateOfBirth`;

  return (
    <Styled.DatePickerFormGroup
      id={fieldId}
      label={dateOfBirthLabel}
      errorMessage={errorMessage}
      hasError={Boolean(validation)}
      isRequired
    >
      <DatePicker
        id={`${fieldId}-picker`}
        name='date of birth'
        value={dateOfBirth ?? ''}
        onChange={onChangeHandler}
        onBlur={() => null} // required field in '@snsw-gel/react'
        placeholder='DD/MM/YYYY'
        min='1900-01-01'
        max={inputMaxDate}
        label={dateOfBirthLabel}
        disabled={false}
      />
    </Styled.DatePickerFormGroup>
  );
};

export default InputDateOfBirth;

interface DatePickerDate {
  isValid: boolean;
  isoDate: string;
  longDate: string;
  shortDate: string;
  value: string;
}

export const dateOfBirthLabel = 'Date of birth';

const validationErrorsMessages = {
  [validationInvalidValue]:
    'Special characters, spaces or full stops are not accepted. Select a valid date and try again.',
  [validationRequiredValue]: 'Enter your date of birth to continue.',
  [validationInvalidAge]:
    'The date entered is not eligible. You must be 15 years and 11 months, please try again.',
};
