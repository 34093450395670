import React, { useState } from 'react';
import { Heading, AccordionItem } from '@snsw-gel/react';
import { AccordionWithoutExpandCollapse } from 'components/AccordionStyling/AccordionStyling.styled';
import getNewActiveAccordionSet from 'components/AccordionStyling/getNewActiveAccordionSet';
import {
  contentAccessLearningModules,
  contentOverseasLicence,
  contentRegionalOrRemoteArea,
  contentSupportGettingLicence,
} from './AccordionItemContent';
import * as Styled from './ImportantInformation.styled';

const ImportantInformation: React.FC = () => {
  const [activeAccordion, setActiveAccordion] = useState(
    new Set<accordionKeyIds>([])
  );

  return (
    <section>
      <Styled.ContentWrapper>
        <Heading level={3}>Important information</Heading>
        <AccordionWithoutExpandCollapse
          id='important-information'
          expandedKeys={activeAccordion}
          onExpandedChange={(newState) => {
            const newAccordion = getNewActiveAccordionSet(
              activeAccordion,
              newState
            );
            setActiveAccordion(newAccordion as Set<accordionKeyIds>);
          }}
        >
          <AccordionItem
            id='regionalRemote'
            title='How do I get to a Service centre if I live in a regional or remote area?'
          >
            {contentRegionalOrRemoteArea}
          </AccordionItem>
          <AccordionItem
            id='overseas-licence'
            title="I'm transferring an overseas licence - what do I do next?"
          >
            {contentOverseasLicence}
          </AccordionItem>
          <AccordionItem
            id='learning-modules'
            title='Can I access the DKT online learning modules again?'
          >
            {contentAccessLearningModules}
          </AccordionItem>
          <AccordionItem
            id='getting-licence'
            title='Need support with getting your licence?'
          >
            {contentSupportGettingLicence}
          </AccordionItem>
        </AccordionWithoutExpandCollapse>
      </Styled.ContentWrapper>
    </section>
  );
};

export default ImportantInformation;

type accordionKeyIds =
  | 'regional-remote'
  | 'overseas-licence'
  | 'learning-modules'
  | 'getting-licence';
