import React from 'react';
import { subFormIdPrefix } from 'components/ExistingCustomerForm/DetailsSubForm/InputTemplate/InputTemplate';
import { tfNSWCardTypes } from 'consts/transport';
import { useStoreSelector } from 'store';
import { TFNSWCardValidation } from 'store/slice/tfNSWCustomer';

const InvalidMessage: React.FC<MessageProps> = ({ fieldKey }) => {
  const cardType = useStoreSelector(
    (state) => state.tfNSWCustomer.tfNSWCardType
  );
  if (!cardType) return <></>;

  // for "tfNSWCardNumber" use that instead of the display label (eg. "Customer Number", "vehicle’s plate", etc.)
  const labelKey = fieldKey === 'tfNSWCardNumber' ? cardType : fieldKey;
  if (!(labelKey in invalidValidationMessage)) return <></>;

  const errorMessage = invalidValidationMessage[labelKey];
  return <li>{errorMessage}</li>;
};

export default InvalidMessage;

export interface MessageProps {
  fieldKey: keyof TFNSWCardValidation;
}

const linkToInputById = (fieldKey: string) => `#${subFormIdPrefix + fieldKey}`;
const constructFieldMessage = (
  fieldKey: string,
  label: string
): React.ReactNode => (
  <>
    Check your <a href={linkToInputById(fieldKey)}>{label}</a> details entered
    and try again.
  </>
);
const constructTransportCardMessage = (
  fieldKey: string,
  label: string
): React.ReactNode => (
  <>
    <a href={linkToInputById('tfNSWCardNumber')}>{label}</a> number entered and
    try again.
  </>
);

const {
  drivesCustomerNumber,
  drivesLicenceNumber,
  nswPlateNumber,
  nswPhotoCard,
  mobilityParkingScheme,
} = tfNSWCardTypes;

export const invalidValidationMessage: Record<string, React.ReactNode> = {
  firstName: constructFieldMessage('firstName', 'Given name'),
  middleName: constructFieldMessage('middleName', 'Middle name'),
  lastName: constructFieldMessage('lastName', 'Family name'),
  dateOfBirth: constructFieldMessage('dateOfBirth', 'Date of birth'),
  [drivesCustomerNumber]: constructTransportCardMessage(
    drivesCustomerNumber,
    'Check your Customer number'
  ),
  [drivesLicenceNumber]: constructTransportCardMessage(
    drivesLicenceNumber,
    'Check your NSW Driver Licence'
  ),
  [nswPlateNumber]: constructTransportCardMessage(
    nswPlateNumber,
    'Check your vehicle’s plate'
  ),
  [nswPhotoCard]: constructTransportCardMessage(
    nswPhotoCard,
    'Check your NSW Photo Card'
  ),
  [mobilityParkingScheme]: constructTransportCardMessage(
    mobilityParkingScheme,
    'Check your MPS permit card'
  ),
};
