import React from 'react';
import { InPageAlert } from '@snsw-gel/react';
import {
  ExternalTextLink,
  ServiceCentreHyperlink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';
import { proofOfIdentityLink } from 'consts/links';

const InPageWarning: React.FC = () => (
  <InPageAlert
    variant='warning'
    title='We found an existing customer record for you'
  >
    <p>
      We see that you hold, or previously held, one of the items listed below.
      We need to collect these details to proceed online.
    </p>
    <p>
      If you can&apos;t provide these details, please visit a service centre
      with your{' '}
      <ExternalTextLink id='link-poi' href={proofOfIdentityLink}>
        proof of identity documents
      </ExternalTextLink>{' '}
      to enrol in DKT online.
    </p>
    <p>
      If you need assistance, call us on <TelephoneContactNumberHyperlink /> or
      visit a <ServiceCentreHyperlink />.
    </p>
  </InPageAlert>
);

export default InPageWarning;
