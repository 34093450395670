import React from 'react';
import {
  ServiceCentreHyperlink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';
import TemplateErrorPage from './TemplateErrorPage/TemplateErrorPage';

const NSWAddressErrorPage: React.FC = () => {
  return (
    <TemplateErrorPage
      pageTitle='Sorry, you cannot proceed online'
      defaultAlertTitle='Your address cannot be verified'
      analyticsErrorType='validation error'
      contactDescription={contactDescription}
    />
  );
};

export default NSWAddressErrorPage;

const contactDescription = (
  <>
    <p>
      Something went wrong when trying to update your address. This may be due to your
      property being a new development or recently subdivided.
    </p>
    <p>
      Need help? Call <TelephoneContactNumberHyperlink /> or visit a{' '}<ServiceCentreHyperlink />.
    </p>
  </>
);
