import React from 'react';
import { TFNSWCardType } from 'api/types/drives';
import { useStoreSelector } from 'store';
import InputTemplate from '../InputTemplate/InputTemplate';

const InputTFNSWNumber: React.FC = () => {
  const tfNSWCardType = useStoreSelector(
    (state) => state.tfNSWCustomer.tfNSWCardType
  );

  if (
    tfNSWCardType === null ||
    tfNSWCardType === 'n/a' ||
    tfNSWCardType in inputFieldVariables === false
  )
    return <></>;

  const { label, helpMessage, validationMissingMessage } =
    inputFieldVariables[tfNSWCardType];

  return (
    <InputTemplate
      dataKey='tfNSWCardNumber'
      label={label}
      helpMessage={helpMessage}
      requiredErrorMessage={validationMissingMessage}
      isRequired={true}
    />
  );
};

export default InputTFNSWNumber;

interface FieldVariables {
  label: string;
  helpMessage: string;
  validationMissingMessage: string;
}

// TODO: figure out a way to type this, does not accept [x: string]
const inputFieldVariables: Record<TFNSWCardType, FieldVariables> = {
  NPC: {
    label: 'PC Number',
    helpMessage:
      'Please enter your PC number exactly as it appears on your card without spaces.',
    validationMissingMessage:
      'Enter your card number exactly as it appears on the card.',
  },
  DRL: {
    label: 'Licence number',
    helpMessage:
      'Please enter the number you see printed on the card under ‘Licence No.’ without spaces.',
    validationMissingMessage: 'Enter your licence number to proceed.',
  },
  PLT: {
    label: 'NSW plate number',
    helpMessage:
      'Please enter your registered vehicle plate number without spaces or special characters, for example: ABC123',
    validationMissingMessage: 'Enter your plate number to proceed.',
  },
  MPS: {
    label: 'MPS card number',
    helpMessage:
      'Please enter your card number exactly as it appears on your card without spaces.',
    validationMissingMessage: 'Enter your MPS card number to proceed.',
  },
  DCI: {
    label: 'Customer number',
    helpMessage:
      'Please enter your customer number without spaces or special characters.',
    validationMissingMessage: 'Enter your customer number to continue.',
  },
};
