import React from 'react';
import * as Styled from './PrefaceInformation.styled';

const PrefaceInformation: React.FC = () => (
  <section>
    <Styled.Wrapper>
      <Styled.ContentWrapper>
        <p>
          <strong>Driver Knowledge Test (DKT) online</strong> is an interactive
          course that teaches you about road safety and road rules in NSW. It
          should take approximately 4-6 hours to complete.
        </p>
        <p>
          <strong>
            It includes the online driver knowledge test you must pass to get
            your C class (car) learner licence.
          </strong>
        </p>
      </Styled.ContentWrapper>
      <Styled.Image
        src='/images/learner licence.svg'
        alt='Three young people celebrating getting their licence. Image by Storyset on Freepik.'
      />
    </Styled.Wrapper>
  </section>
);

export default PrefaceInformation;
