import type {
  EnrolmentIdentificationType,
  TFNSWCardType,
} from 'api/types/drives';
import type { CheckDKTEnrolmentStatus } from 'api/types/response';

const drivesCustomerNumber = 'DCI';
const drivesLicenceNumber = 'DRL';
const nswPlateNumber = 'PLT';
const nswPhotoCard = 'NPC';
const mobilityParkingScheme = 'MPS';

export const tfNSWCardTypes: Record<string, TFNSWCardType> = {
  drivesCustomerNumber,
  drivesLicenceNumber,
  nswPlateNumber,
  nswPhotoCard,
  mobilityParkingScheme,
};

export const tfNSWCardTypeLabels: Record<TFNSWCardType, string> = {
  [drivesCustomerNumber]: 'Customer number',
  [drivesLicenceNumber]: 'NSW Driver Licence',
  [nswPlateNumber]: 'NSW registered vehicle',
  [nswPhotoCard]: 'NSW Photo Card',
  [mobilityParkingScheme]: 'Mobility Parking Scheme permit',
};

const dvs = 'DVS';
const drivesProduct = 'DRIVES_PRODUCT';
const linked = 'LINKED';
const reEnrolment = 'RE_ENROLMENT';
export const enrolmentIdentificationType: Record<
  string,
  EnrolmentIdentificationType
> = {
  dvs,
  drivesProduct,
  linked,
  reEnrolment,
};

const enrolmentStatusPassed = 'passed';
const enrolmentStatusActive = 'active';
const enrolmentStatusReEnrolment = 'reEnrolment';
const enrolmentStatusOther = 'n/a';
export const checkDKTEnrolmentStatus: Record<string, CheckDKTEnrolmentStatus> =
  {
    passed: enrolmentStatusPassed,
    active: enrolmentStatusActive,
    reEnrolment: enrolmentStatusReEnrolment,
    other: enrolmentStatusOther,
  };
