import React from 'react';
import { InPageAlert } from '@snsw-gel/react';
import {
  ServiceCentreHyperlink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';

const ErrorNotification: React.FC<ErrorNotificationProps> = ({ errorType }) => {
  const { title, description } = pdfNotificationMessages[errorType];

  return (
    <InPageAlert variant='error' title={title}>
      <p>
        {description} Need help? Call us on <TelephoneContactNumberHyperlink />{' '}
        or visit a <ServiceCentreHyperlink />.
      </p>
    </InPageAlert>
  );
};

export default ErrorNotification;

export type ReceiptErrorType = 'print' | 'download' | 'email';
interface ErrorNotificationProps {
  errorType: ReceiptErrorType;
}

export const pdfNotificationMessages = {
  download: {
    title: 'Download failed',
    description:
      'There was a problem downloading your receipt, please try again.',
  },
  print: {
    title: 'Print failed',
    description: 'There was a problem printing your receipt, please try again.',
  },
  email: {
    title: 'Email failed to send',
    description: 'We were unable to send your email, please try again.',
  },
};
