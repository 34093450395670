import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getCustomerInfo } from 'api/api';
import routes from 'config/routes';
import { useStoreDispatch } from 'store';
import { customerSliceActions } from 'store/slice/customer';
import handledSystemMessages from 'utils/api/handledSystemMessages';
import { handleResponseData } from 'utils/api/httpClient';

const CallCustomerDetails: React.FC<CallCustomerDetailsProps> = ({
  callbackFn,
}) => {
  const dispatch = useStoreDispatch();
  const history = useHistory();

  useEffect(() => {
    const redirectGenericErrorPage = () => {
      history.push(routes.genericError.path);
    };

    const handleCustomerInfo = async () => {
      const response = await getCustomerInfo();
      const data = handleResponseData(response);

      if (data === null) {
        redirectGenericErrorPage();
        return;
      }

      // handle system messages
      const messageStatus = handledSystemMessages(data.systemMessages, history);
      if (messageStatus.hasError) {
        if (!messageStatus.redirected) {
          redirectGenericErrorPage();
        }
        return;
      }

      dispatch(customerSliceActions.setCustomerData(data));

      callbackFn();
    };

    handleCustomerInfo();
  }, []);

  return null;
};

export default CallCustomerDetails;

interface CallCustomerDetailsProps {
  callbackFn: () => void;
}
