import React from 'react';
import {
  ServiceCentreHyperlink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';
import TemplateErrorPage from './TemplateErrorPage/TemplateErrorPage';

const InterStateAddressErrorPage: React.FC = () => {
  return (
    <TemplateErrorPage
      pageTitle='Sorry, you cannot proceed online'
      defaultAlertTitle='It appears you’ve entered an interstate address'
      analyticsErrorType='validation error'
      contactDescription={contactDescription}
    />
  );
};

export default InterStateAddressErrorPage;

const contactDescription = (
  <>
    <p>
      You must have a NSW residential address to be eligible to enrol in DKT online. Check that your details are correct before you try again.
    </p>
    <p>
      Need help? Call <TelephoneContactNumberHyperlink /> or visit a{' '}<ServiceCentreHyperlink />.
    </p>
  </>
);
