import React from 'react';
import NotificationMessage from 'components/NotificationMessage/NotificationMessage';
import {
  ServiceCentreHyperlink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';
import { termsAndConditionIdentifier } from 'consts/errors/inLineErrors';
import { useStoreSelector } from 'store';
import NotificationWrapper from './NotificationWrapper';

const ErrorNotification: React.FC = () => {
  const errorNotification = useStoreSelector(
    (state) => state.review.errorNotification
  );

  if (!errorNotification) return <></>;

  return (
    <NotificationWrapper testID='inpage-alert'>
      <NotificationMessage message={errorNotification} marginBottom>
        {errorNotification.identifier !== termsAndConditionIdentifier &&
          contactHelpMessage}
      </NotificationMessage>
    </NotificationWrapper>
  );
};

export default ErrorNotification;

const contactHelpMessage = (
  <p>
    Need help? Call <TelephoneContactNumberHyperlink /> or visit a{' '}
    <ServiceCentreHyperlink />.
  </p>
);
