import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@snsw-gel/react';
import { enrolmentIdentificationType } from 'consts/transport';
import { useStoreSelector } from 'store';
import QuitDVSButton from './QuitDVSButton/QuitDVSButton';

const SecondaryActionButton: React.FC = () => {
  const identificationType = useStoreSelector(
    (state) => state.customer.enrolmentIdentificationType
  );
  const history = useHistory();

  if (identificationType === enrolmentIdentificationType.dvs) {
    return <QuitDVSButton />;
  }

  return (
    <Button onClick={() => history.goBack()} variant='back'>
      Back
    </Button>
  );
};

export default SecondaryActionButton;
