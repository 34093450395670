import React from 'react';
import { ExternalTextLink } from 'components/Hyperlinks';
import { nswMedicalConditionsAndFitnessToDrive } from 'consts/links';

const DescriptionContent: React.FC = () => (
  <section>
    <p>
      You <strong>can still enrol in DKT online</strong> if you have a condition
      below.
    </p>
    <p>
      When you apply for your licence in person, you <em>must</em> prove you are{' '}
      <ExternalTextLink href={nswMedicalConditionsAndFitnessToDrive}>
        fit to drive
      </ExternalTextLink>
      . If you are unsure, discuss this with your doctor or specialist.
    </p>
  </section>
);

export default DescriptionContent;
