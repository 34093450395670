import React from 'react';
import * as Styled from './LicenceRadioGroup.styled';

const LicenceRadioLabel: React.FC<LicenceRadioLabel> = ({
  labelText,
  indexID,
  helperText,
}) => {
  return (
    <Styled.LicenceRadioLabelWrapper>
      <span aria-describedby={`${indexID}-clarifyText`}>{labelText}</span>
      <br />
      <Styled.LicenceRadioLabelHelperText id={`${indexID}-clarifyText`}>
        {helperText}
      </Styled.LicenceRadioLabelHelperText>
    </Styled.LicenceRadioLabelWrapper>
  );
};

interface LicenceRadioLabel {
  indexID: string;
  labelText: string;
  helperText: string;
}

export default LicenceRadioLabel;
