/**
 * Use: for only one accordion open at a time using the KIAMA component
 *
 * compares the two sets from the accordion and returns the last clicked accordion
 * also handles closing the active accordion and opening a new accordion
 *
 * @param newSet set from the prop `onExpandedChange((newSet) => {...})`
 * @param previousSet the current active accordion, from the prop `expandedKeys`
 * @returns a new set with the new selected accordion ID
 */

const getNewActiveAccordionSet = <T>(
  previousSet: Set<T>,
  newSet: Set<T>
): Set<T> => {
  if (previousSet.size === 0) {
    // opening a new accordion with none open
    return newSet;
  }
  if (newSet.size === 0) {
    // closing current accordion
    return newSet;
  }

  // switching an accordion active from one to another
  const updatedSet = new Set();
  newSet.forEach((key) => {
    if (!previousSet.has(key)) {
      updatedSet.add(key);
    }
  });
  return updatedSet as Set<T>;
};

export default getNewActiveAccordionSet;
