import styled from 'styled-components';
import { tokens, Heading as SNSWHeading } from '@snsw-gel/react';
import { cardShadow } from 'utils/styling';

// created a midway breakpoint, card looked bad in between tablet - desktop
// approved by designer
export const midwaySmMdBreakpoint =
  (tokens.breakpoints.sm + tokens.breakpoints.md) / 2;

export const CardLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 1.5rem 1rem;
  margin: 1.5rem 0;

  @media screen and (min-width: ${midwaySmMdBreakpoint}px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1.5rem;
    column-gap: 1.5rem;
    padding: 1.5rem;
  }

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 3rem;
    padding: 2rem;
  }

  border-radius: 6px;
  ${cardShadow}
`;

export const ContentLayout = styled.div`
  @media screen and (min-width: ${midwaySmMdBreakpoint}px) {
    grid-column: span 2;
  }
`;

export const ChildWrapper = styled.div`
  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  max-height: 8.5rem;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    max-height: none;
  }
`;

export const Heading = styled(SNSWHeading)`
  && {
    margin-top: 0;
    margin-bottom: 1rem;
    color: ${tokens.colors.brand.snswDarkGrey};
  }
`;
