import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getPoiRedirectUrl } from 'api/api';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import routes from 'config/routes';
import { profileConnectCallbackQueryParameter } from 'config/constants';
import { handleResponseData } from 'utils/api/httpClient';
import useQuery from 'utils/hooks/useQuery';
import * as Styled from '../../DetailsBox.styled';

const EditDvsButton: React.FC = () => {
  const history = useHistory();
  const query = useQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleEditButton = async () => {
    const redirectToGenericErrorPage = () => {
      history.push(routes.genericError.path);
    };

    const sessionId = query.get(profileConnectCallbackQueryParameter);
    if (!sessionId) {
      redirectToGenericErrorPage();
      return;
    }

    setIsLoading(true);
    const response = await getPoiRedirectUrl();
    const poiRedirectUrl = handleResponseData(response);

    if (poiRedirectUrl === null || typeof poiRedirectUrl !== 'string') {
      redirectToGenericErrorPage();
      return;
    }

    const redirectUrl = new URL(poiRedirectUrl);
    redirectUrl.searchParams.append(
      profileConnectCallbackQueryParameter,
      sessionId
    );
    window.location.href = redirectUrl.toString();
  };

  return (
    <>
      <Styled.NormalWeightButton variant='link' onClick={handleEditButton}>
        Edit
        <Styled.IconWrapper>
          <img alt='' src='/images/editIcon.svg' />
        </Styled.IconWrapper>
      </Styled.NormalWeightButton>
      {isLoading && <FullPageLoader />}
    </>
  );
};

export default EditDvsButton;
