import styled from 'styled-components';
import { Accordion } from '@snsw-gel/react';

// designer has request we remove expand/collapse all buttons
export const AccordionWithoutExpandCollapse = styled(Accordion)`
  > div > button {
    display: none;
  }
`;

export const AccordionContentStyling = styled.div`
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  > *:is(h1, h2, h3, h4, h5, h6) {
    margin-bottom: 0;
  }
  > *:is(p) {
    margin-top: 0.25rem;
  }
`;
