import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

if (process.env.REACT_APP_USE_MOCK_API === 'true') {
  import('utils/test/msw').then((mswWorker) => {
    mswWorker.default.start();
    ReactDOM.render(<App />, document.getElementById('root'));
  });
} else {
  ReactDOM.render(<App />, document.getElementById('root'));
}
