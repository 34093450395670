const setupSystemErrorRouting = (
  mappedSystemErrorRoutes: Record<string, string | null>,
  defaultErrorPath: string | null,
  ignoredSeverities: Array<
    'SUCCESS' | 'INFORMATION' | 'WARNING' | 'ERROR' | string
  >
) => {
  const getErrorMessageAndRoute = (
    messages: DriveSystemMessages[]
  ): null | VerifiedErrorMessage => {
    if (!Array.isArray(messages)) return null;

    const errorMessage = messages.find(
      (message) => !ignoredSeverities.includes(message.severity)
    );

    if (!errorMessage) return null;

    const errorMessagePath =
      errorMessage.identifier in mappedSystemErrorRoutes
        ? mappedSystemErrorRoutes[errorMessage.identifier]
        : defaultErrorPath;

    return {
      path: errorMessagePath,
      message: errorMessage,
    };
  };

  return getErrorMessageAndRoute;
};

const systemMessages = {
  setupSystemErrorRouting,
};

export default systemMessages;

interface DriveSystemMessages {
  identifier: string;
  classification: string;
  severity: string;
  message: string;
  messageTitle: string;
  messageDescription: string;
}

interface VerifiedErrorMessage {
  path: string | null;
  message: DriveSystemMessages;
}
