import React from 'react';
import {
  ServiceCentreHyperlink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';
import TemplateErrorPage from './TemplateErrorPage/TemplateErrorPage';

const EligibleErrorPage: React.FC = () => {
  return (
    <TemplateErrorPage
      pageTitle='Sorry, you cannot proceed online'
      defaultAlertTitle='You are not eligible to enrol in DKT online'
      analyticsErrorType='validation error'
      contactDescription={contactDescription}
    />
  );
};

export default EligibleErrorPage;

const contactDescription = (
  <p>
    Need help? Call us on <TelephoneContactNumberHyperlink /> or visit a{' '}
    <ServiceCentreHyperlink />.
  </p>
);
