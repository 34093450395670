import { AxiosResponse, AxiosError } from 'axios';
import { transactionName } from 'config/constants';
import HttpClient from 'utils/api/httpClient';
import type { UserType } from './types/generics';
import type {
  ConfirmDetailsResponse,
  ConfirmationResponse,
  CustomerInfoResponse,
  EnrolmentStatusResponse,
  PoiRedirectUrlResponse,
  PostPoiResponse,
} from './types/response';
import { EnrolmentIdentificationType } from './types/drives';
import { SendEmailBody, TransportIdentificationData } from './types/request';

export const getUserType = async (): Promise<
  AxiosResponse<UserType> | AxiosError
> => {
  return HttpClient.get('/anon/userType', {
    params: {
      state: transactionName,
    },
  });
};

export const getCustomerInfo = async (): Promise<
  AxiosResponse<CustomerInfoResponse> | AxiosError
> => {
  return HttpClient.get('/auth/customer-info');
};

export const getEnrolmentStatus = async (): Promise<
  AxiosResponse<EnrolmentStatusResponse> | AxiosError
> => {
  return HttpClient.get('/auth/dkt/dkt-enrolment-status');
};

export const getPoiRedirectUrl = async (
  init = false
): Promise<AxiosResponse<PoiRedirectUrlResponse> | AxiosError> => {
  return HttpClient.get(
    `/auth/dkt/request-poi/${init === true ? 'true' : 'false'}`
  );
};

export const getConfirmationData = async (
  uuid: string | null
): Promise<AxiosResponse<ConfirmationResponse> | AxiosError> => {
  if (uuid) {
    return HttpClient.get(`/support/dkt/confirmation/${uuid}`, {
      withCredentials: false,
    });
  }
  return HttpClient.get('/auth/dkt/confirmation');
};

export const postEnrolmentDetails = async (
  identificationType: EnrolmentIdentificationType,
  surveyConsent: boolean,
  tfNSWCardData: TransportIdentificationData | null
): Promise<AxiosResponse<ConfirmDetailsResponse> | AxiosError> => {
  const body = {
    identificationType,
    surveyConsent: surveyConsent ? 'Y' : 'N',
    ...(tfNSWCardData && { transportIdentificationData: tfNSWCardData }),
  };

  return HttpClient.post('/auth/dkt/confirm-details', body);
};

export const getCustomerPoiData = async (
  sessionId: string
): Promise<AxiosResponse<PostPoiResponse> | AxiosError> => {
  return HttpClient.get('/auth/dkt/customer-poi-data', {
    headers: { 'x-session-id': sessionId },
  });
};

export const postReceiptSendEmail = async (
  body: SendEmailBody
): Promise<AxiosResponse<string> | AxiosError> => {
  if (body.uuid) {
    return HttpClient.post('/support/dkt/send-email', body, {
      withCredentials: false,
    });
  }

  return HttpClient.post('/auth/dkt/send-email', body);
};

export const forceUserLogout = async (): Promise<
  AxiosResponse<unknown> | AxiosError
> => {
  return HttpClient.get('/logout');
};
