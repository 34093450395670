import React from 'react';
import styled from 'styled-components';
import { IconNotificationInfo, tokens } from '@snsw-gel/react';

// wider width from default Kiama
const closeModalButtonWidth = '48px';

export const SlidingModalWrapper = styled.span`
  div[role='dialog'] {
    @media screen and (max-width: ${tokens.breakpoints.xl}px) {
      left: calc(20% + ${closeModalButtonWidth});
      width: calc(100% - 20% - ${closeModalButtonWidth});
    }
    @media screen and (max-width: ${tokens.breakpoints.md}px) {
      left: ${closeModalButtonWidth};
      width: calc(100% - ${closeModalButtonWidth});
    }
  }
`;

const IconWrapper = styled.div`
  display: inline-block;
  margin-left: 0.25rem;
  margin-top: -0.125rem;
`;

const IconPositioner = styled.div`
  position: relative;
  top: 0.25rem;
`;

// added a white outline for hover link effect to keep icon visible
const iconOutlineWidth = 1;
const StyledIconNotificationInfo = styled(IconNotificationInfo)`
  && {
    width: calc(1rem + ${iconOutlineWidth * 2}px);
    height: calc(1rem + ${iconOutlineWidth * 2}px);
    border: ${iconOutlineWidth}px solid white;
    border-radius: 50%;
  }
`;

export const LinkTextNotificationIcon = (): JSX.Element => {
  return (
    <IconWrapper>
      <IconPositioner>
        <StyledIconNotificationInfo />
      </IconPositioner>
    </IconWrapper>
  );
};
