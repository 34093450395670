import styled from 'styled-components';
import { InPageAlert as SNSWInPageAlert, tokens } from '@snsw-gel/react';

export const InPageAlert = styled(SNSWInPageAlert)`
  margin: 2rem 0;
`;

// the styling inside the InPageAlert is overwriting the default inside the modal
// this is because the modal is not rendered on the body but inside the alert itself
export const ContentChildSpacing = styled.div`
  && {
    p,
    ul {
      margin: 1rem 0;
    }

    a {
      color: ${tokens.colors.text.link};
    }
  }
`;
