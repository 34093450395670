import React from 'react';
import LeadingHeading from 'components/LeadingHeading/LeadingHeading.styled';
import { useStoreSelector } from 'store';

const FormPreface: React.FC = () => {
  const displayName = useStoreSelector((state) => state.customer.displayName);
  const headingText = `Hello${displayName ? ` ${displayName}` : ''},`;

  return (
    <section>
      <LeadingHeading level={2}>{headingText}</LeadingHeading>
      <p>
        To enrol in the Driver Knowledge Test (DKT) online - we need to check
        you are eligible, please complete the following sections.
      </p>
    </section>
  );
};

export default FormPreface;
