import React from 'react';
import { useStoreSelector } from 'store';
import QuestionnaireRadioTemplate from '../QuestionnaireRadioTemplate/QuestionnaireRadioTemplate';

/**
 * QUESTION 5
 *
 * VISIBLE => Q4\
 * YES => END: ineligible\
 * NO => move to Q6
 *  */
const DisqualifiedSuspendedRefusedFromDriving: React.FC = () => {
  const overseasDriverLicence = useStoreSelector(
    (state) => state.questionnaire.overseasDriverLicence
  );
  const disqualifiedSuspendedRefusedFromDriving = useStoreSelector(
    (state) => state.questionnaire.disqualifiedSuspendedRefusedFromDriving
  );

  const isVisible =
    disqualifiedSuspendedRefusedFromDriving !== null ||
    overseasDriverLicence !== null;
  if (!isVisible) return <></>;

  return (
    <QuestionnaireRadioTemplate
      questionnaireKey='disqualifiedSuspendedRefusedFromDriving'
      legendLabel='Have you ever been disqualified, suspended or refused from driving a vehicle, or riding a motorcycle, in Australia or overseas?'
    />
  );
};

export default DisqualifiedSuspendedRefusedFromDriving;
