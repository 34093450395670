const focusById = (id: string): void => {
  const element = document.getElementById(id);
  element?.focus();
};

const scrollToById = (id: string): void => {
  const element = document.getElementById(id);
  element?.scrollIntoView();
};

const documentUtilities = {
  focusById,
  scrollToById,
};

export default documentUtilities;
