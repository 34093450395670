import React from 'react';
import ExistingCustomerForm from 'components/ExistingCustomerForm/ExistingCustomerForm';
import ExistingCustomerFormAlert from 'components/ExistingCustomerFormInPageAlert/ExistingCustomerFormInPageAlert';
import { FormLayout } from 'components/Layouts';
import { SmallHorizontalRule } from 'components/HorizontalRule/HorizontalRule.styled';
import { pageHeadingLabel } from 'config/page';
import InPageWarning from './InPageWarning/InPageWarning';
import FormHeading from './FormHeading/FormHeading';

const ExistingCustomerDVS: React.FC = () => {
  return (
    <FormLayout
      title='Check your eligibility'
      label={pageHeadingLabel}
      stepsList={null}
    >
      <InPageWarning />
      <ExistingCustomerFormAlert />
      <FormHeading />
      <SmallHorizontalRule />
      <ExistingCustomerForm isExistingCustomer />
    </FormLayout>
  );
};

export default ExistingCustomerDVS;
