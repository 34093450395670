import React from 'react';
import {
  ServiceCentreHyperlink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';
import { drupalPageLocation } from 'config/constants';
import TemplateErrorPage from './TemplateErrorPage/TemplateErrorPage';

const SystemErrorPage: React.FC = () => {
  return (
    <TemplateErrorPage
      pageTitle="We're sorry about this"
      defaultAlertTitle='Due to a system issue, our services are not available at the moment'
      analyticsErrorType='system error'
      contactDescription={contactDescription}
    />
  );
};

export default SystemErrorPage;

const contactDescription = (
  <p>
    Please <a href={drupalPageLocation}>try again later</a>, reach out to us at{' '}
    <TelephoneContactNumberHyperlink />, or stop by a <ServiceCentreHyperlink />{' '}
    for assistance.
  </p>
);
