import styled from 'styled-components';
import { tokens } from '@snsw-gel/react';

// Copied from KIAMA <Callout />: must have title, does not support only body text
export const CalloutWrapper = styled.div`
  line-height: 1.5;
  border-left-width: 6px;
  border-left-style: solid;
  padding: 1rem 1rem 1rem 0.75rem;
  margin: 1rem 0rem;
  border-color: ${tokens.colors.brand.snswSecondaryBlue};

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
  }
`;

export const CalloutParagraph = styled.p`
  margin: 0;
`;
