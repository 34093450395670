import React from 'react';
import { useStoreSelector } from 'store';
import QuestionnaireRadioTemplate from '../QuestionnaireRadioTemplate/QuestionnaireRadioTemplate';

/**
 * QUESTION 3.2
 *
 * VISIBLE => Q3.1 === NO\
 * YES => move to Q4\
 * NO => QUIT: ineligible
 *  */
const FullLicenceExpired: React.FC = () => {
  const learnerLicenceExpired = useStoreSelector(
    (state) => state.questionnaire.learnerLicenceExpired
  );
  const fullLicenceExpired = useStoreSelector(
    (state) => state.questionnaire.fullLicenceExpired
  );

  const isVisible =
    fullLicenceExpired !== null || learnerLicenceExpired === 'no';
  if (!isVisible) return <></>;

  return (
    <QuestionnaireRadioTemplate
      questionnaireKey='fullLicenceExpired'
      legendLabel='Is this a full licence that has expired more than 5 years ago?'
    />
  );
};

export default FullLicenceExpired;
