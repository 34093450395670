import { History } from 'history';
import { systemMessages } from '@rmstransactions/components';
import { DriveMessageDetail } from 'api/types/drives';
import routes from 'config/routes';
import { mappedSystemErrorCodesToRoute } from 'consts/errors/systemErrors';

export const getErrorMessageAndRoute = systemMessages.setupSystemErrorRouting(
  mappedSystemErrorCodesToRoute,
  routes.genericError.path,
  ['SUCCESS']
);

/**
 * Checks for any non-SUCCESS message and redirects them to the appopriate page
 *
 * @param messages array of drive system messages from the API call
 * @param history return value from the useHistory() hook from react-router-dom
 * @returns
 *  hasError: returns true if a message has been found
 *  redirected: returns true if the path was not "null"
 *  message: the message object found
 */
export const handledSystemMessages = (
  messages: Array<DriveMessageDetail>,
  history: History<unknown>
): SystemErrorFound | SystemErrorNotFound => {
  const result = getErrorMessageAndRoute(messages);
  if (result === null) return { hasError: false };

  const { path, message } = result;
  if (path) {
    history.push(path, {
      systemMessage: message,
    });
    return { hasError: true, redirected: true, message: message };
  }

  return { hasError: true, redirected: false, message: message };
};

export default handledSystemMessages;

interface SystemErrorNotFound {
  hasError: false;
}

interface SystemErrorFound {
  hasError: true;
  redirected: boolean;
  message: DriveMessageDetail;
}
