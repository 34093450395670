import React from 'react';
import { ExternalTextLink } from 'components/Hyperlinks';
import { Components } from 'react-markdown';

export const hyperlinkNewPageExternal: Components = {
  a: ({ children, href, ...rest }) => (
    <ExternalTextLink href={href} {...rest}>
      {children}
    </ExternalTextLink>
  ),
};
