import React from 'react';
import ReactMarkdown from 'react-markdown';
import { MoreInfoModal } from '@snsw-gel/react';
import { SlidingModalWrapper } from 'components/MoreInfoModalStyling/MoreInfoModalStyling';
import { hyperlinkNewPageExternal } from 'utils/providers/reactMarkdown';
import * as StyledParent from '../PostFormInformation.styled';
import * as Styled from './InfoNewCustomer.styled';
import acceptedIdentityDocumentsMarkdown from './acceptedIdentityDocuments.md';

const InfoNewCustomer: React.FC = () => {
  return (
    <section data-testid={newCustomerTestID}>
      <StyledParent.InformationWrapper>
        <p>
          You’ve completed Step 1. In Step 2, you will need to provide{' '}
          <strong>one current identity document</strong> - make sure you have
          this ready to fill in the details required.
        </p>
        <SlidingModalWrapper>
          <Styled.InPageAlert
            variant='info'
            title='Proof of identity documents'
          >
            <p>
              Take a look at the list of DKT online{' '}
              <MoreInfoModal
                id='identity-documents'
                linkText='identity documents'
                title='Accepted identity documents'
                helpText={
                  <Styled.ContentChildSpacing>
                    <ReactMarkdown components={hyperlinkNewPageExternal}>
                      {acceptedIdentityDocumentsMarkdown}
                    </ReactMarkdown>
                  </Styled.ContentChildSpacing>
                }
                questionHyperLink
              />{' '}
              again.
            </p>
            <p>
              If you <strong>don’t have any</strong> of these documents, you
              cannot continue and will need to visit a NSW Service Centre - more
              documents are accepted in person.
            </p>
          </Styled.InPageAlert>
        </SlidingModalWrapper>
      </StyledParent.InformationWrapper>
    </section>
  );
};

export default InfoNewCustomer;

export const newCustomerTestID = 'info-newCustomer';
