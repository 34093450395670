import React from 'react';
import { enrolmentIdentificationType } from 'consts/transport';
import { useStoreSelector } from 'store';
import {
  EligibilityAccordionID,
  EligibilityValidationState,
} from 'store/slice/eligibilityForm';
import isReEnrolmentOrLinkedCustomer from 'utils/helpers/isReEnrolmentOrLinkedCustomer';
import InfoNewCustomer from './InfoNewCustomer/InfoNewCustomer';
import InfoExistingCustomer from './InfoExistingCustomer/InfoExistingCustomer';
import InfoReEnrolment from './InfoReEnrolment/InfoReEnrolment';

const PostFormInformation: React.FC = () => {
  const completeStatus = useStoreSelector(
    (state) => state.eligibilityForm.completeStatus
  );
  const identificationType = useStoreSelector(
    (state) => state.customer.enrolmentIdentificationType
  );

  const isLinkedOrAlreadyEnrolled =
    isReEnrolmentOrLinkedCustomer(identificationType);

  const completedForm = isFormCompleted(
    completeStatus,
    isLinkedOrAlreadyEnrolled
  );
  if (!completedForm) return <></>;

  if (isLinkedOrAlreadyEnrolled) return <InfoReEnrolment />;

  const hasEnteredDrivesProduct =
    identificationType === enrolmentIdentificationType.drivesProduct;
  if (hasEnteredDrivesProduct) return <InfoExistingCustomer />;

  return <InfoNewCustomer />;
};

export default PostFormInformation;

const isFormCompleted = (
  completeStatus: EligibilityValidationState,
  isReEnrolmentOrLinked: boolean
) => {
  let eligibilityFormIds = Object.keys(
    completeStatus
  ) as EligibilityAccordionID[];

  if (isReEnrolmentOrLinked) {
    // if customer is linked or re enrolling, don't check "existing customer" form status
    // accordion is not rendered for the user
    eligibilityFormIds = eligibilityFormIds.filter(
      (id) => id !== 'existingCustomer'
    );
  }

  const incompleteFormSections = eligibilityFormIds.filter(
    (id) => !completeStatus[id]
  );

  return incompleteFormSections.length === 0;
};
