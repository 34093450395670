const cleanNullKeysFromRecord = <T>(
  record: Record<string, any>
): Partial<T> => {
  return Object.keys(record).reduce((acc, key) => {
    if (record[key] !== null) {
      return {
        ...acc,
        [key]: record[key],
      };
    }
    return acc;
  }, {} as Partial<T>);
};

export default cleanNullKeysFromRecord;
