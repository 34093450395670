import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import * as Styled from './DisableTelOnTabletDesktop.styled';

/*
 * phoneNumber is expected as the LABEL not href
 * eg. "13 77 88"
 * */
const DisableTelOnTabletDesktop: React.FC<DisableTelOnTabletDesktopProps> = ({
  phoneNumber,
}) => {
  return (
    <>
      {isMobileOnly ? (
        <Styled.NoWrapTextLink href={`tel:${phoneNumber.replace(/\s+/g, '')}`}>
          {phoneNumber}
        </Styled.NoWrapTextLink>
      ) : (
        <Styled.NoWrapSpan>{phoneNumber}</Styled.NoWrapSpan>
      )}
    </>
  );
};

export default DisableTelOnTabletDesktop;

interface DisableTelOnTabletDesktopProps {
  phoneNumber: string;
}
