import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from '@snsw-gel/react';
import routes from 'config/routes';
import handleQuitTransaction from 'utils/routes/quitTransaction';

const OnlineNotificationsModal: React.FC<OnlineNotificationsModalProps> = ({
  onCloseModal,
}) => {
  const history = useHistory();
  const handleContinueButton = () => {
    history.push(routes.checkEligibility.path);
  };

  return (
    <Modal
      id='dkt-online-notifications'
      title='DKT online notifications'
      onClose={onCloseModal}
      buttons={[
        { text: 'I accept', onClick: handleContinueButton },
        { text: 'Quit', onClick: handleQuitTransaction.redirectToDrupal },
      ]}
    >
      <p>
        As part of enrolling in DKT online, you will receive notifications about
        your progress and important information related to next steps.
      </p>
      <p>
        These will be sent to your email provided as part of your MyServiceNSW
        Account login. You’ll receive notifications for:
      </p>
      <ol>
        <li>When you enrol in DKT online</li>
        <li>When you pass DKT online</li>
        <li>
          Four weeks before DKT online expires if you have not passed the test
        </li>
        <li>When DKT online has expired (12 months from enrolment)</li>
        <li>
          Four weeks before your online ‘Pass’ result expires and you haven’t
          obtained your driver licence
        </li>
      </ol>
    </Modal>
  );
};

export default OnlineNotificationsModal;

interface OnlineNotificationsModalProps {
  onCloseModal: () => void;
}
