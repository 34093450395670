import styled from 'styled-components';
import { Box as KiamaBox, tokens } from '@snsw-gel/react';

export const Box = styled(KiamaBox)`
  & {
    display: grid;
    grid-template-columns: ${tokens.spacing.icons.lg}px auto;
    gap: 1rem;
    border-radius: 0.375rem;

    margin: 1rem 0;
    @media screen and (min-width: ${tokens.breakpoints.md}px) {
      margin: 1.5rem 0;
    }
  }
`;

export const BoxIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BoxHeading = styled.h3`
  margin: auto 0;
`;

export const BoxDescription = styled.div`
  grid-column: 1 / span 2;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    grid-column: 2;
  }
`;
