import React from 'react';
import { subFormIdPrefix } from 'components/ExistingCustomerForm/DetailsSubForm/InputTemplate/InputTemplate';
import { tfNSWCardTypes } from 'consts/transport';
import { useStoreSelector } from 'store';
import { TFNSWCardValidation } from 'store/slice/tfNSWCustomer';

const RequiredMessage: React.FC<MessageProps> = ({ fieldKey }) => {
  const cardType = useStoreSelector(
    (state) => state.tfNSWCustomer.tfNSWCardType
  );
  if (!cardType) return <></>;

  // for "tfNSWCardNumber" use that instead of the display label (eg. "Customer Number")
  const labelKey = fieldKey === 'tfNSWCardNumber' ? cardType : fieldKey;
  if (!(labelKey in requiredValidationLabels)) return <></>;

  const label = requiredValidationLabels[labelKey];
  return (
    <li>
      <a href={`#${subFormIdPrefix + fieldKey}`}>Enter your {label} </a>
    </li>
  );
};

export default RequiredMessage;

export interface MessageProps {
  fieldKey: keyof TFNSWCardValidation;
}

export const requiredValidationLabels = {
  firstName: 'Given name',
  middleName: 'Middle name',
  lastName: 'Family name',
  dateOfBirth: 'Date of birth',
  [tfNSWCardTypes.drivesCustomerNumber]: 'Customer number',
  [tfNSWCardTypes.drivesLicenceNumber]: 'NSW Driver Licence number',
  [tfNSWCardTypes.nswPlateNumber]: 'registered vehicle’s number plate',
  [tfNSWCardTypes.nswPhotoCard]: 'NSW Photo Card number',
  [tfNSWCardTypes.mobilityParkingScheme]: 'MPS card number',
};
