import React from 'react';
import { ComponentLoader } from '@snsw-gel/react';

const FullPageLoader: React.FC<FullPageLoaderProps> = ({
  label = defaultLoaderLabel,
}) => <ComponentLoader label={label} fullPage />;

export default FullPageLoader;

interface FullPageLoaderProps {
  label?: string;
}

export const defaultLoaderLabel =
  'Processing, please wait. Do not refresh or navigate away.';
