import React from 'react';
import { Button, Heading } from '@snsw-gel/react';
import HorizontalCard from 'components/HorizontalCard/HorizontalCard';
import { ExternalTextLink } from 'components/Hyperlinks';
import { Config } from 'config/env';
import transactionCleanupEvents from 'utils/session/transactionCleanupEvents';
import * as Styled from './StartLearning.styled';

const StartLearning: React.FC = () => {
  const handleRedirectCompono = () => {
    transactionCleanupEvents();
    window.location.href = Config.componoURL;
  };

  return (
    <section>
      <Heading level={3} headingElement='h2'>
        Time to start learning
      </Heading>
      <HorizontalCard
        headingText='Step 1'
        imageSource='/images/Step 1.svg'
        altText='Two young people sitting and studying. Image by Storyset on Freepik.'
      >
        <p>
          Login and access <strong>DKT online</strong> via your{' '}
          <ExternalTextLink href={Config.myAccountBaseUrlOverview}>
            MyServiceNSW Account
          </ExternalTextLink>{' '}
          anytime. Click the button below to start now.
        </p>
        <p>
          Complete the interactive learning modules and take the online test as
          many times as you need to pass.
        </p>
      </HorizontalCard>
      <HorizontalCard
        headingText='Step 2'
        imageSource='/images/Step 2.svg'
        altText='Young person sitting in car with a driving instructor standing outside. Image by Storyset on Freepik.'
      >
        <p>
          When you complete and successfully <strong>pass DKT online</strong>,
          you can apply for your NSW Driver Licence.
        </p>
        <p>
          You’ll receive an email to confirm you have passed and next steps.
        </p>
      </HorizontalCard>
      <Styled.ButtonContainer>
        <Button onClick={handleRedirectCompono}>Start DKT online now</Button>
      </Styled.ButtonContainer>
    </section>
  );
};

export default StartLearning;
