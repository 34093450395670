// accessability
export const mainElementId = 'main-content';

// service.nsw.gov.au
export const snswContactTelephoneNumber = '13 77 88';
export const snswFindServiceCentre =
  'https://www.service.nsw.gov.au/service-centre';
export const snswHomePage = 'https://www.service.nsw.gov.au/';
export const serviceNswPrivacyLink = 'https://www.service.nsw.gov.au/privacy';

export const serviceNswApplyForALearnerDriverLicence =
  'https://www.service.nsw.gov.au/transaction/apply-for-a-learner-driver-licence';
export const serviceNswBookADriverLicenceTest =
  'https://www.service.nsw.gov.au/transaction/book-a-driver-or-rider-licence-test';
export const serviceNSWTransferAnInterstateDriverLicence =
  'https://www.service.nsw.gov.au/transaction/transfer-an-interstate-driver-licence';
export const serviceNSWTransferAnOverseasDriverLicence =
  'https://www.service.nsw.gov.au/transaction/transfer-an-overseas-driver-licence';
export const serviceNSWRenewOrUpgradeANSWDriverLicence =
  'https://www.service.nsw.gov.au/transaction/renew-or-upgrade-a-nsw-driver-licence';

// nsw.gov.au
export const nswMedicalConditionsAndFitnessToDrive =
  'https://www.nsw.gov.au/driving-boating-and-transport/driver-and-rider-licences/health-conditions-and-disability/fitness-to-drive';
export const nswEyesightTestsForDriverAndRiderLicensing =
  'https://www.nsw.gov.au/driving-boating-and-transport/driver-and-rider-licences/health-conditions-and-disability/fitness-to-drive/eyesight-testing';

export const nswDownloadLicenceApplicationForm =
  'https://www.nsw.gov.au/sites/default/files/2021-08/45070018-licence-application.pdf';

export const nswKnowledgeAndDrivingTestExemptions =
  'https://www.nsw.gov.au/driving-boating-and-transport/driver-and-rider-licences/visiting-or-moving-to-nsw/moving-your-overseas-licence/knowledge-and-driving-test-exemptions';
export const nswPayYourFineAnchorCommonQuestions =
  'https://www.nsw.gov.au/money-and-taxes/fines-and-fees/fines/pay-your-fine#toc-common-questions';
export const nswPrepareYourLicenceApplicationHyperlink =
  'https://www.nsw.gov.au/driving-boating-and-transport/driver-and-rider-licences/visiting-or-moving-to-nsw/moving-your-overseas-licence/prepare-your-application';
export const nswDriverLicensingAccessProgram =
  'https://www.nsw.gov.au/driving-boating-and-transport/driver-and-rider-licences/driver-licences/driver-licensing-access-program';
export const nswRoadUserHandbook =
  'https://www.nsw.gov.au/driving-boating-and-transport/roads-safety-and-rules/safety-updates-for-nsw-road-users/road-user-handbook';
export const nswLicencesFees =
  'https://www.nsw.gov.au/driving-boating-and-transport/driver-and-rider-licences/fees';

// transport.nsw.gov
export const transportNswPrivacyLink =
  'https://www.transport.nsw.gov.au/about-us/transport-privacy/privacy-statement';

export const transportNswDownloadWitnessLetterForm =
  'https://tfnswforms.transport.nsw.gov.au/45070768-witness-letter.pdf';

export const transportNswDownloadLicenceApplicationForm =
  'https://www.nsw.gov.au/sites/default/files/2021-08/45070018-licence-application.pdf';

export const proofOfIdentityLink =
  'https://www.nsw.gov.au/driving-boating-and-transport/driver-and-rider-licences/proof-of-identity/proving-your-identity#toc-primary-identification-documents---list-1';

export const proofOfIdentityLink2 =
  'https://www.nsw.gov.au/driving-boating-and-transport/driver-and-rider-licences/proof-of-identity/proving-your-identity#toc-secondary-identification-documents---list-2';
