import {
  serviceNSWRenewOrUpgradeANSWDriverLicence,
  serviceNSWTransferAnInterstateDriverLicence,
  serviceNSWTransferAnOverseasDriverLicence,
} from 'consts/links';

// This will be posted to Google Analytics
const eligibilityIdentifier = 'check your eligibility';

export const errorEligibilityNSWResidentialAddress = {
  identifier: eligibilityIdentifier,
  classification: 'BUSINESS',
  severity: 'ERROR',
  message: '',
  messageTitle: 'You must live in NSW to be eligible to enrol in DKT online',
  messageDescription:
    'If you are moving to NSW, you may be eligible to enrol in-person at a service centre.',
};

export const errorEligibilityRequiredAge = {
  identifier: eligibilityIdentifier,
  classification: 'BUSINESS',
  severity: 'ERROR',
  message: '',
  messageTitle:
    'You must be 15 years and 11 months of age, or older to enrol in DKT online',
  messageDescription:
    'You are not eligible until you meet this minimum age requirement.',
};

const descriptionFullLicenceExpired = `You may be eligible to transfer or renew your licence.

- [Transfer an interstate licence](${serviceNSWTransferAnInterstateDriverLicence}) 
- [Transfer an overseas licence](${serviceNSWTransferAnOverseasDriverLicence})
- [Renew your NSW Driver Licence](${serviceNSWRenewOrUpgradeANSWDriverLicence})
`;
export const errorFullLicenceExpired = {
  identifier: eligibilityIdentifier,
  classification: 'BUSINESS',
  severity: 'ERROR',
  message: '',
  messageTitle:
    'You cannot enrol in DKT if you have a licence that is still valid in NSW, interstate or overseas',
  messageDescription: descriptionFullLicenceExpired,
};

export const errorDisqualifiedSuspendedRefusedFromDriving = {
  identifier: eligibilityIdentifier,
  classification: 'BUSINESS',
  severity: 'ERROR',
  message: '',
  messageTitle:
    'If you have ever been disqualified, suspended, cancelled, or refused from driving a vehicle or riding a motorcycle you are not eligible to enrol in DKT online.',
};
