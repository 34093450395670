import React from 'react';
import { MoreInfoModal } from '@snsw/react-component-library'; // '@snsw-gel/react' bugged with <forms>
import { LinkTextNotificationIcon } from 'components/MoreInfoModalStyling/MoreInfoModalStyling';
import medicalConditionDisabilityMarkdown from './medicalConditionDisability.md';
import ModalMarkdownWrapper from '../MarkdownContentWrapper/MarkdownContentWrapper';
import {
  MoreInfoModalLabel,
  SpacingSlidingModalWrapper,
} from '../InformationModal.styles';

const MedicalConditionDisabilityModal: React.FC = () => {
  return (
    <SpacingSlidingModalWrapper>
      <MoreInfoModal
        id='drivingWithDisability'
        linkText={
          <MoreInfoModalLabel>
            {drivingWithDisabilityModalLabelText}
            <LinkTextNotificationIcon />
          </MoreInfoModalLabel>
        }
        title='Medical conditions and driving with a disability'
        helpText={
          <ModalMarkdownWrapper>
            {medicalConditionDisabilityMarkdown}
          </ModalMarkdownWrapper>
        }
        questionHyperLink
      />
    </SpacingSlidingModalWrapper>
  );
};

export default MedicalConditionDisabilityModal;

export const drivingWithDisabilityModalLabelText =
  'Open this tooltip to find out more about medical conditions and driving with a disability';
