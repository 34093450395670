import React, { useEffect, useRef } from 'react';
import * as Styled from 'components/NotificationMessage/NotificationMessage.styled';
import { useStoreDispatch, useStoreSelector } from 'store';
import {
  EligibilityAccordionID,
  eligibilityFormSliceActions,
} from 'store/slice/eligibilityForm';
import usePrevious from 'utils/hooks/usePrevious';

const EligibilityFormInPageAlert: React.FC = () => {
  const dispatch = useStoreDispatch();
  const errorStatus = useStoreSelector(
    (state) => state.eligibilityForm.errorStatus
  );
  const activeAccordion = useStoreSelector(
    (state) => state.eligibilityForm.activeAccordion
  );
  const ref = useRef<HTMLDivElement>(null);

  const validationErrors = formOrder.filter(
    (accordionId) => errorStatus[accordionId]
  );
  const currentErrorCount = validationErrors.length;
  const previousErrorCount = usePrevious(currentErrorCount);
  const hasValidationError = currentErrorCount > 0;

  const setFocusOnInPageAlert = () => {
    ref.current?.focus();
    ref.current?.scrollIntoView();
  };

  useEffect(() => {
    if (hasValidationError) {
      // incomplete form first detected
      setFocusOnInPageAlert();
    }
  }, [hasValidationError]);

  useEffect(() => {
    if (previousErrorCount && currentErrorCount > previousErrorCount) {
      // if the count of errors increases, focus back on alert
      setFocusOnInPageAlert();
    }
  }, [currentErrorCount]);

  const setActiveAccordion = (selectedAccordion: EligibilityAccordionID) => {
    if (activeAccordion !== selectedAccordion) {
      dispatch(
        eligibilityFormSliceActions.setActiveAccordion(selectedAccordion)
      );
    }
  };

  return (
    <div ref={ref} tabIndex={-1}>
      {hasValidationError && (
        <Styled.InPageAlert
          variant='error'
          title='You haven’t answered all of the eligibility questions'
          $marginBottom
        >
          <p>
            Please respond to all questions in the following sections to
            continue:
          </p>
          <ol>
            {validationErrors.map((id) => (
              <li key={`accordion-anchor-${id}`}>
                <a
                  href={`#accordion-${id}`}
                  onClick={() => setActiveAccordion(id)}
                >
                  {labels[id]}
                </a>
              </li>
            ))}
          </ol>
        </Styled.InPageAlert>
      )}
    </div>
  );
};

export default EligibilityFormInPageAlert;

const formOrder: EligibilityAccordionID[] = [
  'questionnaire',
  'healthAndDriving',
  'existingCustomer',
];
const labels = {
  questionnaire: 'Check your eligibility',
  healthAndDriving: 'Your health and driving',
  existingCustomer: 'Existing customer record',
};
