import React from 'react';
import { InPageAlert } from '@snsw-gel/react';
import {
  ExternalTextLink,
  ServiceCentreHyperlink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';
import { nswPayYourFineAnchorCommonQuestions } from 'consts/links';
import { useStoreSelector } from 'store';
import QuestionnaireRadioTemplate from '../QuestionnaireRadioTemplate/QuestionnaireRadioTemplate';

/**
 * QUESTION 6: END
 *
 * VISIBLE => Q5 === NO\
 * YES => show Info & END: eligible\
 * NO => END: eligible
 *  */
const OverdueFines: React.FC = () => {
  const disqualifiedSuspendedRefusedFromDriving = useStoreSelector(
    (state) => state.questionnaire.disqualifiedSuspendedRefusedFromDriving
  );
  const overdueFines = useStoreSelector(
    (state) => state.questionnaire.overdueFines
  );

  const isVisible =
    overdueFines !== null || disqualifiedSuspendedRefusedFromDriving === 'no';
  if (!isVisible) return <></>;

  return (
    <>
      <QuestionnaireRadioTemplate
        questionnaireKey='overdueFines'
        legendLabel='Do you have any overdue fines from Revenue NSW?'
      />
      {overdueFines === 'yes' && (
        <InPageAlert variant='info' title='Paying your fines'>
          <p>
            You can enrol in DKT online but will not be eligible to apply for
            your driver licence until you{' '}
            <ExternalTextLink href={nswPayYourFineAnchorCommonQuestions}>
              pay any overdue fines
            </ExternalTextLink>
            .
          </p>
          <p>
            Need help, call us on <TelephoneContactNumberHyperlink /> or visit a{' '}
            <ServiceCentreHyperlink /> for assistance.
          </p>
        </InPageAlert>
      )}
    </>
  );
};

export default OverdueFines;
