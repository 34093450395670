import React, { useEffect } from 'react';
import { useStoreDispatch } from 'store';
import { eligibilityFormSliceActions } from 'store/slice/eligibilityForm';
import { healthAndDrivingConditionsActions } from 'store/slice/healthAndDrivingConditions';
import { questionnaireSliceActions } from 'store/slice/questionnaire';
import { tfNSWCustomerSliceActions } from 'store/slice/tfNSWCustomer';
import {
  clearSessionFormData,
  dvsFormSessionManager,
} from 'utils/session/storeSessionManagers';

const RestoreDataDVSFlow: React.FC = () => {
  const dispatch = useStoreDispatch();

  useEffect(() => {
    if (dvsFormSessionManager.getValue() === 'true') {
      // set eligibility form data to pre-dvs redirect
      dispatch(questionnaireSliceActions.restoreFromSessionData());
      dispatch(healthAndDrivingConditionsActions.restoreFromSessionData());
      dispatch(tfNSWCustomerSliceActions.setCardType('n/a'));
      dispatch(eligibilityFormSliceActions.setFormComplete());

      // clear the saved eligibility form from session data
      clearSessionFormData();
    }
  }, []);

  return null;
};

export default RestoreDataDVSFlow;
