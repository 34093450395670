import React from 'react';
import { useStoreSelector } from 'store';
import isReEnrolmentOrLinkedCustomer from 'utils/helpers/isReEnrolmentOrLinkedCustomer';

const FormSubmitPreface: React.FC = () => {
  const identificationType = useStoreSelector(
    (state) => state.customer.enrolmentIdentificationType
  );

  if (isReEnrolmentOrLinkedCustomer(identificationType))
    return (
      <p>
        Your existing customer details will be used to process your enrolment.
      </p>
    );

  return (
    <p>
      Please review your details carefully before you submit your application.
    </p>
  );
};

export default FormSubmitPreface;
