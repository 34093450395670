import React from 'react';
import { InactiveTimeout } from '@rmstransactions/components';
import { forceUserLogout } from 'api/api';
import handleQuitTransaction from 'utils/routes/quitTransaction';

const TimeoutHandler: React.FC = () => {
  return <InactiveTimeout modalButtons={modalButtons} />;
};

export default TimeoutHandler;

const handleLogoutUser = async (callback: () => void) => {
  await forceUserLogout();
  callback();
};

export const modalButtons = [
  {
    text: 'Start again',
    onClick: (): void => {
      handleLogoutUser(handleQuitTransaction.redirectToDrupal);
    },
  },
  {
    text: 'Quit',
    onClick: (): void => {
      handleLogoutUser(handleQuitTransaction.redirectToSNSWHome);
    },
  },
];
