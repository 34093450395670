import React from 'react';
import { Heading } from '@snsw-gel/react';
import { ExternalTextLink } from 'components/Hyperlinks';
import {
  transportNswDownloadLicenceApplicationForm,
  transportNswDownloadWitnessLetterForm,
} from 'consts/links';

const YourSignature: React.FC = () => (
  <>
    <Heading level={4}>Your signature</Heading>
    <p>
      One of your proof of identity documents must show your signature. If it
      doesn&apos;t, you&apos;ll need to have your signature witnessed on your{' '}
      <ExternalTextLink href={transportNswDownloadLicenceApplicationForm}>
        Licence Application Form
      </ExternalTextLink>{' '}
      or complete a{' '}
      <ExternalTextLink href={transportNswDownloadWitnessLetterForm}>
        Witness Letter
      </ExternalTextLink>
      .
    </p>
  </>
);

export default YourSignature;
