export type StepContent =
  | 'Eligibility'
  | 'Your Identity'
  | 'Review and Submit'
  | 'Confirmation';
export type StepStatus = 'todo' | 'completed' | 'current';
export type FormStep = {
  content: string;
  status: StepStatus;
};

export const driverKnowledgeTestSteps: FormStep[] = [
  {
    content: 'Eligibility',
    status: 'todo',
  },
  {
    content: 'Your Identity',
    status: 'todo',
  },
  {
    content: 'Review and Submit',
    status: 'todo',
  },
  {
    content: 'Confirmation',
    status: 'todo',
  },
];

// progressive accordion wrapper for focus/scroll on the /eligibility page
export const accordionWrapperId = 'progressive-accordion';
