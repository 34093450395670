import styled from 'styled-components';
import { Box as SNSWBox, tokens } from '@snsw-gel/react';
import { midwaySmMdBreakpoint } from 'components/HorizontalCard/HorizontalCard.styled';

export const LeadingParagraph = styled.p`
  margin-top: 0;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    margin-bottom: 3.25rem;
  }
`;

export const AdditionalInformation = styled.div`
  @media screen and (min-width: ${midwaySmMdBreakpoint}px) {
    grid-column: span 3;
  }
`;

export const Box = styled(SNSWBox)`
  margin: 0;
`;
