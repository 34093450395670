import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { InPageAlert, Button } from '@snsw-gel/react';
import { FormNavigationButtonWrapper } from 'components/ButtonStyling/ButtonStyling.styled';
import { FormLayout } from 'components/Layouts';
import {
  ServiceCentreHyperlink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';
import { pageHeadingLabel } from 'config/page';
import { hyperlinkNewPageExternal } from 'utils/providers/reactMarkdown';
import { LocationState } from 'utils/routes/locationState';
import handleQuitTransaction from 'utils/routes/quitTransaction';
import { analyticsPushErrorPage } from 'utils/session/pushGoogleAnalyticsPage';

const TemplateErrorPage: React.FC<TemplateErrorPageProps> = ({
  pageTitle,
  defaultAlertTitle,
  analyticsErrorType = 'error',
  contactDescription = defaultContactDescription,
}) => {
  const location = useLocation<LocationState>();
  const systemMessage = location?.state?.systemMessage;

  const inPageAlertTitle = systemMessage?.messageTitle
    ? systemMessage.messageTitle
    : defaultAlertTitle;
  const inPageAlertVariant = getInPageAlertVariant(systemMessage?.severity);

  useEffect(() => {
    const analyticsErrorData = {
      error_type: analyticsErrorType,
      error_name: systemMessage?.identifier ?? 'unknown error',
      error_text: inPageAlertTitle,
      error_position: 'page content',
    };
    setTimeout(() => {
      analyticsPushErrorPage(analyticsErrorData);
    }, 0);
  }, []);

  return (
    <FormLayout title={pageTitle} label={pageHeadingLabel} stepsList={null}>
      <InPageAlert variant={inPageAlertVariant} title={inPageAlertTitle}>
        {systemMessage?.messageDescription && (
          <ReactMarkdown components={hyperlinkNewPageExternal}>
            {systemMessage.messageDescription}
          </ReactMarkdown>
        )}

        {contactDescription}
      </InPageAlert>
      <FormNavigationButtonWrapper>
        <Button
          variant='secondary'
          onClick={handleQuitTransaction.redirectToDrupal}
        >
          Quit
        </Button>
      </FormNavigationButtonWrapper>
    </FormLayout>
  );
};

export default TemplateErrorPage;

interface TemplateErrorPageProps {
  pageTitle: string;
  defaultAlertTitle: string;
  analyticsErrorType?: string;
  contactDescription?: JSX.Element;
}

const defaultContactDescription = (
  <p>
    Need help? Call us on <TelephoneContactNumberHyperlink /> or visit a{' '}
    <ServiceCentreHyperlink />.
  </p>
);

const getInPageAlertVariant = (severity: string | undefined) => {
  if (!severity) return 'error';

  switch (severity) {
    case 'SUCCESS':
      return 'success';
    case 'INFORMATION':
      return 'info';
    case 'WARNING':
      return 'warning';
    default:
      return 'error';
  }
};
