import React from 'react';
import { AccordionContentStyling } from 'components/AccordionStyling/AccordionStyling.styled';
import {
  ExternalTextLink,
  ServiceCentreHyperlink,
} from 'components/Hyperlinks';
import { Config } from 'config/env';
import {
  nswDriverLicensingAccessProgram,
  nswPrepareYourLicenceApplicationHyperlink,
  nswRoadUserHandbook,
  serviceNswBookADriverLicenceTest,
  snswContactTelephoneNumber,
} from 'consts/links';

export const contentRegionalOrRemoteArea = (
  <AccordionContentStyling>
    <p>
      If you live remotely or in a regional area, you may be able to access a
      mobile service centre or visit your local council agency.{' '}
      <ServiceCentreHyperlink label='Find out if there is one near you.' />
    </p>
  </AccordionContentStyling>
);

export const contentOverseasLicence = (
  <AccordionContentStyling>
    <p>
      If you&apos;re transferring an overseas licence to a NSW licence,{' '}
      <ExternalTextLink
        id='licenceApplicationLink'
        href={nswPrepareYourLicenceApplicationHyperlink}
      >
        find out what you need to bring to the service centre.
      </ExternalTextLink>{' '}
      You may also need to{' '}
      <ExternalTextLink
        id='drivingTestLink'
        href={serviceNswBookADriverLicenceTest}
      >
        book a physical driving test
      </ExternalTextLink>{' '}
      with a certified SNSW instructor. If you need assistance call{' '}
      {snswContactTelephoneNumber} or visit a service centre.
    </p>
  </AccordionContentStyling>
);

export const contentAccessLearningModules = (
  <AccordionContentStyling>
    <p>
      Yes, you can access your{' '}
      <ExternalTextLink id='learningModulesLink' href={Config.componoURL}>
        DKT learning modules
      </ExternalTextLink>{' '}
      for as long as your enrolment is still current.
    </p>
    <p>
      When your enrolment expires you&apos;ll receive a notification and
      won&apos;t have access anymore.
    </p>
    <p>
      If you need to refresh your knowledge, the{' '}
      <ExternalTextLink id='dktHandbookLink' href={nswRoadUserHandbook}>
        Driver Knowledge Handbook
      </ExternalTextLink>{' '}
      is available.
    </p>
  </AccordionContentStyling>
);

export const contentSupportGettingLicence = (
  <AccordionContentStyling>
    <p>
      The{' '}
      <ExternalTextLink
        id='dlAccessProgramLink'
        href={nswDriverLicensingAccessProgram}
      >
        Driver Licensing Access Program
      </ExternalTextLink>{' '}
      supports disadvantaged communities enter or re-enter the NSW driver
      licensing system. The program helps with the management of outstanding
      fines, literacy support, understanding road rules, preparation for
      licensing tests and driving experience.
    </p>
  </AccordionContentStyling>
);
