import React from 'react';
import {
  IconNotificationSuccess,
  IconChevronDown,
  IconChevronUp,
} from '@snsw-gel/react';
import * as Styled from './ProgressiveAccordion.styled';

const ProgressiveAccordion: React.FC<ProgressiveAccordionProps> = ({
  id,
  icon,
  title,
  isOpen,
  handleAccordionClick,
  isComplete = false,
  hasError = false,
  children,
}) => {
  return (
    <Styled.AccordionContainer
      id={`accordion-${id}`}
      $hasError={hasError}
      aria-invalid={hasError ? 'true' : 'false'}
      tabIndex={-1}
    >
      <Styled.HeadingWrapper>
        <Styled.AccordionButton
          aria-expanded={isOpen ? 'true' : 'false'}
          onClick={handleAccordionClick}
        >
          <div data-testid='accordionIcon'>
            {isComplete ? <IconNotificationSuccess size='lg' /> : icon}
          </div>
          <Styled.AccordionHeading>{title}</Styled.AccordionHeading>
          <Styled.ChevronContainer>
            {isOpen ? (
              <IconChevronUp data-testid='chevronUp' />
            ) : (
              <IconChevronDown data-testid='chevronDown' />
            )}
          </Styled.ChevronContainer>
        </Styled.AccordionButton>
      </Styled.HeadingWrapper>

      <Styled.ContentContainer $visible={isOpen}>
        <Styled.ChildrenWrapper>{children}</Styled.ChildrenWrapper>
      </Styled.ContentContainer>
    </Styled.AccordionContainer>
  );
};

interface ProgressiveAccordionProps {
  id: string;
  icon: JSX.Element;
  title: string;
  isOpen: boolean;
  handleAccordionClick: () => void;
  isComplete?: boolean;
  hasError?: boolean;
}

export default ProgressiveAccordion;
