import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DriveMessageDetail } from 'api/types/drives';
import { ConfirmationResponse } from 'api/types/response';

export interface ConfirmationState {
  data: {
    customerId: string;
    emailAddress: string;
    transactionRef: string;
    transactionDate: string;
    receiptPDFBase64: string;
  } | null;
  systemMessages: DriveMessageDetail[] | null;
}

export const initialState: ConfirmationState = {
  data: null,
  systemMessages: null,
};

export const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    setConfirmationData: (
      state,
      action: PayloadAction<ConfirmationResponse>
    ) => {
      const {
        customerId,
        emailAddress,
        transactionRef,
        transactionDate,
        receiptPDFBase64,
        systemMessages,
      } = action.payload;

      state.data = {
        customerId,
        emailAddress,
        transactionRef,
        transactionDate,
        receiptPDFBase64,
      };
      state.systemMessages = systemMessages;
    },
    setSystemMessage: (state, action: PayloadAction<DriveMessageDetail[]>) => {
      state.systemMessages = action.payload;
    },
  },
});

export const confirmationSliceActions = confirmationSlice.actions;
export const confirmationReducer = confirmationSlice.reducer;
