import { drupalPageLocation } from 'config/constants';
import { Config } from 'config/env';
import { snswHomePage } from 'consts/links';
import transactionCleanupEvents from 'utils/session/transactionCleanupEvents';

const redirectToDrupal = (): void => {
  transactionCleanupEvents();
  window.location.href = drupalPageLocation;
};

const redirectToMyAccount = (): void => {
  transactionCleanupEvents();
  window.location.href = Config.myAccountBaseUrl;
};

const redirectToSNSWHome = (): void => {
  transactionCleanupEvents();
  window.location.href = snswHomePage;
};

const handleQuitTransaction = {
  redirectToDrupal,
  redirectToMyAccount,
  redirectToSNSWHome,
};

export default handleQuitTransaction;
