import styled from 'styled-components';
import { SlidingModalWrapper } from 'components/MoreInfoModalStyling/MoreInfoModalStyling';

export const SpacingSlidingModalWrapper = styled(SlidingModalWrapper)`
  display: block;
  margin-top: 1rem;
  button {
    text-decoration: none;
  }
`;

export const MoreInfoModalLabel = styled.span`
  font-weight: normal;
`;
