import { sessionStorageKeyManager } from '@rmstransactions/components';

export const dvsFormSessionManager = sessionStorageKeyManager('isDvsFlow');

export const healthAndDrivingSessionManager = sessionStorageKeyManager(
  'formHealthAndDriving'
);

export const questionnaireSessionManager =
  sessionStorageKeyManager('formQuestionnaire');

export const clearSessionFormData = (): void => {
  dvsFormSessionManager.clear();
  healthAndDrivingSessionManager.clear();
  questionnaireSessionManager.clear();
};
