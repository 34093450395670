import React from 'react';
import { Heading } from '@snsw-gel/react';
import { ExternalTextLink } from 'components/Hyperlinks';
import { proofOfIdentityLink, proofOfIdentityLink2} from 'consts/links';

const ProofOfIdentity: React.FC = () => {

  return (
    <>
      <Heading level={4}>Proof of identity documents</Heading>
      <p>
        You will need to provide 2 current identity documents, one from{' '}
        <ExternalTextLink href={proofOfIdentityLink}>
          List 1
        </ExternalTextLink>{' '}
        and another from{' '}
        <ExternalTextLink href={proofOfIdentityLink2}>
          List 2
        </ExternalTextLink>
        .
      </p>
      
      <p>   
        <b>Note: Original paper documents that have been laminated are not accepted as proof of identity.</b>
      </p>

    </>
  );
};

export default ProofOfIdentity;
