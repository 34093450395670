import React from 'react';
import { Button } from '@snsw-gel/react';
import { FormNavigationButtonWrapper } from 'components/ButtonStyling/ButtonStyling.styled';
import {
  ServiceCentreHyperlink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';
import { serviceNswApplyForALearnerDriverLicence } from 'consts/links';
import handleQuitTransaction from 'utils/routes/quitTransaction';
import * as Styled from './CallToActions.styled';

const CallToActions: React.FC = () => {
  return (
    <section>
      <p>
        If you need assistance, call us on <TelephoneContactNumberHyperlink />{' '}
        or visit a <ServiceCentreHyperlink />.
      </p>
      <FormNavigationButtonWrapper>
        <Styled.ExternalButton
          variant='secondary'
          href={serviceNswApplyForALearnerDriverLicence}
          external
        >
          Apply for your licence
        </Styled.ExternalButton>
        <Button
          variant='tertiary'
          onClick={handleQuitTransaction.redirectToMyAccount}
        >
          Quit to MyServiceNSW Account
        </Button>
      </FormNavigationButtonWrapper>
    </section>
  );
};

export default CallToActions;
