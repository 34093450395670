import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ComponentLoader } from '@snsw-gel/react';
import { analyticsServiceV2 } from '@rmstransactions/components';
import { FormNavigationButtonWrapper } from 'components/ButtonStyling/ButtonStyling.styled';
import routes from 'config/routes';
import {
  errorDisqualifiedSuspendedRefusedFromDriving,
  errorEligibilityNSWResidentialAddress,
  errorEligibilityRequiredAge,
  errorFullLicenceExpired,
} from 'consts/errors/questionnaireHardStop';
import { useStoreDispatch, useStoreSelector } from 'store';
import { eligibilityFormSliceActions } from 'store/slice/eligibilityForm';
import {
  analyticsEnrolmentType,
  analyticsPushButtonEvent,
} from 'utils/session/pushGoogleAnalyticsPage';
import IncompleteModal from './IncompleteModal/IncompleteModal';
const { defaultAnalyticsNullValue } = analyticsServiceV2;

const SubmitAndValidate: React.FC = () => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showIncompleteModal, setShowIncompleteModal] =
    useState<boolean>(false);
  const questionnaireState = useStoreSelector((state) => state.questionnaire);
  const {
    nswResidentialAddress,
    requiredAge,
    hasNSWOrInterstateDriverLicence,
    overseasDriverLicence,
    disqualifiedSuspendedRefusedFromDriving,
    overdueFines,
    learnerLicenceExpired,
    fullLicenceExpired,
  } = questionnaireState;

  const checkMissingFields = () => {
    // Check if the NEXT field has not been answered yet
    // Default pathway
    if (nswResidentialAddress === 'yes' && requiredAge === null) {
      return true;
    }
    if (requiredAge === 'yes' && hasNSWOrInterstateDriverLicence === null) {
      return true;
    }
    if (
      hasNSWOrInterstateDriverLicence === 'no' &&
      overseasDriverLicence === null
    ) {
      return true;
    }
    if (
      overseasDriverLicence !== null &&
      disqualifiedSuspendedRefusedFromDriving === null
    ) {
      return true;
    }

    // Q3 subfields
    if (hasNSWOrInterstateDriverLicence === 'yes') {
      if (learnerLicenceExpired === null) {
        // Q3.1 not answered
        return true;
      }
      if (learnerLicenceExpired === 'no' && fullLicenceExpired === null) {
        // Q3.2 not answered
        return true;
      }
    }
    return false;
  };

  const validateFormStatus = () => {
    // HARD STOPS
    if (nswResidentialAddress === 'no') {
      return errorEligibilityNSWResidentialAddress;
    }
    if (requiredAge === 'no') {
      return errorEligibilityRequiredAge;
    }
    if (fullLicenceExpired === 'no') {
      return errorFullLicenceExpired;
    }
    if (disqualifiedSuspendedRefusedFromDriving === 'yes') {
      return errorDisqualifiedSuspendedRefusedFromDriving;
    }

    // FINISHED
    if (overdueFines !== null) {
      return true;
    }

    // DEFAULT: UNFINISHED
    return null;
  };
  const formValidationStatus = validateFormStatus();
  const hardStopError =
    formValidationStatus !== null && typeof formValidationStatus === 'object';

  const handleContinueButton = () => {
    setIsLoading(true);
    if (checkMissingFields() || formValidationStatus === null) {
      setShowIncompleteModal(true);
      setIsLoading(false);
      return;
    }

    // Google analytics stage 3
    const transaction_sub_type = analyticsEnrolmentType.getValue() as string;
    analyticsPushButtonEvent({
      ...saveAndContinueAnalyticsData,
      destination_url: 'check your eligibility',
      transaction_sub_type: transaction_sub_type ?? defaultAnalyticsNullValue,
    });

    if (formValidationStatus === true) {
      // Eligible, complete questionnaire
      dispatch(
        eligibilityFormSliceActions.runFormStepCompleted('questionnaire')
      );
      setIsLoading(false);
      return;
    }

    if (hardStopError) {
      // Ineligible: end transaction
      history.push({
        pathname: routes.errorEligibility.path,
        state: {
          systemMessage: formValidationStatus,
        },
      });
      return;
    }

    // If the sort returns something else, go to error
    history.push(routes.genericError.path);
  };

  const continueButtonVisible = hardStopError || formValidationStatus === true;

  return (
    <>
      {continueButtonVisible && (
        <FormNavigationButtonWrapper>
          <Button variant='secondary' onClick={handleContinueButton}>
            Save and continue
          </Button>
        </FormNavigationButtonWrapper>
      )}

      {showIncompleteModal && (
        <IncompleteModal onClose={() => setShowIncompleteModal(false)} />
      )}
      {isLoading && <ComponentLoader active={isLoading} fullPage />}
    </>
  );
};

export default SubmitAndValidate;

const saveAndContinueAnalyticsData = {
  component_type: 'button_secondary',
  component_text: 'save and continue',
  section_id: 'page_content',
  position_id: '1',
};
