import React from 'react';
import { BrandIconLocked } from '@snsw-gel/react';
import { ExternalTextLink } from 'components/Hyperlinks';
import { serviceNswPrivacyLink, transportNswPrivacyLink } from 'consts/links';
import * as Styled from './PrivacyStatement.styled';

const PrivacyStatement: React.FC = () => {
  return (
    <Styled.PrivacyWrapper>
      <BrandIconLocked size='lg' />
      <Styled.PrivacyDescription>
        Service NSW delivers services on behalf of Transport for NSW. Your
        personal information is disclosed and verified with Transport for NSW as
        part of this transaction. To learn how your personal information is
        handled visit the Privacy Statements from{' '}
        <ExternalTextLink id='link-snsw-privacy' href={serviceNswPrivacyLink}>
          Service NSW
        </ExternalTextLink>{' '}
        and{' '}
        <ExternalTextLink
          id='link-transport-privacy'
          href={transportNswPrivacyLink}
        >
          Transport for NSW
        </ExternalTextLink>
        .
      </Styled.PrivacyDescription>
    </Styled.PrivacyWrapper>
  );
};

export default PrivacyStatement;
