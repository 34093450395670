import React from 'react';
import { useStoreSelector } from 'store';
import QuestionnaireRadioTemplate from '../QuestionnaireRadioTemplate/QuestionnaireRadioTemplate';

/**
 * QUESTION 3
 *
 * VISIBLE => Q2 === YES\
 * YES => move to Q3.1\
 * NO => move to Q4
 *  */
const HasNSWOrInterstateDriverLicence: React.FC = () => {
  const requiredAge = useStoreSelector(
    (state) => state.questionnaire.requiredAge
  );
  const hasNSWOrInterstateDriverLicence = useStoreSelector(
    (state) => state.questionnaire.hasNSWOrInterstateDriverLicence
  );

  const isVisible =
    hasNSWOrInterstateDriverLicence !== null || requiredAge === 'yes';
  if (!isVisible) return <></>;

  return (
    <QuestionnaireRadioTemplate
      questionnaireKey='hasNSWOrInterstateDriverLicence'
      legendLabel='Do you hold or have you ever held a NSW or interstate driver licence (excluding a rider only licence)?'
    />
  );
};

export default HasNSWOrInterstateDriverLicence;
