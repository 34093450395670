import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DriveMessageDetail } from 'api/types/drives';
import { ProfileConnectData } from 'api/types/response';
import { termsAndConditionIdentifier } from 'consts/errors/inLineErrors';

export interface ReviewState {
  termsAndConditions: boolean;
  consentDKTContact: boolean;
  profileData: ProfileConnectData | null;
  errorNotification: null | DriveMessageDetail;
}

export const initialState: ReviewState = {
  termsAndConditions: false,
  consentDKTContact: false,
  profileData: null,
  errorNotification: null,
};

export const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    setTermsAndConditions: (state, action: PayloadAction<boolean>) => {
      state.termsAndConditions = action.payload;

      const currentErrorIsTermsAndConditions =
        state.errorNotification?.identifier === termsAndConditionIdentifier;
      if (action.payload && currentErrorIsTermsAndConditions) {
        state.errorNotification = null;
      }
    },
    setConsentDKTContact: (state, action: PayloadAction<boolean>) => {
      state.consentDKTContact = action.payload;
    },
    setErrorNotification: (
      state,
      action: PayloadAction<DriveMessageDetail | null>
    ) => {
      state.errorNotification = action.payload;
    },
    setProfileData: (state, action: PayloadAction<ProfileConnectData>) => {
      const {
        firstName,
        middleName,
        lastName,
        email,
        sex,
        product,
        productNumber,
        dateOfBirth,
        mobileNumber,
        residentialAddress,
      } = action.payload;

      state.profileData = {
        firstName,
        middleName,
        lastName,
        email,
        sex,
        product,
        productNumber,
        dateOfBirth,
        mobileNumber,
        residentialAddress,
      };
    },
  },
});

export const reviewSliceActions = reviewSlice.actions;
export default reviewSlice.reducer;
