import styled from 'styled-components';
import { Button, Box as KiamaBox, tokens } from '@snsw-gel/react';

export const Box = styled(KiamaBox)`
  display: grid;
  justify-items: start;
  align-items: start;
  gap: 1.25rem;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    grid-template-columns: 1fr auto;
  }
`;

const marginSpacingRows = '1.25rem';
export const DescriptionList = styled.dl`
  display: grid;
  margin: 0;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    grid-template-columns: auto 1fr;
    grid-column-gap: 1.375rem;
  }

  > dt {
    margin-bottom: 0.25rem;
    @media screen and (min-width: ${tokens.breakpoints.md}px) {
      margin-bottom: ${marginSpacingRows}
    }
  }

  > dd {
    margin-bottom: ${marginSpacingRows}
    font-weight: 500;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  > dt:last-of-type, dt:last-of-type {
    margin-bottom: 0;
  }
`;

export const NormalWeightButton = styled(Button)`
  font-weight: normal;
`;

export const IconWrapper = styled.span`
  display: flex;
  margin-left: 0.25rem;
`;
