import React from 'react';
import { Heading } from '@snsw-gel/react';
import { ExternalTextLink } from 'components/Hyperlinks';
import { nswLicencesFees } from 'consts/links';

const TestFee: React.FC = () => (
  <>
    <Heading level={4}>Test fee</Heading>
    <p>
      You will need to pay for the DKT online test fee and your licence
      application fee.
    </p>

    <p>
      For more information about these fees, visit{' '}
      <ExternalTextLink
        id='vistNswLicencesFeesLink'
        href={nswLicencesFees}
      >
        nsw.gov.au
      </ExternalTextLink>
      .
    </p>
  </>
);

export default TestFee;
