import React from 'react';
import * as Styled from '../PostFormInformation.styled';

const InfoReEnrolment = (): JSX.Element => (
  <section data-testid={reEnrolmentTestID}>
    <Styled.InformationWrapper>
      <p>
        You have completed the eligibility section and do not need to supply any
        further identification. Please review and submit your application.
      </p>
    </Styled.InformationWrapper>
  </section>
);

export default InfoReEnrolment;

export const reEnrolmentTestID = 'info-reEnrolment';
