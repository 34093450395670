import React, { useState } from 'react';
import { Button, Modal } from '@snsw-gel/react';
import handleQuitTransaction from 'utils/routes/quitTransaction';
import { analyticsPushButtonEvent } from 'utils/session/pushGoogleAnalyticsPage';
import routes from 'config/routes';

const QuitDVSButton: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleQuitModalButton = () => {
    setModalOpen(true);
    analyticsPushButtonEvent({
      component_type: 'button_quit',
      component_text: 'quit',
      section_id: 'page_content',
      position_id: '1',
      destination_url: 'modal_quit',
    });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleModalContinue = () => {
    handleCloseModal();
    analyticsPushButtonEvent({
      component_type: 'button_primary',
      component_text: 'continue enrolment',
      section_id: 'modal_quit',
      position_id: '1',
      destination_url: window.location.origin + routes.reviewAndSubmit.path,
    });
  };

  return (
    <>
      <Button onClick={handleQuitModalButton} variant='secondary'>
        Quit
      </Button>
      {modalOpen && (
        <Modal
          id='dvs-quit-modal'
          title='Are you sure you want to quit?'
          onClose={handleCloseModal}
          buttons={[
            {
              text: 'Continue',
              onClick: handleModalContinue,
            },
            {
              text: 'Quit',
              onClick: handleQuitTransaction.redirectToDrupal,
            },
          ]}
        >
          <p>If you select ‘Quit’ your information will not be saved.</p>
          <p>If you want to update your details select ‘edit’.</p>
          <p>If you wish to resume your enrolment, please select continue.</p>
        </Modal>
      )}
    </>
  );
};

export default QuitDVSButton;
