import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AnalyticsSessionManager,
  analyticsServiceV2,
  UserSession,
  UserToken,
} from '@rmstransactions/components';
import { getUserType } from 'api/api';
import routes from 'config/routes';
import { Config } from 'config/env';
import { transactionName } from 'config/constants';
import { enrolmentIdentificationType } from 'consts/transport';
import { useStoreDispatch } from 'store';
import { customerSliceActions } from 'store/slice/customer';
import {
  handleResponseData,
  handleResponseHeaders,
} from 'utils/api/httpClient';
import getHeaderValueByKey from 'utils/api/getHeaderValueByKey';
import transactionCleanupEvents from 'utils/session/transactionCleanupEvents';

const loginUrl = `${Config.apiHost}/login?state=${transactionName}`;
const userTokenKey = UserToken.getStorageKey();

const analyticsAuthUserLoginType =
  analyticsServiceV2.analyticsUserLoginMapedValues['AuthenticatedUnLinked'];

const UserAuth: React.FC<UserTypeProps> = ({ callbackFn }) => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const handleErrorRedirect = () => {
      history.push(routes.genericError.path);
    };

    const handleSetUserType = async () => {
      // get userToken auth + x-token
      const response = await getUserType();
      const headers = handleResponseHeaders(response);
      const data = handleResponseData(response);

      const userToken = getHeaderValueByKey(headers, userTokenKey);
      if (userToken === null || typeof userToken !== 'string' || !data) {
        handleErrorRedirect();
        return;
      }

      const userAccountType = data.type;

      if (userAccountType === 'AuthenticatedLinked') {
        dispatch(
          customerSliceActions.setEnrolmentType(
            enrolmentIdentificationType.linked
          )
        );
      } else if (userAccountType === 'AuthenticatedDKTLinked') {
        dispatch(customerSliceActions.setIsDKTLinked(true));
      }

      switch (userAccountType) {
        case 'AuthenticatedLinked':
        case 'AuthenticatedUnLinked':
        case 'AuthenticatedDKTLinked':
          UserToken.setValue(userToken);

          // setup Google analytics
          AnalyticsSessionManager.setSessionID(data.sessionId);
          if (data.myAccountId) {
            AnalyticsSessionManager.setUserID(data.myAccountId);
          }
          AnalyticsSessionManager.setUserLoginType(analyticsAuthUserLoginType);
          AnalyticsSessionManager.setTransactionStartTime();

          callbackFn();
          break;

        default:
          handleErrorRedirect();
      }
    };

    /**
     * START
     */
    if (
      urlSearchParams.has('isLoginRequired') === false ||
      !UserSession.isLoginUser()
    ) {
      // user is not logged in
      transactionCleanupEvents();
      UserSession.login(loginUrl);
      return;
    }

    // TODO: session work? need to check with UX
    // if (isLoggedIn) {
    //   UserSession.setActiveSession();
    // }

    handleSetUserType();
  }, [dispatch]);

  return null;
};

export default UserAuth;

interface UserTypeProps {
  callbackFn: () => void;
}
