import React from 'react';
import BaseRoute from 'components/BaseRoute/BaseRoute';
import routes from 'config/routes';
import EligibleErrorPage from './EligibleErrorPage';
import BusinessErrorPage from './BusinessErrorPage';
import SystemErrorPage from './SystemErrorPage';
import HasLicenceDktExpiredErrorPage from './HasLicenceDktExpiredErrorPage';
import GenericErrorPage from './GenericErrorPage';
import NSWAddressErrorPage from './NSWAddressErrorPage.tsx';
import InterStateAddressErrorPage from './InterStateAddressErrorPage';

const businessErrorRoute = (
  <BaseRoute
    path={routes.errorBusiness.path}
    pageStep={routes.errorBusiness.pageStep}
    key={routes.errorBusiness.path}
  >
    <BusinessErrorPage />
  </BaseRoute>
);

const eligibleErrorRoute = (
  <BaseRoute
    path={routes.errorEligibility.path}
    pageStep={routes.errorEligibility.pageStep}
    key={routes.errorEligibility.path}
  >
    <EligibleErrorPage />
  </BaseRoute>
);

const systemErrorRoute = (
  <BaseRoute
    path={routes.errorSystem.path}
    pageStep={routes.errorSystem.pageStep}
    key={routes.errorSystem.path}
  >
    <SystemErrorPage />
  </BaseRoute>
);

const hasLicenceDktExpiredErrorRoute = (
  <BaseRoute
    path={routes.errorHasLicenceDktExpired.path}
    pageStep={routes.errorHasLicenceDktExpired.pageStep}
    pageSubType={routes.errorHasLicenceDktExpired.pageSubType}
    key={routes.errorHasLicenceDktExpired.path}
  >
    <HasLicenceDktExpiredErrorPage />
  </BaseRoute>
);

const nswAddressErrorRoute = (
  <BaseRoute
    path={routes.errorInNSWAddress.path}
    pageStep={routes.errorInNSWAddress.pageStep}
    key={routes.errorInNSWAddress.path}
  >
    <NSWAddressErrorPage />
  </BaseRoute>
);

const interStateAddressErrorRoute = (
  <BaseRoute
    path={routes.errorInInterStateAddress.path}
    pageStep={routes.errorInInterStateAddress.pageStep}
    key={routes.errorInInterStateAddress.path}
  >
    <InterStateAddressErrorPage />
  </BaseRoute>
);

const genericErrorRoute = (
  <BaseRoute
    path={routes.genericError.path}
    pageStep={routes.genericError.pageStep}
    key={routes.genericError.path}
  >
    <GenericErrorPage />
  </BaseRoute>
);

const ErrorPages = [
  businessErrorRoute,
  eligibleErrorRoute,
  systemErrorRoute,
  hasLicenceDktExpiredErrorRoute,
  nswAddressErrorRoute,
  interStateAddressErrorRoute,
  genericErrorRoute,
];

export default ErrorPages;
