import React from 'react';
import { useStoreSelector } from 'store';
import QuestionnaireRadioTemplate from '../QuestionnaireRadioTemplate/QuestionnaireRadioTemplate';

/**
 * QUESTION 3.1
 *
 * VISIBLE => Q3 === YES\
 * YES => move to Q4\
 * NO => move to Q3.2
 *  */
const LearnerLicenceExpiredOrExpire: React.FC = () => {
  const hasNSWOrInterstateDriverLicence = useStoreSelector(
    (state) => state.questionnaire.hasNSWOrInterstateDriverLicence
  );
  const learnerLicenceExpired = useStoreSelector(
    (state) => state.questionnaire.learnerLicenceExpired
  );

  const isVisible =
    learnerLicenceExpired !== null || hasNSWOrInterstateDriverLicence === 'yes';
  if (!isVisible) return <></>;

  return (
    <QuestionnaireRadioTemplate
      questionnaireKey='learnerLicenceExpired'
      legendLabel='Is this a learner licence that has expired or will expire in the next 6 months?'
    />
  );
};

export default LearnerLicenceExpiredOrExpire;
