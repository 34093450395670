import React, { useEffect, useRef } from 'react';

const NotificationWrapper: React.FC<NotificationWrapperProps> = ({
  testID,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current?.focus();
      ref.current?.scrollIntoView();
    }
  }, []);

  return (
    <div ref={ref} tabIndex={-1} data-testid={testID}>
      {children}
    </div>
  );
};

export default NotificationWrapper;

interface NotificationWrapperProps {
  testID: string;
}
