import React from 'react';
import { FormGroup, Input } from '@snsw-gel/react';
import { useStoreDispatch, useStoreSelector } from 'store';
import {
  tfNSWCustomerSliceActions,
  validationInvalidValue,
  validationRequiredValue,
} from 'store/slice/tfNSWCustomer';
import type {
  TFNSWCardData,
  ValidationFormInvalid,
} from 'store/slice/tfNSWCustomer';

const InputTemplate: React.FC<InputTemplateProps> = ({
  dataKey,
  label,
  requiredErrorMessage,
  helpMessage = '',
  isSpaceAllowed = false,
  isRequired = false,
}) => {
  const dispatch = useStoreDispatch();
  const inputValue = useStoreSelector(
    (state) => state.tfNSWCustomer.data[dataKey]
  );
  const inputValidation = useStoreSelector(
    (state) => state.tfNSWCustomer.validation[dataKey]
  );

  const updateCustomerData = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      tfNSWCustomerSliceActions.setCardData({
        key: dataKey,
        value: event.target.value,
      })
    );
  };

  const invalidValueSpaceAllowedErrorMessage = 'Special characters, symbols and full stops are not accepted. Check your details and try again.';
  const invalidValueSpaceNotAllowedErrorMessage = 'Special characters, spaces or full stops are not accepted. Check your details and try again.';

  const getErrorMessages = (error: ValidationFormInvalid) => {
    switch (error) {
      case validationInvalidValue:
        return isSpaceAllowed ? invalidValueSpaceAllowedErrorMessage : invalidValueSpaceNotAllowedErrorMessage;
      case validationRequiredValue:
        return requiredErrorMessage;
      default:
        return '';
    }
  };

  return (
    <FormGroup
      id={`${subFormIdPrefix}${dataKey}`}
      label={label}
      helpMessage={helpMessage}
      hasError={Boolean(inputValidation)}
      errorMessage={getErrorMessages(inputValidation)}
    >
      <Input
        required={isRequired}
        value={inputValue ?? ''}
        onChange={updateCustomerData}
      />
    </FormGroup>
  );
};

export default InputTemplate;

interface InputTemplateProps {
  dataKey: keyof TFNSWCardData;
  label: string;
  requiredErrorMessage: string;
  helpMessage?: string;
  isSpaceAllowed?: boolean;
  isRequired?: boolean;
}

export const subFormIdPrefix = 'tfnsw-';
