import React from 'react';
import {
  ServiceCentreHyperlink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';
import TemplateErrorPage from './TemplateErrorPage/TemplateErrorPage';

const GenericErrorPage: React.FC = () => {
  return (
    <TemplateErrorPage
      pageTitle="We're sorry about this"
      defaultAlertTitle='Our system is temporarily unavailable'
      analyticsErrorType='generic error'
      contactDescription={contactDescription}
    />
  );
};

export default GenericErrorPage;

const contactDescription = (
  <p>
    We are trying to resolve this issue, please try again later. If you receive
    another error, call us on <TelephoneContactNumberHyperlink /> or visit a{' '}
    <ServiceCentreHyperlink />. We apologise for any inconvenience.
  </p>
);
