import React, { useEffect, useRef } from 'react';
import { InPageAlert } from '@snsw-gel/react';
import { useStoreSelector } from 'store';
import {
  TFNSWCardValidation,
  validationInvalidValue,
  validationRequiredValue,
  validationInvalidAge,
} from 'store/slice/tfNSWCustomer';
import InvalidMessage from './Messages/InvalidMessage';
import RequiredMessage from './Messages/RequiredMessage';
import * as Styled from './ExistingCustomerFormInPageAlert.styled';

const ExistingCustomerFormAlert: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const validation = useStoreSelector(
    (state) => state.tfNSWCustomer.inPageAlertValidation
  );
  const validationCount = Object.keys(validation).length;

  useEffect(() => {
    // move window to show error
    if (ref.current && validationCount > 0) {
      ref.current.scrollIntoView();
    }
  }, [validation, validationCount]);

  return (
    <div ref={ref}>
      {validationCount !== 0 && (
        <InPageAlert variant='error' title='Your page has errors'>
          <p>
            Please check the following {validationCount} item
            {validationCount > 1 && 's'} to continue:
          </p>
          <Styled.CompactUnorderedList>
            {validationOrder.map((key) => {
              switch (validation[key]) {
                case validationInvalidValue:
                  return <InvalidMessage fieldKey={key} key={key} />;
                case validationRequiredValue:
                  return <RequiredMessage fieldKey={key} key={key} />;
                case validationInvalidAge:
                  return <InvalidMessage fieldKey={key} key={key} />;
                default:
                  return <React.Fragment key={key} />;
              }
            })}
          </Styled.CompactUnorderedList>
        </InPageAlert>
      )}
    </div>
  );
};

export default ExistingCustomerFormAlert;

const validationOrder: Array<keyof TFNSWCardValidation> = [
  'firstName',
  'middleName',
  'lastName',
  'dateOfBirth',
  'tfNSWCardNumber',
];
