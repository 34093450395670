import React from 'react';
import { MoreInfoModal } from '@snsw-gel/react';
import { SlidingModalWrapper } from 'components/MoreInfoModalStyling/MoreInfoModalStyling';
import ProofOfIdentity from './ProofOfIdentity/ProofOfIdentity';
import YourSignature from './YourSignature/YourSignature';
import TestFee from './TestFee/TestFee';
import MedicalAssessments from './MedicalAssessments/MedicalAssessments';
import EyeTests from './EyeTests/EyeTests';

const DocumentInformationModal: React.FC = () => {
  return (
    <SlidingModalWrapper>
      <MoreInfoModal
        id='required-documents'
        linkText='everything you need'
        title='What you need for your licence application'
        helpText={
          <>
            <ProofOfIdentity />
            <YourSignature />
            <TestFee />
            <MedicalAssessments />
            <EyeTests />
          </>
        }
        questionHyperLink
      />
    </SlidingModalWrapper>
  );
};

export default DocumentInformationModal;
