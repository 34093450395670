import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Button, ComponentLoader, IconChevronLeft } from '@snsw-gel/react';
import { getPoiRedirectUrl } from 'api/api';
import { FormNavigationButtonWrapper } from 'components/ButtonStyling/ButtonStyling.styled';
import routes from 'config/routes';
import { useStoreDispatch, useStoreSelector } from 'store';
import {
  EligibilityAccordionID,
  EligibilityValidationState,
  eligibilityFormSliceActions,
  initialCompleteStatus,
} from 'store/slice/eligibilityForm';
import { healthAndDrivingConditionsActions } from 'store/slice/healthAndDrivingConditions';
import { questionnaireSliceActions } from 'store/slice/questionnaire';
import { handleResponseData } from 'utils/api/httpClient';
import isReEnrolmentOrLinkedCustomer from 'utils/helpers/isReEnrolmentOrLinkedCustomer';
import { dvsFormSessionManager } from 'utils/session/storeSessionManagers';

const CallToActions: React.FC = () => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const completeStatus = useStoreSelector(
    (state) => state.eligibilityForm.completeStatus
  );
  const tfNSWCardType = useStoreSelector(
    (state) => state.tfNSWCustomer.tfNSWCardType
  );
  const enrolmentIdentification = useStoreSelector(
    (state) => state.customer.enrolmentIdentificationType
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const doesNotNeedExistingCustomer = isReEnrolmentOrLinkedCustomer(
    enrolmentIdentification
  );

  const redirectToLandingPage = () => {
    history.push(routes.landingPage.path);
  };

  const handlePoiRedirect = async () => {
    setIsLoading(true);
    const response = await getPoiRedirectUrl(true);
    const poiRedirectUrl = handleResponseData(response);

    if (poiRedirectUrl === null || typeof poiRedirectUrl !== 'string') {
      history.push(routes.genericError.path);
      return;
    }

    preRedirectActions();

    window.location.href = poiRedirectUrl;
  };

  const preRedirectActions = () => {
    dispatch(questionnaireSliceActions.saveToSessionData());
    dispatch(healthAndDrivingConditionsActions.saveToSessionData());
    dvsFormSessionManager.setValue('true');
  };

  const validateFormAndContinue = () => {
    let eligibilityFormIsIncomplete = false;
    let eligibilityFormKeys = Object.keys(
      initialCompleteStatus
    ) as EligibilityAccordionID[];

    // if customer is linked, don't need "existing customer" form checked
    if (doesNotNeedExistingCustomer) {
      eligibilityFormKeys = eligibilityFormKeys.filter(
        (id) => id !== 'existingCustomer'
      );
    }

    const updatedErrorState = eligibilityFormKeys.reduce(
      (updateState, eligibilitySection) => {
        const sectionCompleted = completeStatus[eligibilitySection];
        if (!sectionCompleted) {
          eligibilityFormIsIncomplete = true;
        }
        return {
          ...updateState,
          [eligibilitySection]: !sectionCompleted,
        };
      },
      {} as EligibilityValidationState
    );
    dispatch(eligibilityFormSliceActions.setErrorStatus(updatedErrorState));

    if (eligibilityFormIsIncomplete) return;

    const userHasEnteredCardData =
      tfNSWCardType !== null && tfNSWCardType !== 'n/a';
    if (doesNotNeedExistingCustomer || userHasEnteredCardData) {
      /**
       * re-enrolment/linked: go to step 4
       * OR
       * existing customer: entered one card detail into "existing customer" form
       */
      history.push(routes.reviewAndSubmit.path);
      return;
    }

    // dvs: request PoI url
    handlePoiRedirect();
  };

  return (
    <>
      <FormNavigationButtonWrapper>
        <Button variant='primary' onClick={validateFormAndContinue}>
          Next step
        </Button>
        <Button
          variant='link'
          iconStart={<IconChevronLeft />}
          onClick={redirectToLandingPage}
        >
          Back
        </Button>
      </FormNavigationButtonWrapper>
      {isLoading && (
        <ComponentLoader
          label='Processing, please wait. Do not refresh or navigate away.'
          fullPage
        />
      )}
    </>
  );
};

export default CallToActions;
