import React from 'react';
import { FormLayout } from 'components/Layouts';
import { pageHeadingLabel } from 'config/page';
import { useStoreSelector } from 'store';
import { GetDKTStepProgress } from 'utils/routes/StepProgress';
import isReEnrolmentOrLinkedCustomer from 'utils/helpers/isReEnrolmentOrLinkedCustomer';
import AccordionController from './AccordionController/AccordionController';
import CallToActions from './CallToActions/CallToActions';
import EligibilityFormInPageAlert from './EligibilityFormInPageAlert/EligibilityFormInPageAlert';
import FormPreface from './FormPreface/FormPreface';
import PostFormInformation from './PostFormInformation/PostFormInformation';
import HandleCustomerData from './HandleCustomerData/HandleCustomerData';
import RestoreDataDVSFlow from './RestoreDataDVSFlow/RestoreDataDVSFlow';

const stepList = GetDKTStepProgress('Check eligibility');

const CheckEligibility: React.FC = () => {
  const identificationType = useStoreSelector(
    (state) => state.customer.enrolmentIdentificationType
  );
  const hideStepList = isReEnrolmentOrLinkedCustomer(identificationType);

  return (
    <>
      <FormLayout
        title='Check your eligibility'
        label={pageHeadingLabel}
        stepsList={hideStepList ? null : stepList}
      >
        <EligibilityFormInPageAlert />
        <FormPreface />
        <AccordionController />
        <PostFormInformation />
        <CallToActions />
      </FormLayout>
      <HandleCustomerData />
      <RestoreDataDVSFlow />
    </>
  );
};

export default CheckEligibility;
