import React from 'react';
import ProgressiveAccordion from 'components/ProgressiveAccordion/ProgressiveAccordion';
import { useStoreDispatch, useStoreSelector } from 'store';
import {
  EligibilityAccordionID,
  eligibilityFormSliceActions,
} from 'store/slice/eligibilityForm';

const AccordionItemWrapper: React.FC<AccordionItemWrapperProps> = ({
  eligibilityFormID,
  title,
  Icon,
  children,
}) => {
  const dispatch = useStoreDispatch();
  const activeAccordion = useStoreSelector(
    (state) => state.eligibilityForm.activeAccordion
  );
  const completeStatus = useStoreSelector(
    (state) => state.eligibilityForm.completeStatus[eligibilityFormID]
  );
  const errorStatus = useStoreSelector(
    (state) => state.eligibilityForm.errorStatus[eligibilityFormID]
  );

  const handleClickAccordion = () => {
    dispatch(eligibilityFormSliceActions.setActiveAccordion(eligibilityFormID));
  };

  return (
    <ProgressiveAccordion
      id={eligibilityFormID}
      icon={<Icon size='lg' />}
      title={title}
      handleAccordionClick={handleClickAccordion}
      isOpen={activeAccordion === eligibilityFormID}
      isComplete={completeStatus}
      hasError={errorStatus}
    >
      {children}
    </ProgressiveAccordion>
  );
};

export default AccordionItemWrapper;

interface AccordionItemWrapperProps {
  eligibilityFormID: EligibilityAccordionID;
  title: string;
  Icon: React.FC<Partial<{ size: string }>>;
}
