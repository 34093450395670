import React from 'react';
import { BrandIconHealth } from '@snsw-gel/react';
import {
  ExternalTextLink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';
import { nswMedicalConditionsAndFitnessToDrive } from 'consts/links';
import { useStoreSelector } from 'store';
import * as Styled from './InformativeCards.styled';

const CheckYourFitnessToDrive: React.FC = () => {
  const seriousLongTerm = useStoreSelector(
    (state) => state.healthAndDrivingConditions.seriousLongTerm
  );
  if (seriousLongTerm !== 'yes') return <></>;

  return (
    <Styled.Box>
      <Styled.BoxIconWrapper>
        <BrandIconHealth size='lg' />
      </Styled.BoxIconWrapper>
      <Styled.BoxHeading>Check your fitness to drive</Styled.BoxHeading>
      <Styled.BoxDescription>
        <p>
          We recommend you discuss if you need a medical assessment with your
          doctor or specialist before you apply for licence.
        </p>
        <p>
          To find out more visit{' '}
          <ExternalTextLink href={nswMedicalConditionsAndFitnessToDrive}>
            nsw.gov.au
          </ExternalTextLink>{' '}
          or call us on <TelephoneContactNumberHyperlink />.
        </p>
      </Styled.BoxDescription>
    </Styled.Box>
  );
};

export default CheckYourFitnessToDrive;
