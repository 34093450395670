import React from 'react';
import { TFNSWCardType } from 'api/types/drives';
import LicenceRadioLabel from 'components/RadioGroup/LicenceRadioLabel';
import * as Styled from 'components/RadioGroup/LicenceRadioGroup.styled';
import { tfNSWCardTypeLabels, tfNSWCardTypes } from 'consts/transport';
import { useStoreDispatch, useStoreSelector } from 'store';
import { tfNSWCustomerSliceActions } from 'store/slice/tfNSWCustomer';
import DetailsSubForm from './DetailsSubForm/DetailsSubForm';
import FormActions from './FormActions/FormActions';

const ExistingCustomerForm: React.FC<ExistingCustomerFormProps> = ({
  isExistingCustomer = false,
}) => {
  const dispatch = useStoreDispatch();
  const tfNSWCardType = useStoreSelector(
    (state) => state.tfNSWCustomer.tfNSWCardType
  );

  const onChangeEvent = (cardTypeValue: LicenceFormValue) => {
    dispatch(tfNSWCustomerSliceActions.setCardType(cardTypeValue));
  };

  const cardOptions = isExistingCustomer
    ? radioFormOptions
    : radioFormOptionsWithNone;

  return (
    <>
      <Styled.LicenceFormRadioGroup
        id={fieldSetID}
        name={`${fieldSetID}-group`}
        legend='Select and enter details:'
        vertical={true}
        options={cardOptions}
        onChange={onChangeEvent}
        value={tfNSWCardType}
      />
      <FormActions isExistingCustomer={isExistingCustomer} />
    </>
  );
};

export default ExistingCustomerForm;

interface ExistingCustomerFormProps {
  isExistingCustomer?: boolean;
}

const fieldSetID = 'tfnsw-licence';
const transportNSWOptions = [
  {
    value: tfNSWCardTypes.nswPhotoCard,
    label: tfNSWCardTypeLabels[tfNSWCardTypes.nswPhotoCard],
  },
  {
    value: tfNSWCardTypes.drivesLicenceNumber,
    label: (
      <LicenceRadioLabel
        indexID={`${fieldSetID}-2`}
        labelText={tfNSWCardTypeLabels[tfNSWCardTypes.drivesLicenceNumber]}
        helperText='(including rider only or learner licence)'
      />
    ),
  },
  {
    value: tfNSWCardTypes.nswPlateNumber,
    label: tfNSWCardTypeLabels[tfNSWCardTypes.nswPlateNumber],
  },
  {
    value: tfNSWCardTypes.mobilityParkingScheme,
    label: tfNSWCardTypeLabels[tfNSWCardTypes.mobilityParkingScheme],
  },
  {
    value: tfNSWCardTypes.drivesCustomerNumber,
    label: (
      <LicenceRadioLabel
        indexID={`${fieldSetID}-5`}
        labelText={tfNSWCardTypeLabels[tfNSWCardTypes.drivesCustomerNumber]}
        helperText='Previously created by Service NSW'
      />
    ),
  },
];
const noTransportOption = { value: 'n/a', label: 'None of the above' };
const radioFormOptions = [
  ...transportNSWOptions.map((option) => ({
    ...option,
    editor: <DetailsSubForm formID={`form-${option.value}`} />,
  })),
];
const radioFormOptionsWithNone = [...radioFormOptions, noTransportOption];

type LicenceFormValue = TFNSWCardType | 'n/a';
