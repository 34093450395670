import { validation } from '../Helpers/validation';

const validatePlateNumber = (
  plateNumber: string
): PlateNumberValidationErrors | null => {
  if (plateNumber === '') {
    return 'PLATE_NUMBER_EMPTY';
  }
  if (validation.invalidPlateNumberCharacters(plateNumber)) {
    return 'SPECIAL_CHARACTER';
  }
  if (validation.invalidPlateNumberMaxLength(plateNumber)) {
    return 'MAX_LENGTH_REACHED';
  }
  return null;
};

export default validatePlateNumber;

export const plateNumberValidationData: ValidationErrorsData = {
  PLATE_NUMBER_EMPTY: 'Please enter a valid plate number, for example ABC123',
  SPECIAL_CHARACTER:
    'The plate number entered contains special characters, please enter only numbers and letters',
  MAX_LENGTH_REACHED:
    'The plate number entered contains too many characters, please enter 6 characters or less',
};

export type PlateNumberValidationErrors =
  | 'PLATE_NUMBER_EMPTY'
  | 'SPECIAL_CHARACTER'
  | 'MAX_LENGTH_REACHED';

export type ValidationErrorsData = {
  [key in PlateNumberValidationErrors]: string;
};
