import styled from 'styled-components';
import {
  FormRadioGroup as KiamaFormRadioGroup,
  tokens,
} from '@snsw/react-component-library';

// CSS :has() selector does not work in Firefox yet, work around
export const LicenceFormRadioGroup = styled(KiamaFormRadioGroup)`
  margin: 2rem 0;

  > div > div {
    margin: 0;
    margin-top: 1rem;
    border-left: none;
    padding: 0;
  }
`;

// to match existing Kiama spacing
export const LicenceRadioLabelWrapper = styled.div`
  margin-bottom: -0.25rem;
`;

export const LicenceRadioLabelHelperText = styled.div`
  color: ${tokens.colors.grey.shuttle};
  font-size: 0.875rem;
`;
