import React from 'react';
import { PageLayout } from 'components/Layouts';
import ApplyLearnerLicence from './ApplyLearnerLicence/ApplyLearnerLicence';
import ImportantInformation from './ImportantInformation/ImportantInformation';
import CallToActions from './CallToActions/CallToActions';

const PassedPage: React.FC = () => {
  return (
    <PageLayout title='You’ve passed DKT online' success>
      <ApplyLearnerLicence />
      <ImportantInformation />
      <CallToActions />
    </PageLayout>
  );
};

export default PassedPage;
