import React from 'react';
import { snswFindServiceCentre } from 'consts/links';

const ServiceCentre: React.FC<ServiceCentreHyperlinkProps> = ({
  label = 'service centre',
}) => (
  <a href={snswFindServiceCentre} target='_blank' rel='noopener noreferrer'>
    {label}
  </a>
);

export default ServiceCentre;

interface ServiceCentreHyperlinkProps {
  label?: string;
}
