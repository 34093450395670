import { matchPath } from 'react-router-dom';
import {
  analyticsServiceV2,
  AnalyticsSessionManager,
  sessionStorageKeyManager,
} from '@rmstransactions/components';
import { EnrolmentIdentificationType } from 'api/types/drives';
import routes from 'config/routes';
const { defaultAnalyticsNullValue } = analyticsServiceV2;

export const analyticsEnrolmentTypeValues: Record<
  EnrolmentIdentificationType,
  string
> = {
  DVS: 'DVS',
  DRIVES_PRODUCT: 'Existing user',
  LINKED: 'Linked user',
  RE_ENROLMENT: 'Re enrolment',
};
export const analyticsEnrolmentType = sessionStorageKeyManager(
  'analyticsEnrolmentType'
);
const transactionName = 'Driver Knowledge Test Online';
const transactionType = 'enrolment';

const { getSessionID, getUserLoginType, getTransactionStartTime } =
  AnalyticsSessionManager;

const pushGoogleAnalyticsPage = (pageStep: string): void => {
  const hasRequiredSessionFields =
    getSessionID() && getUserLoginType() && getTransactionStartTime();
  if (!hasRequiredSessionFields) return;

  const transactionInfo = {
    transaction_name: transactionName,
    transaction_type: transactionType,
    transaction_step: pageStep,
    transaction_sub_type:
      analyticsEnrolmentType.getValue() ?? defaultAnalyticsNullValue,
  };
  const overridePageLocation = getOverridePageLocation();

  analyticsServiceV2.pushNewPageVisited({
    pageName: document?.title,
    transactionInfo: transactionInfo,
    overridePageLocation,
  });
};

export default pushGoogleAnalyticsPage;

/**
 *  we don't want to send the "receiptUUID" value to Google Analytics
 *  therefore we will replace it manually
 */
export const getOverridePageLocation = (): string | undefined => {
  const { pathname } = window.location;

  const isViewReceiptURL = matchPath(pathname, {
    path: routes.confirmationSupport.path,
  });
  if (isViewReceiptURL) {
    const adjustedPathname = routes.confirmationSupport.path.replace(
      ':receiptUUID',
      'uuid'
    );
    return adjustedPathname;
  }
};

export const updateTransactionSubType = (transactionType: string): void => {
  window.dataLayer?.push({
    transaction_info: {
      transaction_sub_type: transactionType,
    },
  });
};

interface AnalyticsPushButtonEventProps {
  component_type: string;
  component_text: string;
  section_id: string;
  position_id: string;
  destination_url?: string;
  transaction_sub_type?: string;
}

export const analyticsPushButtonEvent = ({
  component_type,
  component_text,
  section_id,
  position_id,
  destination_url = defaultAnalyticsNullValue,
  ...optionalArgs
}: AnalyticsPushButtonEventProps): void => {
  window.dataLayer?.push({
    event: 'component_interaction',
    component_type,
    component_text,
    section_id,
    position_id,
    destination_url,
    ...optionalArgs,
  });
};

interface AnalyticsPushErrorPageProps {
  error_type: string;
  error_name: string;
  error_text: string;
  error_position: string;
}

export const analyticsPushErrorPage = ({
  error_type,
  error_name,
  error_text,
  error_position,
}: AnalyticsPushErrorPageProps): void => {
  window.dataLayer?.push({
    event: 'notice_alert',
    error_type,
    error_name,
    error_text,
    error_position,
  });
};
