import React from 'react';
import QuestionnaireRadioTemplate from '../QuestionnaireRadioTemplate/QuestionnaireRadioTemplate';

/**
 * QUESTION 1: START
 *
 * VISIBLE => Always, starting question\
 * YES => move to Q2\
 * NO => END: ineligible
 *  */
const NSWResidentialAddress: React.FC = () => {
  return (
    <QuestionnaireRadioTemplate
      questionnaireKey='nswResidentialAddress'
      legendLabel='Do you have a NSW residential address?'
    />
  );
};

export default NSWResidentialAddress;
