import React from 'react';
import { HorizontalRule } from 'components/HorizontalRule/HorizontalRule.styled';
import { enrolmentIdentificationType } from 'consts/transport';
import { useStoreSelector } from 'store';
import isReEnrolmentOrLinkedCustomer from 'utils/helpers/isReEnrolmentOrLinkedCustomer';
import ProfileConnectDetails from './ProfileConnectDetails/ProfileConnectDetails';
import ExistingCustomerDetails from './ExistingCustomerDetails/ExistingCustomerDetails';
import DetailsSkeleton from './DetailsSkeleton';

const DetailsBox: React.FC = () => {
  const identificationType = useStoreSelector(
    (state) => state.customer.enrolmentIdentificationType
  );

  if (isReEnrolmentOrLinkedCustomer(identificationType))
    return <HorizontalRule data-testid='customer-details-hr' />;

  const isExistingCustomer =
    identificationType === enrolmentIdentificationType.drivesProduct;
  if (isExistingCustomer) return <ExistingCustomerDetails />;

  const fromProfileConnect =
    identificationType === enrolmentIdentificationType.dvs;
  if (fromProfileConnect) return <ProfileConnectDetails />;

  return <DetailsSkeleton />;
};

export default DetailsBox;
