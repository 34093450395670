import React from 'react';
import { Box, Heading } from '@snsw-gel/react';
import { ReducedLeftPaddingUl } from 'components/ListStyling/ListStyling.styled';

const EnrolInformationBox: React.FC = () => (
  <section>
    <Box>
      <Heading level={5} headingElement='h2'>
        When you enrol in DKT online you:
      </Heading>
      <ReducedLeftPaddingUl>
        <li>have 12 months to complete the course and pass the test</li>
        <li>
          can take as many attempts to pass the online test as you need for the
          one fee (you only pay the test fee when you apply for your licence)
        </li>
        <li>
          will receive notifications to keep you up to date about your enrolment
          status and next steps.
        </li>
      </ReducedLeftPaddingUl>
    </Box>
  </section>
);

export default EnrolInformationBox;
