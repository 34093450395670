import React from 'react';
import { Modal } from '@snsw-gel/react';
import handleQuitTransaction from 'utils/routes/quitTransaction';

const IncompleteModal: React.FC<IncompleteModalProps> = ({ onClose }) => {
  return (
    <Modal
      id={incompleteModalID}
      title='Select Yes or No'
      onClose={onClose}
      buttons={[
        { text: 'Continue', onClick: onClose },
        { text: 'Quit', onClick: handleQuitTransaction.redirectToDrupal },
      ]}
    >
      <p>Please select an answer to the question so you can proceed.</p>
    </Modal>
  );
};

export default IncompleteModal;

interface IncompleteModalProps {
  onClose: () => void;
}

export const incompleteModalID = 'incomplete-questionnaire-modal';
