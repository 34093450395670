import { driverKnowledgeTestSteps } from 'config/formSteps';
import type { StepContent, FormStep, StepStatus } from 'config/formSteps';

export const GetStepProgress = (
  currentStep: string,
  steps: FormStep[]
): FormStep[] => {
  const contentSteps = steps.map((step) => step.content);
  const currentStepIndex = contentSteps.findIndex(
    (content) => content === currentStep
  );

  // error not exist
  if (currentStepIndex < 0) return steps;

  return steps.map(({ content }, idx) => {
    let updatedStatus: StepStatus = 'todo';
    if (idx === currentStepIndex) {
      updatedStatus = 'current';
    } else if (idx < currentStepIndex) {
      updatedStatus = 'completed';
    }

    return {
      content,
      status: updatedStatus,
    };
  });
};

export const GetDKTStepProgress = (currentStep: StepContent): FormStep[] =>
  GetStepProgress(currentStep, driverKnowledgeTestSteps);

export const GetDKTCompletedProgress = (): FormStep[] => {
  return driverKnowledgeTestSteps.map((step) => ({
    ...step,
    status: 'completed',
  }));
};
