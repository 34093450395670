import React from 'react';
import { Heading } from '@snsw-gel/react';
import { ServiceCentreHyperlink } from 'components/Hyperlinks';

const EyeTests: React.FC = () => (
  <>
    <Heading level={4}>Eye tests</Heading>
    <p>
      Everyone is required to pass an eyesight test when they apply for their
      learner licence - or an upgrade or transfer. The eyesight test is done
      onsite at a <ServiceCentreHyperlink /> and staff will ask you to read
      aloud from a chart of letters.
    </p>
    <p>
      If you declare a vision or eye condition for the first time, you need to
      provide an eyesight report completed by an optometrist or ophthalmologist.
    </p>
  </>
);

export default EyeTests;
