import React from 'react';
import {
  BrandIconAddLicence,
  BrandIconHealth,
  BrandIconPersonalDataSecurity,
} from '@snsw-gel/react';
import { accordionWrapperId } from 'consts/formSteps';
import { useStoreSelector } from 'store';
import isReEnrolmentOrLinkedCustomer from 'utils/helpers/isReEnrolmentOrLinkedCustomer';
import AccordionItemWrapper from './AccordionItemWrapper/AccordionItemWrapper';
import HealthAndDriving from './AccordionContent/HealthAndDriving/HealthAndDriving';
import Questionnaire from './AccordionContent/Questionnaire/Questionnaire';
import ExitingCustomer from './AccordionContent/ExistingCustomer/ExistingCustomer';
import * as Styled from './AccordionController.styled';

const AccordionController: React.FC = () => {
  const identificationType = useStoreSelector(
    (state) => state.customer.enrolmentIdentificationType
  );
  const isLinkedOrAlreadyEnrolled =
    isReEnrolmentOrLinkedCustomer(identificationType);

  return (
    <section>
      <Styled.AccordionContainer id={accordionWrapperId}>
        <AccordionItemWrapper
          eligibilityFormID='questionnaire'
          title='Check your eligibility'
          Icon={BrandIconAddLicence}
        >
          <Questionnaire />
        </AccordionItemWrapper>

        <AccordionItemWrapper
          eligibilityFormID='healthAndDriving'
          title='Your health and driving'
          Icon={BrandIconHealth}
        >
          <HealthAndDriving />
        </AccordionItemWrapper>

        {!isLinkedOrAlreadyEnrolled && (
          <AccordionItemWrapper
            eligibilityFormID='existingCustomer'
            title='Existing customer record'
            Icon={BrandIconPersonalDataSecurity}
          >
            <ExitingCustomer />
          </AccordionItemWrapper>
        )}
      </Styled.AccordionContainer>
    </section>
  );
};

export default AccordionController;
