import { EnrolmentIdentificationType } from 'api/types/drives';
import { enrolmentIdentificationType } from 'consts/transport';

const isReEnrolmentOrLinkedCustomer = (
  identificationType: EnrolmentIdentificationType | null
): boolean => {
  const isLinkedCustomer =
    identificationType === enrolmentIdentificationType.linked;
  const isReEnrolment =
    identificationType === enrolmentIdentificationType.reEnrolment;
  return isLinkedCustomer || isReEnrolment;
};

export default isReEnrolmentOrLinkedCustomer;
