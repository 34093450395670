import React from 'react';
import { FormCheckbox } from '@snsw-gel/react';
import { useStoreDispatch, useStoreSelector } from 'store';
import { reviewSliceActions } from 'store/slice/review';

const ConsentDKTContact: React.FC = () => {
  const dispatch = useStoreDispatch();
  const consentDKTContact = useStoreSelector(
    (state) => state.review.consentDKTContact
  );

  const handleToggleCheckbox = () => {
    dispatch(reviewSliceActions.setConsentDKTContact(!consentDKTContact));
  };

  return (
    <FormCheckbox
      id='consent-dkt-contact'
      name='consent DKT contact'
      label='(Optional) I consent to being contacted about my DKT online learning experience for the purpose of research and evaluation of the program.'
      value='consent DKT contact checked'
      onChange={handleToggleCheckbox}
      checked={consentDKTContact}
    />
  );
};

export default ConsentDKTContact;
