import React, { useState } from 'react';
import { Button } from '@snsw-gel/react';
import { analyticsServiceV2 } from '@rmstransactions/components';
import * as StyledRadioGroup from 'components/RadioGroup/SurveyRadioGroup.styled';
import { FormNavigationButtonWrapper } from 'components/ButtonStyling/ButtonStyling.styled';
import { useStoreDispatch, useStoreSelector } from 'store';
import { healthAndDrivingConditionsActions } from 'store/slice/healthAndDrivingConditions';
import { eligibilityFormSliceActions } from 'store/slice/eligibilityForm';
import type { FormValues } from 'store/slice/form';
import {
  analyticsEnrolmentType,
  analyticsPushButtonEvent,
} from 'utils/session/pushGoogleAnalyticsPage';
import CheckYourFitnessToDrive from './InformativeCards/CheckYourFitnessToDrive';
import EyesightTests from './InformativeCards/EyesightTests';
import MedicalConditionDisabilityModal from './InformationModals/MedicalConditionDisabilityModal/MedicalConditionDisabilityModal';
import EyeOrVisionModal from './InformationModals/EyeOrVisionModal/EyeOrVisionModal';
const { defaultAnalyticsNullValue } = analyticsServiceV2;

const { setSeriousLongTerm, setEyeOrVision } =
  healthAndDrivingConditionsActions;

const SelfSurvey: React.FC = () => {
  const dispatch = useStoreDispatch();
  const seriousLongTerm = useStoreSelector(
    (state) => state.healthAndDrivingConditions.seriousLongTerm
  );
  const eyeOrVision = useStoreSelector(
    (state) => state.healthAndDrivingConditions.eyeOrVision
  );
  const [seriousLongTermError, setSeriousLongTermError] = useState(false);
  const [eyeOrVisionError, setEyeOrVisionError] = useState(false);

  const handleSeriousLongTermChange = (newValue: FormValues) => {
    dispatch(setSeriousLongTerm(newValue));
    setSeriousLongTermError(false);
  };

  const handleEyeOrVisionChange = (newValue: FormValues) => {
    dispatch(setEyeOrVision(newValue));
    setEyeOrVisionError(false);
  };

  const handleContinueSurvey = () => {
    const eyeOrVisionSelected = eyeOrVision !== null;
    const seriousLongTermSelected = seriousLongTerm !== null;

    if (!eyeOrVisionSelected) {
      setEyeOrVisionError(true);
    }
    if (!seriousLongTermSelected) {
      setSeriousLongTermError(true);
    }

    if (!eyeOrVisionSelected || !seriousLongTermSelected) return;

    // Google analytics stage 3
    const transaction_sub_type = analyticsEnrolmentType.getValue() as string;
    analyticsPushButtonEvent({
      ...saveAndContinueAnalyticsData,
      destination_url: 'your health and driving',
      transaction_sub_type: transaction_sub_type ?? defaultAnalyticsNullValue,
    });

    // update parent form/accordion
    dispatch(
      eligibilityFormSliceActions.runFormStepCompleted('healthAndDriving')
    );
  };

  return (
    <form>
      <StyledRadioGroup.SurveyFormRadioGroup
        id='seriousLongTerm'
        name='seriousLongTermGroup'
        legend='Do you have a serious (permanent or long-term) illness, disability, medical condition or injury that may affect your ability to drive?'
        vertical={false}
        options={surveyOptions}
        value={seriousLongTerm}
        onChange={handleSeriousLongTermChange}
        hasError={seriousLongTermError}
        errorMessage='Select an option.'
      />
      <CheckYourFitnessToDrive />
      <MedicalConditionDisabilityModal />

      <StyledRadioGroup.SurveyFormRadioGroup
        id='eyeOrVision'
        name='eyeOrVisionGroup'
        legend='Do you have an eye or vision condition that may affect your driving?'
        helpMessage='You will need to complete an eye test when you apply for your licence in-person.'
        vertical={false}
        options={surveyOptions}
        value={eyeOrVision}
        onChange={handleEyeOrVisionChange}
        hasError={eyeOrVisionError}
        errorMessage='Select an option.'
      />
      <EyesightTests />
      <EyeOrVisionModal />

      <FormNavigationButtonWrapper>
        <Button onClick={handleContinueSurvey} variant='secondary'>
          Save and continue
        </Button>
      </FormNavigationButtonWrapper>
    </form>
  );
};

export default SelfSurvey;

const surveyOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

const saveAndContinueAnalyticsData = {
  component_type: 'button_secondary',
  component_text: 'save and continue',
  section_id: 'page_content',
  position_id: '1',
};
