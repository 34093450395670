import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { healthAndDrivingSessionManager } from 'utils/session/storeSessionManagers';
import { FormValues } from './form';

export interface HealthAndDrivingConditionsState {
  seriousLongTerm: FormValues | null;
  eyeOrVision: FormValues | null;
}

export const initialState: HealthAndDrivingConditionsState = {
  seriousLongTerm: null,
  eyeOrVision: null,
};
const stateKeys = Object.keys(initialState) as Array<keyof typeof initialState>;
const acceptedValues = [null, 'yes', 'no'];

export const healthAndDrivingConditionsSlice = createSlice({
  name: 'healthAndDrivingConditions',
  initialState,
  reducers: {
    setSeriousLongTerm: (state, action: PayloadAction<FormValues>) => {
      state.seriousLongTerm = action.payload;
    },
    setEyeOrVision: (state, action: PayloadAction<FormValues>) => {
      state.eyeOrVision = action.payload;
    },
    saveToSessionData: (state) => {
      healthAndDrivingSessionManager.setValue(JSON.stringify(state));
    },
    restoreFromSessionData: (state) => {
      const sessionData = healthAndDrivingSessionManager.getValue();
      if (!sessionData) return;

      const data = JSON.parse(sessionData);
      stateKeys.forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const value = data[key];
          if (acceptedValues.includes(value)) {
            state[key] = value;
          }
        }
      });
    },
  },
});

export const healthAndDrivingConditionsActions =
  healthAndDrivingConditionsSlice.actions;
export default healthAndDrivingConditionsSlice.reducer;
