import { tokens } from '@snsw-gel/react';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  margin: 2.5rem 0;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    margin: 3.5rem 0;
  }
`;
