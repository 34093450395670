import React from 'react';
import { useStoreSelector } from 'store';
import { tfNSWCardTypes } from 'consts/transport';
import * as Styled from './CardInfographic.styled';

const { drivesLicenceNumber, nswPhotoCard, mobilityParkingScheme } =
  tfNSWCardTypes;

const CardInfographic: React.FC = () => {
  const tfNSWCardType = useStoreSelector(
    (state) => state.tfNSWCustomer.tfNSWCardType
  );

  switch (tfNSWCardType) {
    case drivesLicenceNumber:
      return (
        <Styled.InfographicFrame
          src='/images/NSW Driver Licence.svg'
          alt='Infographic of a NSW Driver Licence with arrows locating the: name card holders, licence number and date of birth.'
        />
      );
    case nswPhotoCard:
      return (
        <Styled.InfographicFrame
          src='/images/NSW Photo Card.svg'
          alt='Infographic of a NSW Photo Card with arrows locating the card holders: name, PC number and date of birth.'
        />
      );
    case mobilityParkingScheme:
      return (
        <Styled.InfographicFrame
          src='/images/MPS Permit Card.svg'
          alt='Infographic of a Mobility Parking Scheme permit with arrows locating the card holders card number.'
        />
      );
    default:
      return <></>;
  }
};

export default CardInfographic;
