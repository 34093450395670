export type StepContent =
  | 'Check eligibility'
  | 'Verify your identity'
  | 'Personal details'
  | 'Review and submit';
export type StepStatus = 'todo' | 'completed' | 'current';
export type FormStep = {
  content: string;
  status: StepStatus;
};

export const driverKnowledgeTestSteps: FormStep[] = [
  {
    content: 'Check eligibility',
    status: 'todo',
  },
  {
    content: 'Verify your identity',
    status: 'todo',
  },
  {
    content: 'Personal details',
    status: 'todo',
  },
  {
    content: 'Review and submit',
    status: 'todo',
  },
];
