import React from 'react';
import { MoreInfoModal } from '@snsw/react-component-library'; // '@snsw-gel/react' bugged with <forms>
import { LinkTextNotificationIcon } from 'components/MoreInfoModalStyling/MoreInfoModalStyling';
import eyeOrVisionMarkdown from './eyeOrVision.md';
import ModalMarkdownWrapper from '../MarkdownContentWrapper/MarkdownContentWrapper';
import {
  MoreInfoModalLabel,
  SpacingSlidingModalWrapper,
} from '../InformationModal.styles';

const EyeOrVisionModal: React.FC = () => {
  return (
    <SpacingSlidingModalWrapper>
      <MoreInfoModal
        id='eyeOrVision'
        linkText={
          <MoreInfoModalLabel>
            {eyeOrVisionModalLabelText}
            <LinkTextNotificationIcon />
          </MoreInfoModalLabel>
        }
        title='Eye or vision conditions that may affect your driving ability'
        helpText={
          <ModalMarkdownWrapper>{eyeOrVisionMarkdown}</ModalMarkdownWrapper>
        }
        questionHyperLink
      />
    </SpacingSlidingModalWrapper>
  );
};

export default EyeOrVisionModal;

export const eyeOrVisionModalLabelText =
  'Open this tooltip to find out more about eye and vision conditions';
