import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { accordionWrapperId } from 'consts/formSteps';
import documentUtilities from 'utils/helpers/documentUtilities';

export type EligibilityAccordionID =
  | 'questionnaire'
  | 'healthAndDriving'
  | 'existingCustomer';
type ActiveAccordion = EligibilityAccordionID | null;
export type EligibilityValidationState = Record<
  EligibilityAccordionID,
  boolean
>;
const eligibilityOrder: Array<EligibilityAccordionID> = [
  'questionnaire',
  'healthAndDriving',
  'existingCustomer',
];

export interface EligibilityFormState {
  activeAccordion: ActiveAccordion;
  completeStatus: {
    questionnaire: boolean;
    healthAndDriving: boolean;
    existingCustomer: boolean;
  };
  errorStatus: {
    questionnaire: boolean;
    healthAndDriving: boolean;
    existingCustomer: boolean;
  };
}

export const initialCompleteStatus = {
  questionnaire: false,
  healthAndDriving: false,
  existingCustomer: false,
};

export const initialState: EligibilityFormState = {
  activeAccordion: null,
  completeStatus: initialCompleteStatus,
  errorStatus: {
    questionnaire: false,
    healthAndDriving: false,
    existingCustomer: false,
  },
};

export const eligibilityFormSlice = createSlice({
  name: 'eligibilityForm',
  initialState,
  reducers: {
    setActiveAccordion: (
      state,
      action: PayloadAction<EligibilityAccordionID>
    ) => {
      const accordionID = action.payload;
      const isAlreadyActive = state.activeAccordion === accordionID;
      state.activeAccordion = isAlreadyActive ? null : accordionID;
      if (!isAlreadyActive) {
        state.completeStatus[accordionID] = false;
      }
    },
    setCompleteStatus: (
      state,
      action: PayloadAction<Partial<EligibilityValidationState>>
    ) => {
      state.completeStatus = {
        ...state.completeStatus,
        ...action.payload,
      };
    },
    setErrorStatus: (
      state,
      action: PayloadAction<Partial<EligibilityValidationState>>
    ) => {
      state.errorStatus = {
        ...state.errorStatus,
        ...action.payload,
      };
    },
    runFormStepCompleted: (
      state,
      action: PayloadAction<EligibilityAccordionID>
    ) => {
      const id = action.payload;
      state.completeStatus[id] = true;
      state.errorStatus[id] = false;

      const nextAccordionId = getNextAccordionId(id);
      if (nextAccordionId && !state.completeStatus[nextAccordionId]) {
        documentUtilities.focusById(`accordion-${nextAccordionId}`);
        documentUtilities.scrollToById(accordionWrapperId);
        state.activeAccordion = nextAccordionId;
      } else {
        documentUtilities.focusById(`accordion-${id}`);
        documentUtilities.scrollToById(accordionWrapperId);
        state.activeAccordion = null;
      }
    },
    setFormComplete: (state) => {
      state.completeStatus = {
        questionnaire: true,
        healthAndDriving: true,
        existingCustomer: true,
      };
    },
  },
});

export const eligibilityFormSliceActions = eligibilityFormSlice.actions;
export default eligibilityFormSlice.reducer;

const getNextAccordionId = (completedAccordion: EligibilityAccordionID) => {
  const index = eligibilityOrder.indexOf(completedAccordion);
  if (index < 0 || index === eligibilityOrder.length - 1) return null;

  return eligibilityOrder[index + 1];
};
