import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from '@snsw-gel/react';
import { FormNavigationButtonWrapper } from 'components/ButtonStyling/ButtonStyling.styled';
import { enrolmentIdentificationType } from 'consts/transport';
import routes from 'config/routes';
import { useStoreDispatch, useStoreSelector } from 'store';
import { customerSliceActions } from 'store/slice/customer';
import { eligibilityFormSliceActions } from 'store/slice/eligibilityForm';
import {
  TFNSWCardData,
  TFNSWCardValidation,
  tfNSWCardTypeFormValue,
  tfNSWCustomerSliceActions,
} from 'store/slice/tfNSWCustomer';
import cleanNullKeysFromRecord from 'utils/helpers/cleanNullKeysFromRecord';
import handleQuitTransaction from 'utils/routes/quitTransaction';
import NoneSelectedError from './NoneSelectedError/NoneSelectedError';
import {
  getInvalidCardNumberError,
  getInvalidDateError,
  getInvalidNameError,
} from './formValidation';
import {
  analyticsEnrolmentType,
  analyticsPushButtonEvent,
} from 'utils/session/pushGoogleAnalyticsPage';
import { reviewSliceActions } from 'store/slice/review';

const FormActions: React.FC<FormActionsProps> = ({
  isExistingCustomer = false,
}) => {
  const history = useHistory();
  const dispatch = useStoreDispatch();
  const tfNSWCardType = useStoreSelector(
    (state) => state.tfNSWCustomer.tfNSWCardType
  );
  const tfNSWData = useStoreSelector((state) => state.tfNSWCustomer.data);
  const [showEmptyError, setShowEmptyError] = useState<boolean>(false);

  useEffect(() => {
    if (showEmptyError && tfNSWCardType !== null) {
      if (isExistingCustomer && tfNSWCardType === 'n/a') {
        // a DVS existing customer cannot be 'n/a', keep error visible
        return;
      } else {
        setShowEmptyError(false);
      }
    }
  }, [showEmptyError, tfNSWCardType]);

  const handleCompleteForm = () => {
    dispatch(
      eligibilityFormSliceActions.runFormStepCompleted('existingCustomer')
    );
    dispatch(reviewSliceActions.setErrorNotification(null));

    // Google analytics stage 3
    const destination_url = isExistingCustomer
      ? routes.reviewAndSubmit.path
      : 'existing customer record';
    const component_type = isExistingCustomer
      ? 'button_primary'
      : 'button_secondary';
    const transaction_sub_type = analyticsEnrolmentType.getValue() as string;
    analyticsPushButtonEvent({
      ...saveAndContinueAnalyticsData,
      component_type,
      destination_url,
      transaction_sub_type,
    });

    if (isExistingCustomer) {
      history.push(routes.reviewAndSubmit.path);
    }
  };

  const handleOnContinue = () => {
    const cardTypeIsNone = tfNSWCardType === 'n/a';
    if (tfNSWCardType === null || (cardTypeIsNone && isExistingCustomer)) {
      setShowEmptyError(true);
      return;
    }

    if (cardTypeIsNone) {
      // enrolment type: new customer => profile connect
      dispatch(
        customerSliceActions.setEnrolmentType(enrolmentIdentificationType.dvs)
      );

      handleCompleteForm();
      return;
    }

    const validation = validateForm(tfNSWData, tfNSWCardType);
    dispatch(tfNSWCustomerSliceActions.setCardValidation(validation));

    const alertValidation = cleanNullKeysFromRecord(validation);
    dispatch(
      tfNSWCustomerSliceActions.setInPageAlertValidation(alertValidation)
    );

    const hasValidationError = Object.keys(alertValidation).length > 0;
    if (hasValidationError) {
      return;
    }

    // enrolment type: existing tfNSW customer => step 4
    dispatch(
      customerSliceActions.setEnrolmentType(
        enrolmentIdentificationType.drivesProduct
      )
    );

    handleCompleteForm();
  };

  return (
    <>
      <NoneSelectedError
        visible={showEmptyError}
        isExistingCustomer={isExistingCustomer}
      />
      <FormNavigationButtonWrapper>
        <Button
          variant={isExistingCustomer ? 'primary' : 'secondary'}
          onClick={handleOnContinue}
        >
          Save and continue
        </Button>

        {isExistingCustomer && (
          <Button
            variant='secondary'
            onClick={handleQuitTransaction.redirectToDrupal}
          >
            Quit
          </Button>
        )}
      </FormNavigationButtonWrapper>
    </>
  );
};

export default FormActions;

interface FormActionsProps {
  isExistingCustomer?: boolean;
}

export const validateForm = (
  data: TFNSWCardData,
  cardType: tfNSWCardTypeFormValue
): TFNSWCardValidation => {
  const { firstName, middleName, lastName, dateOfBirth, tfNSWCardNumber } =
    data;

  const isFirstNameRequired = Boolean(middleName);
  const validation = {
    firstName: getInvalidNameError(
      firstName,
      maxLengthFirstName,
      isFirstNameRequired
    ),
    middleName: getInvalidNameError(middleName, maxLengthMiddleName, false),
    lastName: getInvalidNameError(lastName, maxLengthLastName, true),
    dateOfBirth: getInvalidDateError(dateOfBirth),
    tfNSWCardNumber: getInvalidCardNumberError(tfNSWCardNumber, cardType),
  };

  return validation;
};

const maxLengthFirstName = 60;
const maxLengthMiddleName = 60;
const maxLengthLastName = 200;

const saveAndContinueAnalyticsData = {
  component_text: 'save and continue',
  section_id: 'page_content',
  position_id: '1',
};
