import styled from 'styled-components';
import { tokens } from '@snsw-gel/react';

const midwaySmMdBreakpoint =
  (tokens.breakpoints.sm + tokens.breakpoints.md) / 2;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;

  @media screen and (min-width: ${midwaySmMdBreakpoint}px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 3.5rem;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  max-height: 12rem;
  grid-row: 1;

  @media screen and (min-width: ${midwaySmMdBreakpoint}px) {
    max-height: none;
    grid-column: 3;
  }
`;

export const ContentWrapper = styled.div`
  @media screen and (min-width: ${midwaySmMdBreakpoint}px) {
    grid-column: span 2;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;
