/**
 * returns functions to modify a sessionStorage's key value
 * closuring key for flexibility between keys
 *
 * @param sessionKey
 * @returns Object with three functions to help manage the key-value in the session storage
 */
const sessionStorageKeyManager = (sessionKey: string) => {
  let key = sessionKey;

  return {
    getValue: (): string | null => {
      return sessionStorage.getItem(key);
    },
    setValue: (value: string): void => {
      sessionStorage.setItem(key, value);
    },
    clear: (): void => {
      sessionStorage.removeItem(key);
    },
  };
};

export default sessionStorageKeyManager;
