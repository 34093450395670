import React from 'react';
import { ContentContainer, SectionHeader } from '@snsw-gel/react';
import { mainElementId } from 'consts/links';
import { ContentWrapper } from '../Layouts.styled';
import TimeoutHandler from '../TimeoutHandler/TimeoutHandler';
import * as Styled from './PageLayout.styled';

const PageLayout: React.FC<PageLayoutProps> = ({
  title,
  label,
  breadcrumbList,
  success = false,
  children,
}) => {
  const headerTitle = success ? (
    <Styled.HeadingIconWrapper>
      <Styled.IconNotificationSuccess
        size='lg'
        data-testid='header-success-icon'
      />
      <span>{title}</span>
    </Styled.HeadingIconWrapper>
  ) : (
    title
  );

  return (
    <>
      <main id={mainElementId}>
        <SectionHeader
          title={headerTitle}
          label={label}
          breadcrumbList={breadcrumbList}
        />
        <ContentContainer>
          <ContentWrapper>{children}</ContentWrapper>
        </ContentContainer>
      </main>
      <TimeoutHandler />
    </>
  );
};

export default PageLayout;

interface PageLayoutProps {
  title: string;
  label?: string;
  success?: boolean;
  breadcrumbList?: Breadcrumb[];
}

type Breadcrumb = {
  content: string;
  path: string;
};
