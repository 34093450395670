import React from 'react';
import { TextLink, SkeletonText, SkeletonContainer } from '@snsw-gel/react';
import { useStoreSelector } from 'store';
import * as Styled from './EnrolmentConfirmation.styled';
import { emailReceiptActionID } from '../ReceiptActions/ReceiptActions';

const EnrolmentConfirmation: React.FC = () => {
  const confirmationData = useStoreSelector((state) => state.confirmation.data);
  const emailFromCustomerInfo = useStoreSelector(
    (state) => state.customer.email
  );

  if (!confirmationData) {
    return (
      <section data-testid='enrolment-confirmation-skeleton'>
        <SkeletonContainer>
          <SkeletonText rows={2} />
          <SkeletonText rows={1} />
          <SkeletonText rows={2} />
        </SkeletonContainer>
      </section>
    );
  }

  const { emailAddress, customerId, transactionRef, transactionDate } =
    confirmationData;
  const customerEmail = emailAddress ?? emailFromCustomerInfo;

  return (
    <section>
      <p>
        You have successfully enrolled in DKT online! You have 12 months to
        complete the course and pass the online test.
      </p>
      <p>
        Your customer number is <strong>{customerId}</strong> – keep this for
        future reference.
      </p>
      <p>
        An email confirming your enrolment has been sent to{' '}
        <strong>{customerEmail}</strong>{' '}
        <TextLink
          id='send-another-email'
          href={`#${emailReceiptActionID}-input`}
        >
          Send to another email address
        </TextLink>
      </p>
      <Styled.InformationParagraph>
        Transaction reference ID: <strong>{transactionRef}</strong>
      </Styled.InformationParagraph>
      <Styled.InformationParagraph>
        Date of transaction: <strong>{transactionDate}</strong>
      </Styled.InformationParagraph>
    </section>
  );
};

export default EnrolmentConfirmation;
