import React from 'react';
import { InPageAlert } from '@snsw-gel/react';
import {
  TelephoneContactNumberHyperlink,
  ServiceCentreHyperlink,
} from 'components/Hyperlinks';

const NoneSelectedError: React.FC<NoneSelectedErrorProps> = ({
  visible,
  isExistingCustomer = false,
}) => {
  if (!visible) return <></>;

  if (isExistingCustomer) return existingCustomerAlert;

  return defaultAlert;
};

export default NoneSelectedError;

interface NoneSelectedErrorProps {
  visible: boolean;
  isExistingCustomer?: boolean;
}

const defaultAlert = (
  <InPageAlert variant='error' title='Select an option to proceed'>
    <p>
      If you do not have one of the documents listed please select{' '}
      <strong>none of the above</strong> to continue.
    </p>
    <p>
      If you have a document but do not have the details, call us on{' '}
      <TelephoneContactNumberHyperlink /> for assistance.
    </p>
  </InPageAlert>
);

const existingCustomerAlert = (
  <InPageAlert variant='error' title='Select an item to continue'>
    <p>
      If you need assistance please call us on{' '}
      <TelephoneContactNumberHyperlink /> or visit a <ServiceCentreHyperlink />.
    </p>
  </InPageAlert>
);
