import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EnrolmentIdentificationType } from 'api/types/drives';
import type { CustomerInfoResponse } from 'api/types/response';
import {
  analyticsEnrolmentType,
  analyticsEnrolmentTypeValues,
  updateTransactionSubType,
} from 'utils/session/pushGoogleAnalyticsPage';

export interface CustomerState {
  displayName: string | null;
  email: string | null;
  isDKTLinked: boolean;
  enrolmentIdentificationType: EnrolmentIdentificationType | null;
}

export const initialState: CustomerState = {
  displayName: null,
  email: null,
  isDKTLinked: false,
  enrolmentIdentificationType: null,
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerData: (state, action: PayloadAction<CustomerInfoResponse>) => {
      const { firstName, email } = action.payload;
      if (firstName && firstName.indexOf('@') < 0) {
        state.displayName = firstName;
      }
      state.email = email;
    },
    setIsDKTLinked: (state, action: PayloadAction<boolean>) => {
      state.isDKTLinked = action.payload;
    },
    setEnrolmentType: (
      state,
      action: PayloadAction<null | EnrolmentIdentificationType>
    ) => {
      state.enrolmentIdentificationType = action.payload;

      // set Google Analytics enrolment type
      if (action.payload) {
        const transactionType = analyticsEnrolmentTypeValues[action.payload];
        analyticsEnrolmentType.setValue(transactionType);
        updateTransactionSubType(transactionType);
      }
    },
  },
});

export const customerSliceActions = customerSlice.actions;
export default customerSlice.reducer;
