import { Card, tokens } from '@snsw/react-component-library';
import styled from 'styled-components';

export const PrivacyWrapper = styled(Card)`
  display: grid;
  margin-top: 2rem;
  border-radius: 0.5rem;
  padding: 2rem;
  gap: 1rem;
  height: fit-content;
  box-shadow: 0rem 0rem 0.5rem 0rem rgba(0, 0, 0, 0.16);

  @media only screen and (max-width: ${tokens.breakpoints.md}px) {
    flex-flow: row wrap;
  }
`;

export const PrivacyDescription = styled.div`
  font-size: 0.875rem;
  line-height: 1.375;
  grid-column: 1 / span 2;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    grid-column: 2;
  }
`;
