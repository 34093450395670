import React from 'react';
import {
  ServiceCentreHyperlink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';
import TemplateErrorPage from './TemplateErrorPage/TemplateErrorPage';

const BusinessErrorPage: React.FC = () => {
  return (
    <TemplateErrorPage
      pageTitle="We're sorry about this"
      defaultAlertTitle='Our system is temporarily unavailable'
      analyticsErrorType='business error'
      contactDescription={contactDescription}
    />
  );
};

export default BusinessErrorPage;

const contactDescription = (
  <p>
    You can try again later. If you still receive an error, please call us on{' '}
    <TelephoneContactNumberHyperlink /> or visit a <ServiceCentreHyperlink />.
    We apologise for any inconvenience.
  </p>
);
