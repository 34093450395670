import React from 'react';
import { Quickfeed } from '@rmstransactions/components';
import { TUTDContainer } from './ThumbsUpThumbsDown.styled';
import { TUTDAppName, TUTDLabel } from 'config/constants';

const ThumbsUpThumbsDown: React.FC = () => {
    return (
        <TUTDContainer>
            <Quickfeed
                sourceApplication={TUTDAppName}
                label={TUTDLabel}
                environment={process.env.REACT_APP_ENV === 'prod' ? 'production' : 'uat'}
            />
        </TUTDContainer>
    );
};

export default ThumbsUpThumbsDown;