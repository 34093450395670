import React, { useState } from 'react';
import {
  IconEmail,
  IconPrint,
  IconDownload,
  Button,
  Heading,
} from '@snsw/react-component-library';
import EmailConfirmationModal from '../EmailConfirmationModal/EmailConfirmationModal';
import * as Styled from './KeepAReceipt.styled';
import {
  EmailValidationErrors,
  emailValidationErrorsData,
  validateEmail,
} from './EmailInput/validateEmail';
import EmailInput from './EmailInput/EmailInput';

const KeepAReceipt: React.FC<KeepAReceiptProps> = ({
  id,
  emailFieldId = 'sendEmail',
  onPrint,
  onDownload,
  onEmailSubmit,
  heading = 'Download or share your receipt',
  labelPrint = 'Print',
  labelDownload = 'Download transaction receipt',
  labelEmail = 'Email your receipt to:',
  confirmationModalContentName = 'receipt',
}) => {
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [emailValidationError, setEmailValidationError] =
    useState<EmailValidationErrors | null>(null);
  const [confirmEmailModalOpen, setConfirmEmailModalOpen] =
    useState<boolean>(false);

  const handleOnSendEmail = () => {
    const validationErrors = validateEmail(emailAddress);
    if (validationErrors.length > 0) {
      setEmailValidationError(validationErrors[0]);
      return;
    }
    setEmailValidationError(null);
    setConfirmEmailModalOpen(true);
  };

  const onConfirmEmail = () => {
    setConfirmEmailModalOpen(false);
    onEmailSubmit(emailAddress);
  };

  const emailErrorMessage = emailValidationError
    ? emailValidationErrorsData[emailValidationError]
    : '';

  return (
    <>
      <div id={id}>
        <Heading level={3}>{heading}</Heading>
        <Styled.ActionWrapper>
          <Styled.ActionRow>
            <Styled.IconWrapper>
              <IconPrint size={Styled.receiptIconSize} />
            </Styled.IconWrapper>
            <Button variant='link' onClick={onPrint}>
              {labelPrint}
            </Button>
          </Styled.ActionRow>
        </Styled.ActionWrapper>

        <Styled.ActionWrapper>
          <Styled.ActionRow>
            <Styled.IconWrapper>
              <IconDownload size={Styled.receiptIconSize} />
            </Styled.IconWrapper>
            <Button variant='link' onClick={onDownload}>
              {labelDownload}
            </Button>
          </Styled.ActionRow>
        </Styled.ActionWrapper>

        <Styled.ActionWrapper>
          <Styled.EmailLabel
            htmlFor={`${emailFieldId}-input`}
            id={`${emailFieldId}-label`}
          >
            <Styled.ActionRow>
              <Styled.IconWrapper>
                <IconEmail size={Styled.receiptIconSize} />
              </Styled.IconWrapper>
              {labelEmail}
            </Styled.ActionRow>
          </Styled.EmailLabel>
          <Styled.EmailFormWrapper>
            <EmailInput
              id={`${emailFieldId}-input`}
              name='receiptEmail'
              value={emailAddress}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setEmailAddress(event.target.value)
              }
              hasError={Boolean(emailErrorMessage)}
              errorMessage={emailErrorMessage}
              data-testid='receiptEmail'
              aria-labelledby={`${emailFieldId}-label`}
            />

            <Button variant='secondary' onClick={handleOnSendEmail}>
              Send
            </Button>
          </Styled.EmailFormWrapper>
        </Styled.ActionWrapper>
      </div>
      <EmailConfirmationModal
        emailAddress={emailAddress}
        contentName={confirmationModalContentName}
        onCancel={() => setConfirmEmailModalOpen(false)}
        onConfirm={onConfirmEmail}
        isOpen={confirmEmailModalOpen}
      />
    </>
  );
};

export default KeepAReceipt;

interface KeepAReceiptProps {
  id: string;
  emailFieldId?: string;
  onDownload: () => void;
  onPrint: () => void;
  onEmailSubmit: (email: string) => void;
  heading?: string;
  labelDownload?: string;
  labelPrint?: string;
  labelEmail?: string;
  confirmationModalContentName?: string;
}
