import dayjs, { Dayjs } from 'dayjs';

const isDktDateOfBirthValid = (
  dateOfBirth: Dayjs,
  nowDate = dayjs()
): boolean => {
  const minimumRequiredDate = nowDate
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .subtract(15, 'year')
    .subtract(11, 'month');

  if (minimumRequiredDate.isSame(dateOfBirth)) return true;
  return minimumRequiredDate.isAfter(dateOfBirth);
};

export default isDktDateOfBirthValid;
