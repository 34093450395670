import React, { useState } from 'react';
import { Button } from '@snsw-gel/react';
import { FormNavigationButtonWrapper } from 'components/ButtonStyling/ButtonStyling.styled';
import handleQuitTransaction from 'utils/routes/quitTransaction';
import OnlineNotificationsModal from './OnlineNotificationsModal/OnlineNotificationsModal';

const CallToActions: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleOnContinue = () => {
    setShowModal(true);
  };

  return (
    <>
      <FormNavigationButtonWrapper>
        <Button onClick={handleOnContinue}>Continue</Button>
        <Button
          variant='secondary'
          onClick={handleQuitTransaction.redirectToDrupal}
        >
          Quit
        </Button>
      </FormNavigationButtonWrapper>
      {showModal && (
        <OnlineNotificationsModal onCloseModal={() => setShowModal(false)} />
      )}
    </>
  );
};

export default CallToActions;
