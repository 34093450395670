import React from 'react';
import { Svg } from '@rmstransactions/components';
import {
  ExternalTextLink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';
import { nswEyesightTestsForDriverAndRiderLicensing } from 'consts/links';
import { useStoreSelector } from 'store';
import * as Styled from './InformativeCards.styled';

const EyesightTests: React.FC = () => {
  const eyeOrVision = useStoreSelector(
    (state) => state.healthAndDrivingConditions.eyeOrVision
  );
  if (eyeOrVision !== 'yes') return <></>;

  return (
    <Styled.Box>
      <Styled.BoxIconWrapper>
        <Svg.BrandEyeTest />
      </Styled.BoxIconWrapper>
      <Styled.BoxHeading>Eyesight tests</Styled.BoxHeading>
      <Styled.BoxDescription>
        <p>
          If you have an eye or vision condition, you may need a report from an
          optometrist or eye specialist before you apply for your licence.
        </p>
        <p>
          To find out more visit{' '}
          <ExternalTextLink href={nswEyesightTestsForDriverAndRiderLicensing}>
            nsw.gov.au
          </ExternalTextLink>{' '}
          or call us on <TelephoneContactNumberHyperlink />.
        </p>
      </Styled.BoxDescription>
    </Styled.Box>
  );
};

export default EyesightTests;
