import React from 'react';
import { useStoreSelector } from 'store';
import constructFullName from 'utils/helpers/constructFullName';
import * as Styled from '../DetailsBox.styled';
import EditDvsButton from './EditDvsButton/EditDvsButton';

const ProfileConnectDetails: React.FC = () => {
  const profileData = useStoreSelector((state) => state.review.profileData);

  if (!profileData) return <></>;

  const {
    firstName,
    middleName,
    lastName,
    email,
    sex,
    dateOfBirth,
    mobileNumber,
    residentialAddress,
    product,
    productNumber,
  } = profileData;

  return (
    <Styled.Box data-testid='customer-details-dvs'>
      <Styled.DescriptionList>
        <dt>Full name</dt>
        <dd>{constructFullName([firstName, middleName, lastName]) || ''}</dd>

        <dt>Date of Birth</dt>
        <dd>{dateOfBirth || ''}</dd>

        <dt>Email</dt>
        <dd>{email || ''}</dd>

        <dt>Sex</dt>
        <dd>{sex || ''}</dd>

        <dt>Product</dt>
        <dd>{product || ''}</dd>

        <dt>Number</dt>
        <dd>{productNumber || ''}</dd>

        <dt>Mobile</dt>
        <dd>{mobileNumber || ''}</dd>

        <dt>Residential address</dt>
        <dd>{residentialAddress || ''}</dd>
      </Styled.DescriptionList>
      <EditDvsButton />
    </Styled.Box>
  );
};

export default ProfileConnectDetails;
