import React from 'react';
import ReactMarkdown from 'react-markdown';
import { DriveMessageDetail } from 'api/types/drives';
import * as Styled from './NotificationMessage.styled';

const NotificationMessage: React.FC<NotificationMessageProps> = ({
  message,
  ariaRole = 'status',
  marginTop = false,
  marginBottom = false,
  children,
}) => {
  if (!message) return <></>;

  const { messageTitle, messageDescription, severity } = message;
  const variant =
    severity in alertVariantsMap ? alertVariantsMap[severity] : 'error';
  return (
    <Styled.InPageAlert
      variant={variant}
      title={messageTitle ?? defaultMessageTitle}
      role={ariaRole}
      $marginTop={marginTop}
      $marginBottom={marginBottom}
    >
      <ReactMarkdown>
        {messageDescription ?? defaultMessageDescription}
      </ReactMarkdown>
      {children}
    </Styled.InPageAlert>
  );
};

export default NotificationMessage;

interface NotificationMessageProps {
  message: null | DriveMessageDetail;
  ariaRole?: 'alert' | 'status';
  marginTop?: boolean;
  marginBottom?: boolean;
}

type SNSWInPageAlertVariants = 'info' | 'success' | 'warning' | 'error';

const alertVariantsMap: Record<string, SNSWInPageAlertVariants> = {
  INFORMATION: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};

const defaultMessageTitle = 'Cannot complete your request';
const defaultMessageDescription =
  'We are unable to access this vehicle information due to a technical error. Please call us on 13 77 88 or visit a service centre. We apologise for any inconvenience.';
