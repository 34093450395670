import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { FormCheckbox, Modal } from '@snsw-gel/react';
import { termsAndConditionIdentifier } from 'consts/errors/inLineErrors';
import { useStoreDispatch, useStoreSelector } from 'store';
import { reviewSliceActions } from 'store/slice/review';
import { hyperlinkNewPageExternal } from 'utils/providers/reactMarkdown';
import termsAndConditionsMarkdown from './termsAndConditions.md';
import * as Styled from './TermsAndConditions.styled';

const TermsAndConditions: React.FC = () => {
  const dispatch = useStoreDispatch();
  const termsAndConditions = useStoreSelector(
    (state) => state.review.termsAndConditions
  );
  const errorNotification = useStoreSelector(
    (state) => state.review.errorNotification
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const hasTermsAndConditionError =
    errorNotification?.identifier === termsAndConditionIdentifier;

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleToggleCheckbox = () => {
    dispatch(reviewSliceActions.setTermsAndConditions(!termsAndConditions));
  };

  const handleModalAccept = () => {
    dispatch(reviewSliceActions.setTermsAndConditions(true));
    closeModal();
  };

  return (
    <>
      <p>
        I declare that the information provided is true and correct. I have read
        and accept the DKT online{' '}
        <Styled.NormalWeightButton
          id='button-terms-and-conditions'
          variant='link'
          onClick={openModal}
        >
          Terms and Conditions.
        </Styled.NormalWeightButton>
      </p>

      <FormCheckbox
        id={termsAndConditionsID}
        name='terms and conditions'
        label='I accept the DKT online Terms and Conditions.'
        value='terms and conditions checked'
        errorMessage='Please accept the Terms and Conditions to continue'
        hasError={hasTermsAndConditionError}
        onChange={handleToggleCheckbox}
        checked={termsAndConditions}
        isRequired={true}
      />

      {showModal && (
        <Modal
          title='DKT online Terms and Conditions'
          onClose={closeModal}
          buttons={[
            {
              text: 'I accept',
              onClick: handleModalAccept,
            },
            { text: 'Close', onClick: closeModal },
          ]}
        >
          <ReactMarkdown components={hyperlinkNewPageExternal}>
            {termsAndConditionsMarkdown}
          </ReactMarkdown>
        </Modal>
      )}
    </>
  );
};

export default TermsAndConditions;

export const termsAndConditionsID = 'checkbox-termsAndConditions';
