import React, { useEffect, useState } from 'react';
import { ComponentLoader } from '@snsw-gel/react';
import UserAuth from 'components/UserAuth/UserAuth';
import CallCustomerDetails from 'components/CallCustomerDetails/CallCustomerDetails';
import DKTEnrolmentStatus from 'components/CallDKTEnrolmentStatus/DKTEnrolmentStatus';
import routes from 'config/routes';
import { useStoreDispatch } from 'store';
import { eligibilityFormSliceActions } from 'store/slice/eligibilityForm';
import pushGoogleAnalyticsPage from 'utils/session/pushGoogleAnalyticsPage';

const pageStep = routes.landingPage.pageStep;

const ApiHandler: React.FC = () => {
  const dispatch = useStoreDispatch();
  const [currentTask, setCurrentTask] = useState<
    null | 'userAuth' | 'callCustomerDetails' | 'dktEnrolmentStatus'
  >(null);
  const isLoading = currentTask !== null;

  useEffect(() => {
    setCurrentTask(statusUserAuth);
  }, []);

  const handleCleanup = () => {
    dispatch(eligibilityFormSliceActions.setActiveAccordion('questionnaire'));
    pushGoogleAnalyticsPage(pageStep);
    setCurrentTask(null);
  };

  return (
    <>
      {isLoading && (
        <ComponentLoader
          label='Processing, please wait. Do not refresh or navigate away.'
          fullPage
        />
      )}
      {currentTask === statusUserAuth && (
        <UserAuth
          callbackFn={() => setCurrentTask(statusCallCustomerDetails)}
        />
      )}
      {currentTask === statusCallCustomerDetails && (
        <CallCustomerDetails
          callbackFn={() => setCurrentTask(statusDktEnrolmentStatus)}
        />
      )}
      {currentTask === statusDktEnrolmentStatus && (
        <DKTEnrolmentStatus callbackFn={() => handleCleanup()} />
      )}
    </>
  );
};

export default ApiHandler;

const statusUserAuth = 'userAuth';
const statusCallCustomerDetails = 'callCustomerDetails';
const statusDktEnrolmentStatus = 'dktEnrolmentStatus';
