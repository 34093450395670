import React, { useEffect, useState } from 'react';
import { ComponentLoader } from '@snsw-gel/react';
import CallCustomerDetails from 'components/CallCustomerDetails/CallCustomerDetails';
import { useStoreSelector } from 'store';

const HandleCustomerData: React.FC = () => {
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const customerEmail = useStoreSelector((state) => state.customer.email);

  useEffect(() => {
    if (!customerEmail) {
      setIsFetchingData(true);
    }
  }, []);

  if (isFetchingData) {
    return (
      <>
        <ComponentLoader
          label='Processing, please wait. Do not refresh or navigate away.'
          fullPage
        />
        <CallCustomerDetails callbackFn={() => setIsFetchingData(false)} />
      </>
    );
  }

  return <></>;
};

export default HandleCustomerData;
