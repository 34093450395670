import React from 'react';
import { ContentContainer, FormHeader } from '@snsw-gel/react';
import type { FormStep } from 'consts/formSteps';
import { mainElementId } from 'consts/links';
import { ContentWrapper } from '../Layouts.styled';
import TimeoutHandler from '../TimeoutHandler/TimeoutHandler';

const FormLayout: React.FC<FormLayoutProps> = ({
  title,
  label,
  stepsList,
  children,
}) => (
  <>
    <main id={mainElementId}>
      <FormHeader title={title} label={label} stepsList={stepsList} />
      <ContentContainer>
        <ContentWrapper>{children}</ContentWrapper>
      </ContentContainer>
    </main>
    <TimeoutHandler />
  </>
);

export default FormLayout;

interface FormLayoutProps {
  title: string;
  label: string;
  stepsList: FormStep[] | null;
}
