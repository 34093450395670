import styled from 'styled-components';
import { Heading as SNSWHeading, tokens } from '@snsw-gel/react';

export const HeadingContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  margin: 2rem 0;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    margin: 2.5rem 0;
  }
`;

export const Heading = styled(SNSWHeading)`
  && {
    margin: auto 0;
  }
`;
