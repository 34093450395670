import React from 'react';
import { SkeletonContainer, SkeletonText } from '@snsw-gel/react';
import * as Styled from './DetailsBox.styled';

const DetailsSkeleton: React.FC = () => {
  return (
    <SkeletonContainer>
      <Styled.Box data-testid='customer-details-skeleton'>
        <Styled.DescriptionList>
          {Array(6)
            .fill(null)
            .map((_, idx) => (
              <React.Fragment key={idx}>
                <dt>
                  <SkeletonText />
                </dt>
                <dd>
                  <SkeletonText />
                </dd>
              </React.Fragment>
            ))}
        </Styled.DescriptionList>
      </Styled.Box>
    </SkeletonContainer>
  );
};

export default DetailsSkeleton;
