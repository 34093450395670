import { termsAndConditionsID } from 'pages/ReviewAndSubmit/ReviewDeclarations/TermsAndConditions/TermsAndConditions';

export const termsAndConditionIdentifier = 'TERMS_AND_CONDITIONS';

export const errorTermsAndConditionsInvalid = {
  identifier: termsAndConditionIdentifier,
  classification: 'BUSINESS',
  severity: 'ERROR',
  message: '',
  messageTitle: 'Your page has errors',
  messageDescription: `Please check the following item:

  - [Accept the DKT Terms and Conditions to continue](#${termsAndConditionsID})
  `,
};
