import React from 'react';
import * as Styled from '../PostFormInformation.styled';

const InfoExistingCustomer = (): JSX.Element => (
  <section data-testid={existingCustomerTestID}>
    <Styled.InformationWrapper>
      <p>
        You have completed Step 1. You do not need to supply any further
        identification and can progress to Step 4: Review and submit your
        application.
      </p>
    </Styled.InformationWrapper>
  </section>
);

export default InfoExistingCustomer;

export const existingCustomerTestID = 'info-existingCustomer';
