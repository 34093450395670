import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { ComponentLoader, SuccessIndicator } from '@snsw-gel/react';
import { getConfirmationData } from 'api/api';
import HorizontalRule from 'components/HorizontalRule/HorizontalRule';
import { PageLayout } from 'components/Layouts';
import routes from 'config/routes';
import { useStoreDispatch, useStoreSelector } from 'store';
import { confirmationSliceActions } from 'store/slice/confirmation';
import { handleResponseData } from 'utils/api/httpClient';
import handledSystemMessages from 'utils/api/handledSystemMessages';
import { RoutePathParams } from 'utils/routes/routePathParams';
import EnrolmentConfirmation from './EnrolmentConfirmation/EnrolmentConfirmation';
import ReceiptActions from './ReceiptActions/ReceiptActions';
import StartLearning from './StartLearning/StartLearning';
import ThumbsUpThumbsDown from './ThumbsUpThumbsDown/ThumbsUpThumbsDown';

const Confirmation: React.FC = () => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const { receiptUUID } = useParams<RoutePathParams>();
  const confirmationData = useStoreSelector((state) => state.confirmation.data);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const successIndicatorTimer = useRef(0);
  const [showSuccessIndicator, setShowSuccessIndicator] = useState(false);

  useEffect(() => {
    const redirectGenericErrorPage = () => {
      history.push(routes.genericError.path);
    };

    const handleConfirmationData = async () => {
      setIsLoading(true);
      const result = await getConfirmationData(receiptUUID ?? null);
      const data = handleResponseData(result);
      if (data === null) {
        redirectGenericErrorPage();
        return;
      }

      // check system messages
      const messageStatus = handledSystemMessages(data.systemMessages, history);
      if (messageStatus.hasError) {
        if (!messageStatus.redirected) {
          redirectGenericErrorPage();
        }
        return;
      }

      dispatch(confirmationSliceActions.setConfirmationData(data));

      setIsLoading(false);
      setShowSuccessIndicator(true);

      window.clearTimeout(successIndicatorTimer.current);
      successIndicatorTimer.current = window.setTimeout(() => {
        setShowSuccessIndicator(false);
      }, successIndicatorDurationInMilis);
    };

    handleConfirmationData();
    return () => {
      window.clearTimeout(successIndicatorTimer.current);
    };
  }, []);

  const headingTitleStatus = confirmationData
    ? 'Your enrolment is complete'
    : 'Getting your enrolment details';

  return (
    <>
      <PageLayout title={headingTitleStatus} success>
        <EnrolmentConfirmation />
        <HorizontalRule />
        <StartLearning />
        <HorizontalRule />
        <ReceiptActions />
        <ThumbsUpThumbsDown />
      </PageLayout>

      {isLoading && <ComponentLoader label={loadingOverlayText} fullPage />}

      <SuccessIndicator
        active={showSuccessIndicator}
        label={successIndicatorText}
      />
    </>
  );
};

export default Confirmation;

const loadingOverlayText =
  'Processing, please wait. Do not refresh or navigate away.';
const successIndicatorText = 'Congratulations';
const successIndicatorDurationInMilis = 3000;
