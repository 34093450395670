import { validatePlateNumber } from '@rmstransactions/components';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { tfNSWCardTypes } from 'consts/transport';
import {
  validationInvalidAge,
  validationInvalidValue,
  validationRequiredValue,
} from 'store/slice/tfNSWCustomer';
import type {
  ValidationDateOfBirthInvalid,
  ValidationFormInvalid,
  tfNSWCardTypeFormValue,
} from 'store/slice/tfNSWCustomer';
import isDktDateOfBirthValid from 'utils/helpers/isDktDateOfBirthValid';
dayjs.extend(customParseFormat);

export const getInvalidNameError = (
  value: string | null,
  maxLength: number,
  isRequired: boolean
): ValidationFormInvalid => {
  if (value === '' || value === null) {
    if (isRequired) return validationRequiredValue;
    return null;
  }

  const validateCharacters = Boolean(value.match(regexValidNameCharacters));
  const inputExceededMaxLength = value.length > maxLength;

  if (!validateCharacters || inputExceededMaxLength)
    return validationInvalidValue;

  return null;
};

export const getInvalidDateError = (
  value: string | null
): ValidationDateOfBirthInvalid => {
  if (value === '' || value === null) return validationRequiredValue;

  const parsedDate = dayjs(value, 'DD/MM/YYYY', true);
  if (!parsedDate.isValid()) return validationInvalidValue;

  const isDktValidAge = isDktDateOfBirthValid(parsedDate);
  if (!isDktValidAge) return validationInvalidAge;

  return null;
};

const regexValidNameCharacters =
  /^[a-zA-ZàáâäãåąčćçęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñšžÀÁÂÄÃÅĄĆČÇĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßŒÆŠŽ∂ð '-]+$/;
const regexForNonDigitCharacters = /[^0-9]/g;
const regexForNonAlphaNumericCharacters = /[^a-zA-Z0-9]/g;

export const getInvalidCardNumberError = (
  value: string | null,
  cardType: tfNSWCardTypeFormValue
): ValidationFormInvalid => {
  if (!value) return validationRequiredValue;

  // validate plate number
  if (cardType === tfNSWCardTypes.nswPlateNumber) {
    const validationError = validatePlateNumber(value);
    if (validationError) return validationInvalidValue;
    return null;
  }

  // validate digits/alpha-numeric
  const regexValidation =
    cardType === tfNSWCardTypes.drivesLicenceNumber
      ? regexForNonAlphaNumericCharacters
      : regexForNonDigitCharacters;
  const invalidDigits = Boolean(value.match(regexValidation));
  if (invalidDigits) {
    return validationInvalidValue;
  }

  // validate length
  if (
    cardType === tfNSWCardTypes.mobilityParkingScheme ||
    cardType === tfNSWCardTypes.nswPhotoCard
  ) {
    if (value.length > 10) return validationInvalidValue;
  }

  if (
    cardType === tfNSWCardTypes.drivesCustomerNumber ||
    cardType === tfNSWCardTypes.drivesLicenceNumber
  ) {
    if (value.length > 8) return validationInvalidValue;
  }

  return null;
};
