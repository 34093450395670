import React from 'react';
import { Heading } from '@snsw-gel/react';
import {
  ExternalTextLink,
  ServiceCentreHyperlink,
} from 'components/Hyperlinks';
import * as StyledHorizontalCard from 'components/HorizontalCard/HorizontalCard.styled';
import {
  nswDownloadLicenceApplicationForm,
  serviceNswApplyForALearnerDriverLicence,
} from 'consts/links';
import { useStoreSelector } from 'store';
import DocumentInformationModal from './DocumentInformationModal/DocumentInformationModal';
import * as Styled from './ApplyLearnerLicence.styled';

const ApplyLearnerLicence: React.FC = () => {
  const displayName = useStoreSelector((state) => state.customer.displayName);

  return (
    <section>
      <Styled.LeadingParagraph>
        <strong>Congratulations{displayName ? ` ${displayName}` : ''},</strong>{' '}
        you can apply for your NSW Driver Licence now!
      </Styled.LeadingParagraph>
      <StyledHorizontalCard.CardLayout>
        <StyledHorizontalCard.Image
          src='/images/passed dkt.svg'
          alt='Young person sitting in car with a driving instructor standing outside. Image by Storyset on Freepik.'
        />
        <StyledHorizontalCard.ContentLayout>
          <StyledHorizontalCard.ChildWrapper>
            <Heading level={4} headingElement='h2'>
              Steps to get your licence
            </Heading>
            <ol data-testid='card-ordered-list'>
              <li id='applyForYourLicence'>
                Click here for <DocumentInformationModal /> to apply for your
                licence.
              </li>
              <li id='download'>
                Download and complete your{' '}
                <ExternalTextLink
                  id='licenceForm'
                  href={nswDownloadLicenceApplicationForm}
                >
                  licence form
                </ExternalTextLink>{' '}
                (or complete at a service centre).
              </li>
              <li id='submit'>
                Visit a <ServiceCentreHyperlink label='Service NSW centre' /> to
                submit your application.
              </li>
            </ol>
          </StyledHorizontalCard.ChildWrapper>
        </StyledHorizontalCard.ContentLayout>
        <Styled.AdditionalInformation>
          <StyledHorizontalCard.ChildWrapper>
            <Styled.Box>
              Your identity documents must be original{' '}
              <strong>(not laminated, photocopied or damaged).</strong> You’ll
              need to organise replacement documents in these instances.
            </Styled.Box>
            <p>
              Visit{' '}
              <ExternalTextLink
                id='vistNswLink'
                href={serviceNswApplyForALearnerDriverLicence}
              >
                nsw.gov.au
              </ExternalTextLink>{' '}
              to find out more.
            </p>
          </StyledHorizontalCard.ChildWrapper>
        </Styled.AdditionalInformation>
      </StyledHorizontalCard.CardLayout>
    </section>
  );
};

export default ApplyLearnerLicence;
