import styled from 'styled-components';
import { FormRadioGroup as KiamaFormRadioGroup, tokens } from '@snsw-gel/react';

export const SurveyFormRadioGroup = styled(KiamaFormRadioGroup)`
  margin-top: 2rem;
  margin-bottom: 1.5rem;

  @media screen and (min-width: ${tokens.breakpoints.sm}px) {
    margin-top: 2.5rem;
  }

  > div {
    gap: 1rem;
  }
`;

export const SurveyRadioHelperText = styled.div`
  margin-top: 1rem;
`;
