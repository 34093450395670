import styled from 'styled-components';
import { IconNotificationSuccess as SNSWIconNotificationSuccess } from '@snsw-gel/react';

export const HeadingIconWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
`;

export const IconNotificationSuccess = styled(SNSWIconNotificationSuccess)`
  margin-top: 0.5rem;
`;
