import React from 'react';
import { Heading } from '@snsw-gel/react';
import {
  ServiceCentreHyperlink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';

const MedicalAssessments: React.FC = () => (
  <>
    <Heading level={4}>Medical assessments</Heading>
    <p>
      If you need a medical assessment, your doctor or specialist can complete
      and submit a digital form online. They may require your customer number to
      do this.
    </p>
    <p>
      If your doctor requires the paper version of this form, contact us on{' '}
      <TelephoneContactNumberHyperlink /> or visit a <ServiceCentreHyperlink />{' '}
      to request one.
    </p>
  </>
);

export default MedicalAssessments;
