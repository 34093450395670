import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getCustomerPoiData } from 'api/api';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { FormLayout } from 'components/Layouts';
import { profileConnectCallbackQueryParameter } from 'config/constants';
import { pageHeadingLabel } from 'config/page';
import routes from 'config/routes';
import { errorEligibilityRequiredAge } from 'consts/errors/questionnaireHardStop';
import { enrolmentIdentificationType } from 'consts/transport';
import { useStoreDispatch, useStoreSelector } from 'store';
import { reviewSliceActions } from 'store/slice/review';
import { customerSliceActions } from 'store/slice/customer';
import { GetDKTStepProgress } from 'utils/routes/StepProgress';
import { handleResponseData } from 'utils/api/httpClient';
import handledSystemMessages from 'utils/api/handledSystemMessages';
import isDktDateOfBirthValid from 'utils/helpers/isDktDateOfBirthValid';
import useQuery from 'utils/hooks/useQuery';
import isReEnrolmentOrLinkedCustomer from 'utils/helpers/isReEnrolmentOrLinkedCustomer';
import { clearSessionFormData } from 'utils/session/storeSessionManagers';
import ReviewDetailsBox from './DetailsBox/DetailsBox';
import ReviewDeclarations from './ReviewDeclarations/ReviewDeclarations';
import ErrorNotification from './ErrorNotification/ErrorNotification';
import FormSubmitPreface from './FormSubmitPreface/FormSubmitPreface';
dayjs.extend(customParseFormat);

const stepList = GetDKTStepProgress('Review and submit');

const ReviewAndSubmit: React.FC<ReviewAndSubmitProps> = ({
  postPoi = false,
}) => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const query = useQuery();
  const identificationType = useStoreSelector(
    (state) => state.customer.enrolmentIdentificationType
  );

  const redirectToGenericErrorPage = () => {
    history.push(routes.genericError.path);
  };

  useEffect(() => {
    const handleGetPostPoiData = async () => {
      setIsLoading(true);
      const sessionId = query.get(profileConnectCallbackQueryParameter);
      if (!sessionId) {
        redirectToGenericErrorPage();
        return;
      }

      const response = await getCustomerPoiData(sessionId);
      const data = handleResponseData(response);
      if (data === null) {
        redirectToGenericErrorPage();
        return;
      }

      // check system messages
      const messageStatus = handledSystemMessages(data.systemMessages, history);
      if (messageStatus.hasError) {
        if (!messageStatus.redirected) {
          redirectToGenericErrorPage();
        }
        return;
      }

      // to check DoB if under-aged from DVS flow
      if (!isDateOfBirthDktValid(data?.dateOfBirth)) {
        history.push({
          pathname: routes.errorEligibility.path,
          state: {
            systemMessage: errorEligibilityRequiredAge,
          },
        });
        return;
      }

      dispatch(reviewSliceActions.setProfileData(data));
      dispatch(
        customerSliceActions.setEnrolmentType(enrolmentIdentificationType.dvs)
      );
      setIsLoading(false);
    };

    if (postPoi) {
      clearSessionFormData();
      handleGetPostPoiData();
    }
  }, []);

  const hideStepList = isReEnrolmentOrLinkedCustomer(identificationType);

  return (
    <>
      <FormLayout
        title='Review and submit'
        label={pageHeadingLabel}
        stepsList={hideStepList ? null : stepList}
      >
        <ErrorNotification />
        <FormSubmitPreface />
        <ReviewDetailsBox />
        <ReviewDeclarations />
      </FormLayout>
      {isLoading && <FullPageLoader />}
    </>
  );
};

export default ReviewAndSubmit;

interface ReviewAndSubmitProps {
  postPoi?: boolean;
}

export const isDateOfBirthDktValid = (dateOfBirth: unknown): boolean => {
  if (typeof dateOfBirth !== 'string') return false;

  const parsedDate = dayjs(dateOfBirth, 'DD/MM/YYYY', true);
  if (!parsedDate.isValid()) return false;

  const isDktValidAge = isDktDateOfBirthValid(parsedDate);
  if (!isDktValidAge) return false;

  return true;
};
